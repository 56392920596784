import { app } from './_app';

export const globals = {
  '@global': {
    ...app,
    // '*': {
    //   '&:focus': {
    //     outline: '2px solid #005eaa !important',
    //   },
    // },
    'button:focus': {
      outline: '2px solid #005eaa !important !important',
    },
    'a:focus': {
      outline: '2px solid #005eaa !important !important',
    },
    // 'button:active': {
    //   outline: '2px solid #005eaa !important !important',
    // },
    // 'input:active': {
    //   outline: '2px solid #005eaa !important !important',
    // },
  },
};
