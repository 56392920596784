"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.thunk = void 0;

var createThunkMiddleware = function createThunkMiddleware() {
  return function (_ref) {
    var dispatch = _ref.dispatch,
        getState = _ref.getState;
    return function (next) {
      return function (action) {
        if (typeof action === 'function') {
          return action(dispatch, getState);
        }

        return next(action);
      };
    };
  };
};

var thunk = createThunkMiddleware();
exports.thunk = thunk;