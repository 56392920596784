"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.baseStyles = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var baseStyles = function baseStyles(theme) {
  var _theme$colors = theme.colors,
      primaryColor = _theme$colors.primary,
      defaultBGColor = _theme$colors.gray100,
      disabledColor = _theme$colors.gray500,
      textColor = _theme$colors.gray800,
      whiteColor = _theme$colors.white;
  return {
    option: function option(_ref) {
      var isDisabled = _ref.isDisabled,
          isFocused = _ref.isFocused,
          optionStyles = _ref.optionStyles;
      var isFocusedColor = isFocused && whiteColor;
      var isFocusedBG = isFocused && primaryColor;
      var defaultColor = isDisabled ? disabledColor : textColor;
      return _objectSpread(_objectSpread({}, optionStyles), {}, {
        color: isFocusedColor || defaultColor,
        backgroundColor: isFocusedBG || defaultBGColor,
        position: 'relative',
        '&:hover $optionIcon:after': {
          borderColor: 'inherit'
        }
      });
    },
    flex: {
      display: 'flex',
      flex: '1 1 0%',
      flexDirection: 'row',
      flexWrap: 'wrap',
      boxSizing: 'border-box'
    },
    optionText: {
      display: 'block',
      flex: '1 1 0%',
      boxSizing: 'border-box',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    optionIcon: function optionIcon(_ref2) {
      var isFocused = _ref2.isFocused;
      var iconSize = 24;
      return {
        width: iconSize,
        height: iconSize - 4,
        position: 'relative',
        left: 5,
        top: 2,
        '&:after': {
          content: '""',
          position: 'absolute',
          top: iconSize / 10 - 2,
          left: iconSize * 0.3,
          width: iconSize / 3,
          height: iconSize / 1.7,
          borderColor: isFocused ? 'inherit' : primaryColor,
          borderStyle: 'solid',
          borderWidth: iconSize / 10,
          borderTopWidth: 0,
          borderLeftWidth: 0,
          transform: 'rotate(45deg) scale(1)'
        }
      };
    }
  };
};

exports.baseStyles = baseStyles;