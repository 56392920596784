"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.parseCustomStyles = exports.getCustomStyles = exports.getSheetClasses = exports.addDynamicRules = exports.updateDynamicRules = exports.removeDynamicRules = exports.createStyleSheet = exports.addMeta = exports.getMeta = exports.unmanageSheet = exports.manageSheet = exports.getManager = exports.getSheetIndex = void 0;

var _typeof2 = _interopRequireDefault(require("@babel/runtime/helpers/typeof"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _reactJss = require("react-jss");

var _jss = require("jss");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var defaultManagers = new Map();
var sheetsMeta = new WeakMap();
var sheetIndex = Number.MIN_SAFE_INTEGER || -1e9;

var getSheetIndex = function getSheetIndex() {
  sheetIndex += 1;
  return sheetIndex;
};

exports.getSheetIndex = getSheetIndex;

var getManager = function getManager(context, managerId) {
  // If `managers` map is present in the context, we use it in order to
  // let JssProvider reset them when new response has to render server-side.
  if (context.managers) {
    if (!context.managers[managerId]) {
      context.managers[managerId] = new _jss.SheetsManager();
    }

    return context.managers[managerId];
  }

  var manager = defaultManagers.get(managerId);

  if (!manager) {
    manager = new _jss.SheetsManager();
    defaultManagers.set(managerId, manager);
  }

  return manager;
};

exports.getManager = getManager;

var manageSheet = function manageSheet(options) {
  var sheet = options.sheet,
      context = options.context,
      index = options.index,
      theme = options.theme;

  if (!sheet) {
    return;
  }

  var manager = getManager(context, index);
  manager.manage(theme);

  if (context.registry) {
    context.registry.add(sheet);
  }
};

exports.manageSheet = manageSheet;

var unmanageSheet = function unmanageSheet(options) {
  if (!options.sheet) {
    return;
  }

  var manager = getManager(options.context, options.index);
  manager.unmanage(options.theme);
};

exports.unmanageSheet = unmanageSheet;

var getMeta = function getMeta(sheet) {
  return sheetsMeta.get(sheet);
};

exports.getMeta = getMeta;

var addMeta = function addMeta(sheet, meta) {
  sheetsMeta.set(sheet, meta);
};

exports.addMeta = addMeta;

var getStyles = function getStyles(options) {
  var styles = options.styles;

  if (typeof styles !== 'function') {
    return styles;
  }

  return styles(options.theme);
};

var getSheetOptions = function getSheetOptions(options, link) {
  var minify;

  if (options.context.id && options.context.id.minify != null) {
    minify = options.context.id.minify;
  }

  var classNamePrefix = options.context.classNamePrefix || '';

  if (options.name && !minify) {
    classNamePrefix += "".concat(options.name.replace(/\s/g, '-'), "-");
  }

  var meta = '';
  if (options.name) meta = "".concat(options.name, ", ");
  meta += typeof options.styles === 'function' ? 'Themed' : 'Unthemed';
  return {
    index: options.index,
    meta: meta,
    classNamePrefix: classNamePrefix,
    link: link,
    generateId: options.context.generateId
  };
};

var createStyleSheet = function createStyleSheet(options) {
  if (options.context.disableStylesGeneration) {
    return undefined;
  }

  var manager = getManager(options.context, options.index);
  var existingSheet = manager.get(options.theme);

  if (existingSheet) {
    return existingSheet;
  }

  var jss = options.context.jss || _reactJss.jss;
  var styles = getStyles(options);
  var dynamicStyles = (0, _jss.getDynamicStyles)(styles);
  var sheet = jss.createStyleSheet(styles, getSheetOptions(options, dynamicStyles !== null));
  addMeta(sheet, {
    dynamicStyles: dynamicStyles,
    styles: styles
  });
  manager.add(options.theme, sheet);
  return sheet;
};

exports.createStyleSheet = createStyleSheet;

var removeDynamicRules = function removeDynamicRules(sheet, data, rules) {
  // Loop over each dynamic rule and remove the dynamic rule
  // We can't just remove the whole sheet as this has all of the rules for every component instance
  Object.keys(rules).forEach(function (key) {
    sheet.deleteRule(rules[key]);
  });
};

exports.removeDynamicRules = removeDynamicRules;

var updateDynamicRules = function updateDynamicRules(sheet, data, rules) {
  // Loop over each dynamic rule and update it
  // We can't just update the whole sheet as this has all of the rules for every component instance
  Object.keys(rules).forEach(function (key) {
    sheet.updateOne(rules[key], data);
  });
};

exports.updateDynamicRules = updateDynamicRules;

var addDynamicRules = function addDynamicRules(sheet, data) {
  var meta = getMeta(sheet);

  if (!meta) {
    return undefined;
  }

  var rules = {};

  if (meta.dynamicStyles) {
    // Loop over each dynamic rule and add it to the stylesheet
    Object.keys(meta.dynamicStyles).forEach(function (key) {
      var initialRuleCount = sheet.rules.index.length;
      var originalRule = sheet.addRule(key, meta.dynamicStyles[key]); // Loop through all created rules, fixes updating dynamic rules

      for (var i = initialRuleCount; i < sheet.rules.index.length; i++) {
        var rule = sheet.rules.index[i];
        sheet.updateOne(rule, data); // If it's the original rule, we need to add it by the correct key so the hook and hoc
        // can correctly concat the dynamic class with the static one

        rules[originalRule === rule ? key : rule.key] = rule;
      }
    });
  }

  return rules;
};

exports.addDynamicRules = addDynamicRules;

var getSheetClasses = function getSheetClasses(sheet, dynamicRules) {
  if (!dynamicRules) {
    return sheet.classes;
  }

  var classes = {};
  var meta = getMeta(sheet);

  if (!meta) {
    return sheet.classes;
  }

  Object.keys(meta.styles).forEach(function (key) {
    classes[key] = sheet.classes[key];

    if (key in dynamicRules) {
      classes[key] += " ".concat(sheet.classes[dynamicRules[key].key]);
    }
  });
  return classes;
};

exports.getSheetClasses = getSheetClasses;

var getCustomStyles = function getCustomStyles(styles, customStyles, theme) {
  var themedStyles = typeof styles === 'function' ? styles(theme) : styles;

  if (!customStyles) {
    return themedStyles;
  }

  var defaultStyles = Object.keys(customStyles).reduce(function (obj, key) {
    var newStyles = _objectSpread({}, obj);

    newStyles[key] = function (data) {
      return customStyles[key](themedStyles[key], theme, data);
    };

    return newStyles;
  }, {});
  return Object.keys(themedStyles).reduce(function (obj, key) {
    var newStyles = _objectSpread({}, obj);

    if (typeof customStyles[key] === 'function') {
      if (typeof themedStyles[key] === 'function') {
        newStyles[key] = function (data) {
          return customStyles[key](themedStyles[key](data), theme, data);
        };
      } else {
        newStyles[key] = function (data) {
          return customStyles[key](themedStyles[key], theme, data);
        };
      }
    } else if ((0, _typeof2["default"])(customStyles[key]) === 'object') {
      newStyles[key] = customStyles[key];
    } else {
      newStyles[key] = themedStyles[key];
    }

    return newStyles;
  }, defaultStyles);
};

exports.getCustomStyles = getCustomStyles;

var parseCustomStyles = function parseCustomStyles(customStyles) {
  var data = {};
  var custom = {};

  if (!customStyles) {
    return {
      data: data
    };
  }

  Object.keys(customStyles).forEach(function (key) {
    if (typeof customStyles[key] === 'function') {
      custom[key] = customStyles[key];
    } else {
      data[key] = customStyles[key];
    }
  });
  return {
    data: data,
    custom: custom
  };
};

exports.parseCustomStyles = parseCustomStyles;