import React from 'react';
import { Icon } from '@abyss/web/ui/Icon';

export const IconAccountBalances = props => {
  return (
    <Icon {...props} color='#005eaa'>
      <svg width="40px" height="40px" viewBox="0 0 40 40" focusable="false">
        <title>Account Balances</title>
        <desc>A piece of paper with a dollar sign</desc>
        <path
          transform="translate(2.000000, 0.000000)"
          d="M33.31 0c.37 0 .761.246.918.576 3.372 7.138 1.257 13.136-.79 18.943-2 5.678-3.892 11.035-.89 17.434.14.297.115.699-.06.975a1.07 1.07 0 01-.857.47H2.687c-.367 0-.756-.248-.92-.58-3.367-7.131-1.25-13.133.795-18.937l.244-.693C4.71 12.764 6.33 7.58 3.452 1.444a1.054 1.054 0 01.062-.972C3.686.194 4.039 0 4.37 0h28.94zm-.577 1.918H5.739c2.528 6.55.55 12.16-1.366 17.601C2.421 25.055.574 30.288 3.266 36.482H30.26c-2.524-6.555-.548-12.168 1.367-17.6 1.954-5.537 3.802-10.776 1.107-16.964zm-7.204 28.815a.958.958 0 010 1.918H7.866a.959.959 0 110-1.918H25.53zm-.12-5.786c.532 0 .958.428.958.958a.957.957 0 01-.958.958H7.746a.96.96 0 01-.961-.958.96.96 0 01.961-.958H25.41zM19.06 5.817v1.381c2.473.251 3.915 1.632 4.046 3.82h-2.55c-.02-.941-.71-1.746-1.496-1.728v3.185c1.709.423 2.457.729 3.182 1.305.847.651 1.31 1.649 1.31 2.86 0 .976-.347 1.84-1.02 2.531-.863.902-1.785 1.286-3.472 1.458v1.516h-1.053v-1.516c-2.727-.212-4.202-1.709-4.471-4.472h2.55v.058c0 1.21.71 2.073 1.92 2.324v-3.646c-.057-.02-.076-.02-.115-.037-.02 0-.096-.02-.251-.077-.056-.02-.169-.041-.307-.078-2.455-.614-3.49-1.688-3.49-3.608 0-1.228.44-2.186 1.342-2.897.75-.593 1.437-.824 2.822-.998v-1.38h1.053zm0 9.364v3.358C20.35 18.327 21 17.732 21 16.774c0-.807-.443-1.172-1.939-1.593zm-1.053-5.89c-1 .037-1.613.613-1.613 1.496 0 .787.363 1.112 1.613 1.44V9.29z"
        />
      </svg>
    </Icon>
  );
};
