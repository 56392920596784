"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formatMoney = void 0;

var _accounting = _interopRequireDefault(require("accounting"));

_accounting["default"].settings.currency.format = {
  pos: '%s%v',
  neg: '%s(%v)',
  zero: '%s%v'
};

var formatMoney = function formatMoney(value) {
  return _accounting["default"].formatMoney(value || 0);
};

exports.formatMoney = formatMoney;