import * as React from 'react';
import { withGoogleReCaptcha } from './with-google-recaptcha';

class GoogleReCaptcha extends React.Component {
  async componentDidMount() {
    const { googleReCaptchaProps, action, onVerify } = this.injectedProps;
    const { executeRecaptcha } = googleReCaptchaProps;
    if (!executeRecaptcha) {
      return;
    }
    const token = await executeRecaptcha(action);
    if (!onVerify) {
      return;
    }
    onVerify(token);
  }

  get injectedProps() {
    return this.props;
  }

  render() {
    return null;
  }
}
const WrappedGoogleRecaptcha = withGoogleReCaptcha(GoogleReCaptcha);
export { WrappedGoogleRecaptcha as GoogleReCaptcha };
