import React, { useEffect, useState } from 'react';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { useStyles } from '@hhs/ui/old-abyss/ui/styles/hooks/useStyles';
import { getFeature } from '@hhs/api/src/tools/features';
import { PageHeader } from '../../PageHeader';
import { PageLayout } from '../../PageLayout';
import { styles } from './ContactUs.styles';

export const ContactUs = () => {
  const router = useRouter();
  const location = router.getLocation();  
  const classes = useStyles(styles);
  const [hourOfOperationText, setHourOfOperationText] = useState('');

  const handleGoBack = () => {
    const url = window.location.pathname.split('/');
    if ((url[1] === 'attestation' || url[1] === 'registration' || url[1] === 'docusign') &&
      (url[2] === 'contact-us' || url[2] === 'accessibility-statement' || url[2] === 'terms-of-use' || url[2] === 'privacy-policy')) {
      router.navigate('/');
      return;
    }
    if (window.location.pathname.split('/').length > 2) {  
      router.navigate(-1);  
    } else {  
      //router.navigate(-1);  
      router.navigate('/');
    }    
  };

  useEffect(() => {
    const urlToSetHours = window.location.pathname.split('/');
    var hooText = getFeature('OIDC_MIGRATION');
    switch (urlToSetHours[1]) {
      case 'attestation':
        hooText = 'Hours of operation are 8 a.m. to 4 p.m. Central Time, Monday through Friday.';
        break;
      case 'dashboard':
        hooText = 'Hours of operation are 8 a.m. to 4 p.m. Central Time, Monday through Friday.';
        break;
      case 'registration':
        hooText = 'Hours of operation are 8 a.m. to 4 p.m. Central Time, Monday through Friday.';
        break;
      case 'member':
        hooText = 'Hours of operation are 8 a.m. to 4 p.m. Central Time, Monday through Friday.';
        break; 
      default:
        hooText = 'Hours of operation are 8 a.m. to 4 p.m. Central Time, Monday through Friday.';
        break;                           
    }
    const hooDisplayText = `If you have any questions about the program please contact the provider support line at (866) 569-3522; 
    for TTY dial 711. ` +  hooText + ` Our service staff members 
    are available to provide real-time technical assistance, as well as service and payment support.
    `; 
    setHourOfOperationText(hooDisplayText);
  }, []);  

  return (
    <React.Fragment>
      <PageHeader onBackClick={handleGoBack}>
        <h1 className={classes.pageHeader}>Contact Us</h1>
      </PageHeader>
      <PageLayout>
        <p>
        {hourOfOperationText}
        </p>
      </PageLayout>
    </React.Fragment>
  );
};
