"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.baseStyles = void 0;

var baseStyles = function baseStyles() {
  return {
    title: {
      fontWeight: 'bold',
      marginBottom: 5
    }
  };
};

exports.baseStyles = baseStyles;