import * as React from 'react';
import PropTypes from 'prop-types';

const SCRIPT_NOT_AVAILABLE = 'Recaptcha script is not available';
const GoogleReCaptchaContext = React.createContext({
  // dummy default context;
});
const { Consumer: GoogleReCaptchaConsumer } = GoogleReCaptchaContext;
export { GoogleReCaptchaConsumer, GoogleReCaptchaContext };
export class GoogleReCaptchaProvider extends React.Component {
  constructor(args) {
    super(args);
    this.scriptId = 'google-recaptcha-enterprise';
    this.resolver = undefined;
    this.rejecter = undefined;
    this.grecaptcha = new Promise((resolve, reject) => {
      this.resolver = resolve;
      this.rejecter = reject;
    });
    this.executeRecaptcha = async action => {
      const { reCaptchaKey } = this.props;
      return this.grecaptcha.then(_grecaptcha =>
        _grecaptcha.execute(reCaptchaKey, { action })
      );
    };
    this.handleOnLoad = () => {
      if (!window || !window.grecaptcha) {
        console.warn(SCRIPT_NOT_AVAILABLE);
        return;
      }
      window.grecaptcha.enterprise.ready(() => {
        this.resolver(window.grecaptcha.enterprise);
      });
    };
    this.injectGoogleReCaptchaScript = () => {
      /**
       * Scripts has already been injected script,
       * return to avoid duplicated scripts
       */
      if (document.getElementById(this.scriptId)) {
        this.handleOnLoad();
        return;
      }
      const head = document.getElementsByTagName('head')[0];
      const js = this.generateGoogleReCaptchaScript();
      head.appendChild(js);
    };
    this.generateGoogleReCaptchaScript = () => {
      const { reCaptchaKey, language, nonce } = this.props;
      const js = document.createElement('script');
      js.id = this.scriptId;
      js.src = `${this.googleRecaptchaSrc}?render=${reCaptchaKey}${
        language ? `&hl=${language}` : ''
      }`;
      if (nonce) {
        js.nonce = nonce;
      }
      js.onload = this.handleOnLoad;
      return js;
    };
  }

  componentDidMount() {
    const { reCaptchaKey } = this.props;
    if (!reCaptchaKey) {
      return;
    }
    this.injectGoogleReCaptchaScript();
  }

  componentDidUpdate(prevProps) {
    const { reCaptchaKey } = this.props;
    if (prevProps.reCaptchaKey || !reCaptchaKey) {
      return;
    }
    this.injectGoogleReCaptchaScript();
  }

  componentWillUnmount() {
    // remove badge
    const nodeBadge = document.querySelector('.grecaptcha-badge');
    if (nodeBadge && nodeBadge.parentNode) {
      document.body.removeChild(nodeBadge.parentNode);
    }
    // remove script
    const script = document.querySelector(`#${this.scriptId}`);
    if (script) {
      script.parentNode.removeChild(script);
    }
  }

  get googleReCaptchaContextValue() {
    return { executeRecaptcha: this.executeRecaptcha };
  }

  get googleRecaptchaSrc() {
    const { useRecaptchaNet } = this.props;
    const hostName = useRecaptchaNet ? 'recaptcha.net' : 'google.com';
    return `https://www.${hostName}/recaptcha/enterprise.js`;
  }

  render() {
    const { children } = this.props;
    return React.createElement(
      GoogleReCaptchaContext.Provider,
      { value: this.googleReCaptchaContextValue },
      children
    );
  }
}

GoogleReCaptchaProvider.propTypes = {
  useRecaptchaNet: PropTypes.bool,
  children: PropTypes.node,
  reCaptchaKey: PropTypes.string,
  language: PropTypes.string,
  nonce: PropTypes.string,
};

GoogleReCaptchaProvider.defaultProps = {
  useRecaptchaNet: false,
  children: null,
  reCaptchaKey: null,
  language: null,
  nonce: null,
};
