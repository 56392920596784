"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.injectQuery = void 0;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

/**
 * Adds query to location.
 * Utilises the search prop of location to construct query.
 */
var injectQuery = function injectQuery(location) {
  if (!location) {
    return location;
  }

  var searchQuery = location.search || window.location.search;

  if (typeof searchQuery !== 'string' || searchQuery.length === 0) {
    return _objectSpread(_objectSpread({}, location), {}, {
      query: {}
    });
  } // Ignore the `?` part of the search string e.g. ?username=codejockie


  var search = searchQuery.substring(1); // Split the query string on `&` e.g. ?username=codejockie&name=Kennedy

  var queries = search.split('&'); // Contruct query

  var query = queries.reduce(function (acc, currentQuery) {
    // Split on `=`, to get key and value
    var _currentQuery$split = currentQuery.split('='),
        _currentQuery$split2 = (0, _slicedToArray2["default"])(_currentQuery$split, 2),
        queryKey = _currentQuery$split2[0],
        queryValue = _currentQuery$split2[1];

    return _objectSpread(_objectSpread({}, acc), {}, (0, _defineProperty2["default"])({}, queryKey, queryValue));
  }, {});
  return _objectSpread(_objectSpread({}, location), {}, {
    query: query
  });
};

exports.injectQuery = injectQuery;