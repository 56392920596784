import { createTheme } from '@abyss/web/tools/theme';

const themeOverride = {
  css: {
    '.abyss-theme-provider-root': null
  },
  theme: {
    colors: {
      primary: '#005eaa !important',
      primary1: '#005eaa !important',
      focus: '#005eaa !important',
      interactive2: '#196ecf',
      interactive1: '#004BA0',
      interactive3: '#D9E9FA',
      error: '#C41512',
      warning: '#a24c26',
    },
    fonts: {body: 'SourceSansPro, Helvetica, Arial, sans-serif',},
  },
};

export const theme = createTheme('abyss', themeOverride);
