export const styles = theme => {
  return {
    headerIcon: {
      marginRight: 20,
    },
    subHeading: {
      marginTop: 10,
      marginBottom: 25,
      lineHeight: '18px',
      maxWidth: 850,
    },
    middleFootnote: {
      marginTop: 10,
      marginBottom: 25,
      lineHeight: '18px',
      maxWidth: 850,
      fontSize: '12px',
    },
    privacyStatement: {
      marginBottom: 48,
      lineHeight: '18px',
      marginLeft: 52,
    },
    privacyStatementSection: {
      margin: [24, 0],
    },
    accessiblityLink: {
      '&:focus': {
        outline: 'none',
        boxShadow: '0px 0px 5px 5px rgb(174,202,225)',
      },
    },
    bulletPoint: {
      marginLeft: '21px',
      listStyleType: 'circle',
    },
    horizontalLine: {
      borderBottom: '1px solid #D8D8D8',
      marginLeft: 12,
      marginBottom: 20,
      width: '84%',
    },
    horizontalLine2: {
      borderBottom: '1px solid #D8D8D8',
      marginLeft: 12,
      marginBottom: 20,
      width: '70%',
    },
    section: {
      width: '100%',
    },
    continueButton: {
      borderRadius: 3,
      padding: '0px 24px'
    },
    headerContent: {
      fontSize: '14px',
    },
    belowText1: {
      fontSize: 11,
      lineHeight: 1.2,
      textAlign: 'center',
      marginTop: 25,
    },
    belowText2: {
      fontSize: 11,
      lineHeight: 1.2,
      textAlign: 'center',
    },
  };
};
