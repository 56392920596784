export const styles = theme => {
  return {
    content: {
      marginTop: 70,
      fontSize: 14,
      lineHeight: '18px',
      maxWidth: 700,
    },
    accessiblityLink: {
      '&:focus': {
        outline: 'none',
        boxShadow: '0px 0px 5px 5px rgb(174,202,225)',
      },
    },
  };
};
