"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getErrors = void 0;

var _some = _interopRequireDefault(require("lodash/some"));

var getErrors = function getErrors(value, form, validators, index) {
  var errors = {};

  if (validators) {
    (0, _some["default"])(validators, function (validator, key) {
      errors[key] = !!validator(value, form, index);
      return errors[key];
    });
  }

  return errors;
};

exports.getErrors = getErrors;