"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formActions = exports.removeItem = exports.addItem = exports.validate = exports.unset = exports.reset = exports.update = exports.load = exports.set = exports.init = exports.submit = exports.REMOVE_ITEM = exports.ADD_ITEM = exports.VALIDATE = exports.UNSET = exports.RESET = exports.UPDATE = exports.SET = exports.INIT = exports.SUBMIT = void 0;

var _typeof2 = _interopRequireDefault(require("@babel/runtime/helpers/typeof"));

var _pickBy = _interopRequireDefault(require("lodash/pickBy"));

var _some = _interopRequireDefault(require("lodash/some"));

var _merge = _interopRequireDefault(require("lodash/merge"));

var _isEqual = _interopRequireDefault(require("lodash/isEqual"));

var _event = require("../../tools/event");

var _getFields = require("./helpers/getFields");

var _getModel = require("./helpers/getModel");

var _mapFileUploads = require("./helpers/mapFileUploads");

var PREFIX = '@hhs/ui/old-abyss/ui/'; // New prefix defined here  

var SUBMIT = '${PREFIX}/form/SUBMIT';
exports.SUBMIT = SUBMIT;
var INIT = '${PREFIX}/form/INIT';
exports.INIT = INIT;
var SET = '${PREFIX}/form/SET';
exports.SET = SET;
var UPDATE = '${PREFIX}/form/UPDATE';
exports.UPDATE = UPDATE;
var RESET = '${PREFIX}/form/RESET';
exports.RESET = RESET;
var UNSET = '${PREFIX}/form/UNSET';
exports.UNSET = UNSET;
var VALIDATE = '${PREFIX}/form/VALIDATE';
exports.VALIDATE = VALIDATE;
var ADD_ITEM = '${PREFIX}/form/ADD_ITEM';
exports.ADD_ITEM = ADD_ITEM;
var REMOVE_ITEM = '${PREFIX}/form/REMOVE_ITEM';
exports.REMOVE_ITEM = REMOVE_ITEM;

var submit = function submit(model, onSuccess, onError) {
  return function (dispatch, getState) {
    setTimeout(function () {
      var state = getState();
      var fields = (0, _getFields.getFields)(state, model);
      var invalidKey = Object.keys(fields).find(function (key) {
        return !fields[key].valid;
      });
      var modelValue = (0, _mapFileUploads.mapFileUploads)((0, _getModel.getModel)(state, model));

      if (invalidKey) {
        dispatch({
          type: SUBMIT,
          payload: {
            model: model,
            focus: invalidKey
          }
        });
        var errorFields = (0, _pickBy["default"])(fields, function (value) {
          return (0, _some["default"])(value.errors);
        });
        (0, _event.event)('ABYSS_FORM_SUBMIT_ERROR', {
          model: model,
          modelValue: modelValue,
          fields: errorFields
        });

        if (onError) {
          onError(modelValue, errorFields);
        }
      } else {
        dispatch({
          type: SUBMIT,
          payload: {
            model: model
          }
        });
        (0, _event.event)('ABYSS_FORM_SUBMIT_SUCCESS', {
          model: model,
          modelValue: modelValue,
          fields: fields
        });

        if (onSuccess) {
          onSuccess(modelValue, fields);
        }
      }
    });
  };
};

exports.submit = submit;

var init = function init(model, value) {
  var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  return {
    type: INIT,
    payload: {
      model: model,
      value: value,
      options: options
    }
  };
};

exports.init = init;

var set = function set(model, value) {
  var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  return {
    type: SET,
    payload: {
      model: model,
      value: value,
      options: options
    }
  };
};

exports.set = set;

var load = function load(model, value, options) {
  return function (dispatch, getState) {
    if ((0, _typeof2["default"])(value) !== 'object') {
      dispatch(set(model, value, options));
    } else {
      var isArray = Array.isArray(value);
      var currentValue = (0, _getModel.getModel)(getState(), model) || (isArray ? [] : {});
      var mergedValue = isArray ? currentValue.concat(value) : (0, _merge["default"])({}, currentValue, value);

      if (options || !(0, _isEqual["default"])(currentValue, mergedValue)) {
        dispatch(set(model, mergedValue, options));
      }
    }
  };
};

exports.load = load;

var update = function update(model) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return {
    type: UPDATE,
    payload: {
      model: model,
      options: options
    }
  };
};

exports.update = update;

var reset = function reset(model, value) {
  var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

  if (typeof window !== 'undefined') {
    window.__ABYSS_FORM_RESET__ = true;
    setTimeout(function () {
      return delete window.__ABYSS_FORM_RESET__;
    });
  }

  return {
    type: RESET,
    payload: {
      model: model,
      value: value,
      options: options
    }
  };
};

exports.reset = reset;

var unset = function unset(model) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return {
    type: UNSET,
    payload: {
      model: model,
      options: options
    }
  };
};

exports.unset = unset;

var validate = function validate(model) {
  var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return {
    type: VALIDATE,
    payload: {
      model: model,
      options: options
    }
  };
};

exports.validate = validate;

var addItem = function addItem(model, value, index) {
  return {
    type: ADD_ITEM,
    payload: {
      model: model,
      value: value,
      index: index
    }
  };
};

exports.addItem = addItem;

var removeItem = function removeItem(model, index) {
  return {
    type: REMOVE_ITEM,
    payload: {
      model: model,
      index: index
    }
  };
};

exports.removeItem = removeItem;
var formActions = {
  submit: submit,
  init: init,
  set: set,
  load: load,
  update: update,
  reset: reset,
  unset: unset,
  validate: validate,
  addItem: addItem,
  removeItem: removeItem
};
exports.formActions = formActions;