import { http as abyssHttp } from '@abyss/web/tools/http';
import { event } from '@abyss/web/tools/event';
import { getMessage } from '../messages';

const getCode = response => {
  const data = response.data || {};

  if (response.code) {
    return response.code;
  }
  if (data.faultCode) {
    return data.faultCode;
  }
  if (data.errorCode) {
    return data.errorCode;
  }
  if (data.code) {
    return data.code;
  }

  if (data.errors) {
    return data.errors.map(err => err.code).join(',');
  }

  return response.status || 'NoResponse';
};

const getError = response => {
  const code = getCode(response);
  const id = response.requestId;
  const messageId = code ? `${id}[${code}]` : `${id}`;

  let message;

  if (code) {
    message = getMessage(`${id}[${code}]`);
    if (!message) {
      message = getMessage(`default-error[${code}]`);
    }
  }

  if (!message) {
    message = getMessage(id);
    if (!message) {
      message = getMessage('default-error') || messageId;
    }
  }

  return { code, message };
};

const http = options => {
  const httpOptions = options;

  return abyssHttp(httpOptions)
    .then(response => {
      response.requestId = httpOptions.id;

      if (!response.cached) {
        event('API_TRANSACTION', {
          result: 'success',
          duration: response.duration,
          status: response.status,
          endpoint: response.endpoint,
          requestId: response.requestId,
          payload: options.data,
          headers: response.headers,
          body: response.data,
        });
      }

      return response;
    })
    .catch(response => {
      response.requestId = httpOptions.id;
      const responseData = response.data || {};
      const errorData = getError(response);

      event('API_TRANSACTION', {
        result: 'error',
        duration: response.duration,
        status: response.status,
        endpoint: response.endpoint,
        requestId: response.requestId,
        code: errorData.code,
        message: errorData.message,
        payload: options.data,
        headers: response.headers,
        body: response.data,
      });

      throw {
        ...response,
        data: {
          ...responseData,
          ...errorData,
        },
      };
    });
};

Object.assign(http, abyssHttp);

export { http };
