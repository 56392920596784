"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getDefaultMiddleware = void 0;

var _enableDevTools = require("./enableDevTools");

var _thunk = require("./thunk");

var getDefaultMiddleware = function getDefaultMiddleware() {
  var middlewareArray = [_thunk.thunk];

  if ((0, _enableDevTools.enableDevTools)()) {
    middlewareArray = [// eslint-disable-next-line global-require
    require('redux-immutable-state-invariant')["default"](), _thunk.thunk, // eslint-disable-next-line global-require
    require('redux-logger').createLogger({
      collapsed: true
    })];
  }

  return middlewareArray;
};

exports.getDefaultMiddleware = getDefaultMiddleware;