"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getErrorMessage = void 0;

var _find = _interopRequireDefault(require("lodash/find"));

var getErrorMessage = function getErrorMessage(value, form, messages) {
  var errors = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
  var index = arguments.length > 4 ? arguments[4] : undefined;
  var message = (0, _find["default"])(messages, function (v, k) {
    return errors[k];
  });
  return typeof message === 'function' ? message(value, form, errors, index) : message;
};

exports.getErrorMessage = getErrorMessage;