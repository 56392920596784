"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useRouter = void 0;

var _react = require("react");

var _reactRedux = require("react-redux");

var _actions = require("../../redux/actions");

var _RouterContext = require("../../redux/RouterContext");

var useRouter = function useRouter() {
  var useDispatch = (0, _reactRedux.createDispatchHook)(_RouterContext.RouterContext);
  var dispatch = useDispatch();

  var useAction = function useAction(action) {
    return (0, _react.useCallback)(function () {
      return dispatch(action.apply(void 0, arguments));
    }, [dispatch]);
  };

  var onLocationChanged = useAction(_actions.routerActions.onLocationChanged);
  var push = useAction(_actions.routerActions.push);
  var replace = useAction(_actions.routerActions.replace);
  var go = useAction(_actions.routerActions.go);
  var goBack = useAction(_actions.routerActions.goBack);
  var goForward = useAction(_actions.routerActions.goForward);
  return {
    onLocationChanged: onLocationChanged,
    push: push,
    replace: replace,
    go: go,
    goBack: goBack,
    goForward: goForward
  };
};

exports.useRouter = useRouter;