"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.storage = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var getItem = function getItem(storage, key) {
  var item = storage.getItem(key);

  if (item) {
    if (storage === localStorage || storage === sessionStorage) {
      item = JSON.parse(item);
    }

    if (item.e && Date.now() > item.e) {
      storage.removeItem(key);
      return null;
    }

    return item.v;
  }

  return null;
};

var setItem = function setItem(storage, key, value, maxAge) {
  var obj = {
    v: value
  };

  if (maxAge) {
    obj.e = Date.now() + maxAge;
  }

  if (storage === localStorage || storage === sessionStorage) {
    obj = JSON.stringify(obj);
  }

  storage.setItem(key, obj);
};

var updateItem = function updateItem(storage, key, value, maxAge) {
  var tempItem = getItem(storage, key);
  tempItem.v = _objectSpread(_objectSpread({}, tempItem.v), {}, {
    value: value
  });
  setItem(storage, key, tempItem, maxAge);
};

var memoryStore = {};
var memoryStorage = {
  getItem: function getItem(key) {
    return memoryStore[key];
  },
  setItem: function setItem(key, value) {
    memoryStore[key] = value;
  },
  updateItem: function updateItem(key, value) {
    memoryStore[key] = value;
  },
  removeItem: function removeItem(key) {
    delete memoryStore[key];
  },
  clear: function clear() {
    memoryStore = {};
  }
};

if (typeof window !== 'undefined') {
  window.__abyss_storage__ = window.__abyss_storage__ || {};
}

var windowStorage = {
  getItem: function getItem(key) {
    return window.__abyss_storage__[key];
  },
  setItem: function setItem(key, value) {
    window.__abyss_storage__[key] = value;
  },
  updateItem: function updateItem(key, value) {
    window.__abyss_storage__[key] = value;
  },
  removeItem: function removeItem(key) {
    delete window.__abyss_storage__[key];
  },
  clear: function clear() {
    window.__abyss_storage__ = {};
  }
};
var cookieStorage = {
  getItem: function getItem(key) {
    var v = document.cookie.match("(^|;) ?".concat(key, "=([^;]*)(;|$)"));
    return v ? v[2] : null;
  },
  setItem: function setItem(key, value, maxAge) {
    var d = new Date();
    d.setTime(d.getTime() + maxAge);
    document.cookie = "".concat(key, "=").concat(value, ";path=/;expires=").concat(d.toGMTString());
  },
  updateItem: function updateItem(key, value, maxAge) {
    var d = new Date();
    d.setTime(d.getTime() + maxAge);
    document.cookie = "".concat(key, "=").concat(value, ";path=/;expires=").concat(d.toGMTString());
  },
  removeItem: function removeItem(key) {
    document.cookie = "".concat(key, "=;path=/;expires=Thu, 01 Jan 1970 00:00:00 GMT");
  },
  clear: function clear() {
    document.cookie.split(';').forEach(function (cookie) {
      var key = cookie.split('=')[0];
      document.cookie = "".concat(key, "=;path=/;expires=Thu, 01 Jan 1970 00:00:00 GMT");
    });
  }
};
var storage = {
  getItem: getItem,
  setItem: setItem,
  memory: {
    get: function get(key) {
      return getItem(memoryStorage, key);
    },
    set: function set(key, value, maxAge) {
      return setItem(memoryStorage, key, value, maxAge);
    },
    update: function update(key, value, maxAge) {
      return updateItem(memoryStorage, key, value, maxAge);
    },
    remove: function remove(key) {
      return memoryStorage.removeItem(key);
    },
    clear: function clear() {
      return memoryStorage.clear();
    }
  },
  window: {
    get: function get(key) {
      return getItem(windowStorage, key);
    },
    set: function set(key, value, maxAge) {
      return setItem(windowStorage, key, value, maxAge);
    },
    update: function update(key, value, maxAge) {
      return updateItem(windowStorage, key, value, maxAge);
    },
    remove: function remove(key) {
      return windowStorage.removeItem(key);
    },
    clear: function clear() {
      return windowStorage.clear();
    }
  },
  cookie: {
    get: function get(key) {
      return cookieStorage.getItem(key);
    },
    set: function set(key, value, maxAge) {
      return cookieStorage.setItem(key, value, maxAge);
    },
    update: function update(key, value, maxAge) {
      return updateItem(cookieStorage, key, value, maxAge);
    },
    remove: function remove(key) {
      return cookieStorage.removeItem(key);
    },
    clear: function clear() {
      return cookieStorage.clear();
    }
  },
  local: {
    get: function get(key) {
      return getItem(localStorage, key);
    },
    set: function set(key, value, maxAge) {
      return setItem(localStorage, key, value, maxAge);
    },
    update: function update(key, value, maxAge) {
      return updateItem(localStorage, key, value, maxAge);
    },
    remove: function remove(key) {
      return localStorage.removeItem(key);
    },
    clear: function clear() {
      return localStorage.clear();
    }
  },
  session: {
    get: function get(key) {
      return getItem(sessionStorage, key);
    },
    set: function set(key, value, maxAge) {
      return setItem(sessionStorage, key, value, maxAge);
    },
    update: function update(key, value, maxAge) {
      return updateItem(sessionStorage, key, value, maxAge);
    },
    remove: function remove(key) {
      return sessionStorage.removeItem(key);
    },
    clear: function clear() {
      return sessionStorage.clear();
    }
  }
};
exports.storage = storage;