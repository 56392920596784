"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.baseStyles = void 0;

var baseStyles = function baseStyles(theme) {
  return {
    alert: function alert(_ref) {
      var color = _ref.color;
      return {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        backgroundColor: theme.colors.gray100,
        marginBottom: 10,
        minHeight: 60,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: color,
        borderRadius: 3,
        borderLeftWidth: 12,
        borderLeftColor: color,
        padding: '14px 24px',
        fontSize: '14px',
        lineHeight: '18px'
      };
    },
    alertIcon: {
      marginRight: 10,
      marginLeft: -10
    },
    alertTimestamp: {
      fontSize: 11,
      color: theme.colors.gray600
    },
    offScreen: {
      position: 'absolute',
      left: -9999
    },
    closeButton: {
      position: 'absolute !important',
      right: 15,
      top: 15
    },
    childrenWrapper: {
      width: '100%'
    }
  };
};

exports.baseStyles = baseStyles;