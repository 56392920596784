"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useForm = void 0;

var _react = require("react");

var _reactRedux = require("react-redux");

var _actions = require("../../redux/actions");

var _FormContext = require("../../redux/FormContext");

var useForm = function useForm() {
  var useDispatch = (0, _reactRedux.createDispatchHook)(_FormContext.FormContext);
  var dispatch = useDispatch();

  var useAction = function useAction(action) {
    return (0, _react.useCallback)(function () {
      return dispatch(action.apply(void 0, arguments));
    }, [dispatch]);
  };

  var submit = useAction(_actions.formActions.submit);
  var init = useAction(_actions.formActions.init);
  var set = useAction(_actions.formActions.set);
  var load = useAction(_actions.formActions.load);
  var update = useAction(_actions.formActions.update);
  var reset = useAction(_actions.formActions.reset);
  var unset = useAction(_actions.formActions.unset);
  var validate = useAction(_actions.formActions.validate);
  var addItem = useAction(_actions.formActions.addItem);
  var removeItem = useAction(_actions.formActions.removeItem);
  return {
    submit: submit,
    init: init,
    set: set,
    load: load,
    update: update,
    reset: reset,
    unset: unset,
    validate: validate,
    addItem: addItem,
    removeItem: removeItem
  };
};

exports.useForm = useForm;