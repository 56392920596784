import React from 'react';
import PropTypes from 'prop-types';
import { useStyles } from '@hhs/ui/old-abyss/ui/styles/hooks/useStyles';
import { classNames } from '@abyss/web/tools/classNames';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { formatter } from '../../tools/formatter';
import { PageLayout } from '../PageLayout';

import { baseStyles } from './PageHeader.styles';

export const PageHeader = ({
  className,
  styles,
  children,
  showBackButton,
  ...props
}) => {
  const router = useRouter();
  const location = router.getLocation();  
  const classes = useStyles(baseStyles, null, styles);
  
  
  const handleGoBack = () => {
    // attestation, registration, docusign open links in new tab
    // want direct route back hom from contactus, accessibility, terms, privacy
    // /contactUs, /accessibilityStatement, /termsOfUse, /privacyPolicy
    const url = window.location.pathname.split('/');
    if ((url[1] === 'attestation' || url[1] === 'registration' || url[1] === 'docusign') &&
      (url[2] === 'contact-us' || url[2] === 'accessibility-statement' || url[2] === 'terms-of-use' || url[2] === 'privacy-policy')) {
      router.navigate('/');
      return;
    }
    if (window.location.pathname.split('/').length > 2) {  
      router.navigate(-1);  
    } else {  
      //router.navigate(-1);  
      router.navigate('/');
    }    
  };

  
  // const getPreviousPageName = () => {
  //   let pageName = 'Home';
  //   if (previous.location && previous.location.pathname !== '/') {
  //     pageName = previous.location.pathname.substring(
  //       previous.location.pathname.lastIndexOf('/') + 1
  //     );
  //   }
  //   return formatter('capitalCase', pageName);
  // };

  return (
    <div {...props} className={classNames(classes.pageHeader, className)}>
      <PageLayout isWide>
        <If condition={showBackButton}>
          <Then>  
            <button
              type="button"
              onClick={handleGoBack}
              className={classes.backButton}
              aria-label={`Back to previous page`}
            />
          </Then>             
        </If>
        <PageLayout style={{ marginLeft: showBackButton ? 90 : 'auto' }}>
          {children}
        </PageLayout>
      </PageLayout>
    </div>
  );
};

PageHeader.propTypes = {
  className: PropTypes.string,
  styles: PropTypes.shape({}),
  children: PropTypes.node,
  showBackButton: PropTypes.bool,
};

PageHeader.defaultProps = {
  className: null,
  styles: null,
  children: null,
  showBackButton: true,
};
