"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useModel = void 0;

var _reactRedux = require("react-redux");

var _getModel = require("../../redux/helpers/getModel");

var _FormContext = require("../../redux/FormContext");

var useModel = function useModel(model, initialValue) {
  var useSelect = (0, _reactRedux.createSelectorHook)(_FormContext.FormContext);
  return useSelect(function (state) {
    return (0, _getModel.getModel)(state, model, initialValue);
  });
};

exports.useModel = useModel;