"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useFormState = void 0;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _reactRedux = require("react-redux");

var _FormContext = require("../../redux/FormContext");

var initialFormState = {
  fields: {},
  model: {},
  valid: true
};

var useFormState = function useFormState(model) {
  var useSelect = (0, _reactRedux.createSelectorHook)(_FormContext.FormContext);

  var _model$split = model.split('.'),
      _model$split2 = (0, _slicedToArray2["default"])(_model$split, 1),
      formKey = _model$split2[0];

  return useSelect(function (state) {
    return state[formKey];
  }) || initialFormState;
};

exports.useFormState = useFormState;