"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.baseStyles = void 0;

var baseStyles = function baseStyles() {
  return {
    input: {
      top: 0,
      left: 0,
      '&:focus': {
        outline: 'none',
        boxShadow: 'none'
      }
    }
  };
};

exports.baseStyles = baseStyles;