import React from 'react';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { PageHeader } from '../../PageHeader';
import { PageLayout } from '../../PageLayout';

export const CommunicationPreferences = () => {
  const router = useRouter();
  const location = router.getLocation();  

  const handleGoBack = () => {
    if (location.state?.from) {  
      router.navigate(location.state.from);  
    } else {  
      router.navigate(-1);  
    }  
  };

  return (
    <React.Fragment>
      <PageHeader onBackClick={handleGoBack}>
        <PageHeader.Title>Communication Preferences</PageHeader.Title>
      </PageHeader>
      <PageLayout>
        <p>
          By providing your email and phone number, you agree that HHS/HRSA or
          its contractor may send you communications or call you regarding
          Relief Fund Payment. Additionally, you may receive communications
          associated with the Optum ID and/or Optum Pay services that are
          unrelated to Relief Fund Payment. If you don't want to receive
          communications associated with the Optum ID and/or Optum Pay services
          that are unrelated to Relief Fund Payment, please follow the
          instructions for doing so included in the Optum ID Terms of Use and/or
          Optum Pay Enrollment Terms and Conditions.
        </p>
      </PageLayout>
    </React.Fragment>
  );
};
