"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.mapFileUploads = void 0;

var _typeof2 = _interopRequireDefault(require("@babel/runtime/helpers/typeof"));

var _mapValues = _interopRequireDefault(require("lodash/mapValues"));

var _constants = require("./constants");

var mapFileUploads = function mapFileUploads(value) {
  if (value && (0, _typeof2["default"])(value) === 'object') {
    var file = value[_constants.FILE_UPLOAD_KEY];

    if (file) {
      return window[_constants.FILE_UPLOAD_KEY][file];
    }

    return Array.isArray(value) ? value.map(mapFileUploads) : (0, _mapValues["default"])(value, mapFileUploads);
  }

  return value;
};

exports.mapFileUploads = mapFileUploads;