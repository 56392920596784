"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "FormProvider", {
  enumerable: true,
  get: function get() {
    return _FormProvider.FormProvider;
  }
});

var _FormProvider = require("./FormProvider");