"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "Tooltip", {
  enumerable: true,
  get: function get() {
    return _Tooltip.Tooltip;
  }
});

var _Tooltip = require("./Tooltip");

var _TooltipContent = require("./TooltipContent");

var _TooltipTitle = require("./TooltipTitle");

_Tooltip.Tooltip.Content = _TooltipContent.TooltipContent;
_Tooltip.Tooltip.Title = _TooltipTitle.TooltipTitle;