import { createReducer } from '@hhs/ui/old-abyss/ui/redux/helpers/createReducer';
import { GET_USER_SESSION } from './constants';

const initialState = {
  ipAddress: '',
};

export const userReducer = createReducer(initialState, {
  [`${GET_USER_SESSION}_SUCCESS`]: (state, action) => {
    return action.payload;
  },
});
