import { useAction } from '@hhs/ui/old-abyss/ui/redux/hooks/useAction';
import { useSelect } from '@hhs/ui/old-abyss/ui/redux/hooks/useSelect';
import { setStep } from './actions';

const stepsSelector = state => state.steps.step;

export const useSteps = () => {
  return {
    step: useSelect(stepsSelector),
    setStep: useAction(setStep),
  };
};
