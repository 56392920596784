"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createProps = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var createProps = function createProps(obj) {
  return Object.keys(obj || {}).reduce(function (props, key) {
    return Object.assign(props, obj[key] && (0, _defineProperty2["default"])({}, key, obj[key]));
  }, {});
};

exports.createProps = createProps;