export const baseStyles = () => {
  return {
    layout: ({ isWide }) => {
      return {
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: isWide ? 1310 : 1130,
        paddingLeft: isWide ? 0 : 20,
        paddingRight: isWide ? 0 : 20,
      };
    },
  };
};
