"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.configureStore = void 0;

var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));

var _typeof2 = _interopRequireDefault(require("@babel/runtime/helpers/typeof"));

var _redux = require("redux");

var _reduxDevtoolsExtension = require("redux-devtools-extension");

var _getDefaultMiddleware = require("../middleware/getDefaultMiddleware");

var _enableDevTools = require("../middleware/enableDevTools");

var _actions = require("../saga/actions");

var _combineSagas = require("../helpers/combineSagas");

var configureStore = function configureStore(options) {
  var opts = options || {};
  var reducer = opts.reducer,
      _opts$middleware = opts.middleware,
      middleware = _opts$middleware === void 0 ? (0, _getDefaultMiddleware.getDefaultMiddleware)() : _opts$middleware,
      preloadedState = opts.preloadedState,
      _opts$enhancers = opts.enhancers,
      enhancers = _opts$enhancers === void 0 ? [] : _opts$enhancers;
  var rootReducer;

  if (typeof reducer === 'function') {
    rootReducer = reducer;
  } else if ((0, _typeof2["default"])(reducer) === 'object') {
    rootReducer = (0, _redux.combineReducers)(reducer);
  } else {
    throw new Error(['Reducer argument must be a function or an object of functions that ', 'can be passed to combineReducers'].join(','));
  }

  var middlewareEnhancer = _redux.applyMiddleware.apply(void 0, (0, _toConsumableArray2["default"])(middleware));

  var finalCompose = _redux.compose;

  if ((0, _enableDevTools.enableDevTools)()) {
    finalCompose = (0, _reduxDevtoolsExtension.composeWithDevTools)({
      trace: true
    });
  }

  var storeEnhancers = [middlewareEnhancer].concat((0, _toConsumableArray2["default"])(enhancers));
  var composedEnhancer = finalCompose.apply(void 0, (0, _toConsumableArray2["default"])(storeEnhancers));
  var store = (0, _redux.createStore)(rootReducer, preloadedState, composedEnhancer);

  store.loadSagas = function (sagas) {
    var allSagas = (0, _combineSagas.combineSagas)(sagas);
    store.dispatch((0, _actions.loadSagas)(allSagas));
  };

  return store;
};

exports.configureStore = configureStore;