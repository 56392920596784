import React, { useEffect, useState } from 'react';
import { config } from '@abyss/web/tools/config';
import { event } from '@abyss/web/tools/event';
import { Alert } from '@hhs/ui/old-abyss/ui/base/Alert';
import { IconFinancialProtection } from '@hhs/ui/src/base/Icon/Provider/IconFinancialProtection';
import { useStyles } from '@hhs/ui/old-abyss/ui/styles/hooks/useStyles';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { PageHeader } from 'src/app/App/PageHeader';
import { PageLayout } from 'src/app/App/PageLayout';
import { Button } from '@hhs/ui/src/base/Button';
import { FormControl } from '@hhs/ui/old-abyss/ui/form/FormControl';
import { TextInput } from '@hhs/ui/old-abyss/ui/form/TextInput';
import { ErrorMessage } from '@hhs/ui/old-abyss/ui/form/ErrorMessage';
import { Flex } from '@abyss/web/ui/Flex';
import { Checkbox } from '@hhs/ui/old-abyss/ui/form/Checkbox';
import { useForm } from '@hhs/ui/old-abyss/ui/form/hooks/useForm';
import { useModel } from '@hhs/ui/old-abyss/ui/form/hooks/useModel';
import { useAttestation } from 'src/state/attestation/hooks';
import { SelectList } from '@hhs/ui/old-abyss/ui/form/SelectList';
import { ReCaptcha } from '@hhs/ui/src/utility/ReCaptcha';
import { getMessage } from 'src/tools/messages';
import { EMAIL_REGEX } from '@hhs/ui/src/tools/regex';
import moment from 'moment';

import { styles } from './AttestForm.styles';
import { RejectModal } from './RejectModal';
import states from './states.json';
import { useSteps } from 'src/state/steps';

const RECAPTCHA_KEY = config('RECAPTCHA_KEY_EP_CHECKBOX');

export const AttestForm = () => {
  const { setStep } = useSteps();
  const classes = useStyles(styles);
  const form = useForm();
  const attestData = useModel('attest');
  const router = useRouter();
  const [passedCaptcha, setPassedCaptcha] = useState();
  const [error, setError] = useState();
  const [rejectModalOpen, setRejectModalOpen] = useState(false);
  const [isAccept, setAccept] = useState(false);
  const { attestations, submitAttestation, attestationData } = useAttestation();
  const isCheckPayment = attestationData.checkPayment;
  const noOfDays = config('NINTY_DAYS_PAYMENT_ISSUANCE') ? 90 : 1000;
  const deadLineDate = attestationData.settleDate
    ? moment(attestationData.settleDate, 'YYYY-MM-DD')
        .add(noOfDays, 'days')
        .format('MM/DD/YYYY')
    : '';
  const TodayDate = moment()
    .utc()
    .utcOffset('-05:00')
    .format('MM/DD/YYYY');
  const isRejectDisable =
    config('NINTY_DAYS_PAYMENT_ISSUANCE') &&
    deadLineDate &&
    new Date(TodayDate) > new Date(deadLineDate);

  useEffect(() => {
    // handle the page refersh event.
    if(Object.values(attestations).length == 0) {     
      
      setStep(1);
      router.navigate('/step/1');
    } else {
      event('ACCEPT_CONFIRMATION_PAGE_VIEW');
    }
  }, []);

  const handleRecaptcha = value => {
    setPassedCaptcha(value);
  };

  const handleAccept = () => {
    setError();
    setAccept(true);
    form.submit('attest', data => {
      form.submit('attestCheckbox', checkboxData => {
        event('ACCEPT_CONFIRMATION_SUBMISSION', {
          serviceAddressState: data.serviceAddress.state.label,
          serviceAddressZip: data.serviceAddress.zip,
          billingAddressState: data.billingAddress.state.label,
          billingAddressZip: data.billingAddress.zip,
        });

        const payload = {
          ...data,
          ...attestationData,
          ...checkboxData,
          accepted: true,
          token: passedCaptcha,
        };

        submitAttestation(
          payload,
          () => {
            router.navigate('/step/4');
          },
          respError => {
            let errorType = 'Error';
            let errorMessage = respError.message;

            if (respError.code === 'HHACDR') {
              errorType = 'Duplicate Submission';
              errorMessage = getMessage('submit-attestation[HHACDR]', {
                tin: attestationData.maskedTin,
              });
            }

            event('SUBMIT_ATTESTATION_ERROR', {
              errorType,
              errorMessage,
              errorCode: respError.code,
              payload,
            });

            setError(respError);
          }
        );
      });
    });
  };

  const handleReject = () => {
    setError();
    form.submit('attest', () => {
      setRejectModalOpen(true);
    });
  };

  const handleModalClose = () => {
    setRejectModalOpen(false);
  };

  const handleModalReject = () => {
    event('REJECT_CONFIRMATION_SUBMISSION', {
      serviceAddressState: attestData.serviceAddress.state.label,
      serviceAddressZip: attestData.serviceAddress.zip,
      billingAddressState: attestData.billingAddress.state.label,
      billingAddressZip: attestData.billingAddress.zip,
    });

    const payload = {
      ...attestData,
      ...attestationData,
      accepted: false,
      token: passedCaptcha,
    };

    submitAttestation(
      payload,
      () => {
        router.navigate('/step/4');
      },
      respError => {
        let errorType = 'Error';
        let errorMessage = respError.message;

        if (respError.code === 'HHACDR') {
          errorType = 'Duplicate Submission';
          errorMessage = getMessage('submit-attestation[HHACDR]', {
            tin: attestationData.maskedTin,
          });
        }

        event('SUBMIT_ATTESTATION_ERROR', {
          errorType,
          errorMessage,
          errorCode: respError.code,
          payload,
        });

        setError(respError);
      }
    );
    setRejectModalOpen(false);
  };

  return (
    <React.Fragment>
      <RejectModal
        isCheckPayment={isCheckPayment}
        isLoading={submitAttestation.saga.isLoading}
        isOpen={rejectModalOpen}
        onClose={handleModalClose}
        onConfirm={handleModalReject}
      />
      <PageHeader>
        <PageHeader.Title>
          Attestation and Payment Confirmation
        </PageHeader.Title>
      </PageHeader>
      <PageLayout className={classes.content}>
        <Flex>
          <Flex.Content className={classes.section}>
            <Flex direction='row' alignContent='stretch' className={classes.section}>
              <IconFinancialProtection className={classes.headerIcon} size={36} />
              <h2>Payment Terms Attestation</h2>
              <div className={classes.horizontalLine} />
            </Flex>
            <Flex direction='row' justify='space-between'>
              <Flex.Content className={classes.indent}>
                <div className={classes.topSection}>
                  Please attest to and accept the{' '}
                  <a
                    href={config('RELIEF_FUND_TERMS_AND_CONDITIONS_PDF')}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.accessiblityLink}
                  >
                    Terms & Conditions
                  </a>{' '}
                  below for each TIN you have entered. The current TIN is shown
                  in the box to the right. Once you complete the first TIN you
                  will be asked to attest to each TIN in the list.
                </div>
                <FormControl
                  id="attest-amountReceived"
                  model="attestCheckbox.attestAmountReceived"
                  className={classes.formControlCheckbox}
                  required
                >
                  {(inputProps, errorProps) => (
                    <React.Fragment>
                      <Checkbox
                        {...inputProps}
                        validators={{
                          required: value => !value,
                        }}
                      >
                        <div className={classes.checkboxMargin}>
                          I acknowledge receipt of{' '}
                          <b>{attestationData.amountPaid}</b> from the Public
                          Health and Social Services Emergency Fund ("Relief
                          Fund"), and accept the{' '}
                          <a
                            href={config(
                              'RELIEF_FUND_TERMS_AND_CONDITIONS_PDF'
                            )}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={classes.accessiblityLink}
                          >
                            Terms & Conditions
                          </a>
                          . If you received a payment from funds appropriated in
                          the Relief Fund under Division B of Public Law 116-127
                          and retain that payment for at least 90 days without
                          contacting HHS regarding remittance of those funds,
                          you are deemed to have accepted the following{' '}
                          <a
                            href={config(
                              'RELIEF_FUND_TERMS_AND_CONDITIONS_PDF'
                            )}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={classes.accessiblityLink}
                          >
                            Terms & Conditions
                          </a>
                          . This is not an exhaustive list and you must comply
                          with any other relevant statutes and regulations, as
                          applicable. Your commitment to full compliance with
                          all Terms and Conditions is material to the
                          Secretary’s decision to disburse these funds to you.
                          Non-compliance with any Term or Condition is grounds
                          for the Secretary to recoup some or all of the payment
                          made from the Relief Fund. These Terms and Conditions
                          apply directly to the recipient of payment from the
                          Relief Fund. In general, the requirements that apply
                          to the recipient, also apply to sub-recipients and
                          contractors under grants, unless an exception is
                          specified.
                        </div>
                      </Checkbox>
                      <ErrorMessage
                        {...errorProps}
                        messages={{
                          required: 'This is required.',
                        }}
                      />
                    </React.Fragment>
                  )}
                </FormControl>
                <FormControl
                  id="attest-cares"
                  model="attestCheckbox.attestPayment"
                  className={classes.formControlCheckbox}
                  required
                >
                  {(inputProps, errorProps) => (
                    <React.Fragment>
                      <Checkbox
                        {...inputProps}
                        validators={{
                          required: value => !value,
                        }}
                      >
                        <div className={classes.checkboxMargin}>
                          By receiving and accepting Relief Fund payment, you
                          attest that in accordance with the "Coronavirus Aid,
                          Relief, and Economic Security (CARES) Act (P.L.
                          116-136); the Paycheck Protection Program (PPP) and
                          Health Care Enhancement Act (P.L. 116-139), the
                          Coronavirus Response and Relief Supplemental
                          Appropriations (CRRSA) Act (P.L. 116-123), and the
                          American Rescue Plan Act (PL 117-002)", you are
                          eligible for this payment. You acknowledge that you
                          may be asked to submit to the review process
                          established by the U.S Department of Health and Human
                          Services, including its contractor (collectively,
                          "HHS"), to determine your eligibility for this
                          payment. Additionally, upon request by HHS, you will
                          provide any and all information related to the
                          disposition or use of the funds received under the
                          Relief Fund for auditing and/or reporting purposes. I
                          attest that I have the legal authority to act on
                          behalf of the provider group that has received payment
                          under the Relief Fund. For Electronic Funds Transfer /
                          ACH Payments, HHS or its contractor may make
                          adjustments to the payment whenever a correction or
                          change is required. For example, if there is an error,
                          you agree that HHS may correct the error immediately
                          and without notice. Such errors may include, but are
                          not limited to, reversing an improper credit, and
                          correcting calculation and input errors. The right to
                          make adjustments are not subject to any limitations or
                          time constraints, except as required by law.
                        </div>
                      </Checkbox>
                      <ErrorMessage
                        {...errorProps}
                        messages={{
                          required: 'This is required.',
                        }}
                      />
                    </React.Fragment>
                  )}
                </FormControl>
                <div className={classes.section}>
                  By providing your email and phone number, you agree that HRSA
                  or its contractor may send you communications or call you
                  regarding Relief Fund payment. You understand that you need to
                  give us the most up to date contact information.
                </div>
              </Flex.Content>
              <Flex.Content>
                <div className={classes.infoBox}>
                  <div className={classes.infoBoxTitle}>
                    <h3>
                      <b>Current Request</b>
                    </h3>
                  </div>
                  <hr className={classes.hr} />
                  <div className={classes.infoBoxSection}>
                    <div className={classes.infoBoxLabel}>Billing TIN</div>
                    <b>{attestationData.maskedTin}</b>
                  </div>
                  <Switch>
                    <Case condition={attestationData.checkPayment}>
                      <div className={classes.infoBoxSection}>
                        <div className={classes.infoBoxLabel}>Check Number</div>
                        <b>{attestationData.accountNumber}</b>
                      </div>
                    </Case>
                    <Default>
                      <div className={classes.infoBoxSection}>
                        <div className={classes.infoBoxLabel}>
                          Last Six Digits of Account Number
                        </div>
                        <b>{attestationData.maskedAccountNumber}</b>
                      </div>
                    </Default>
                  </Switch>
                  <div className={classes.infoBoxSection}>
                    <div className={classes.infoBoxLabel}>
                      Relief Fund Payment
                    </div>
                    <b>{attestationData.amountPaid}</b>
                  </div>
                </div>
              </Flex.Content>
            </Flex>
            <fieldset className={classes.section2}>
              <legend>
                <h3>
                  <b>Contact Information</b>
                </h3>
                Please complete the information below. All fields are required
                unless otherwise indicated.
              </legend>
              <FormControl
                id="attest-first-name"
                model="attest.firstName"
                label="First Name"
                className={classes.formControl}
                required
              >
                {(inputProps, errorProps) => (
                  <React.Fragment>
                    <TextInput
                      {...inputProps}
                      maxLength={80}
                      validators={{
                        required: value => !value,
                      }}
                    />
                    <ErrorMessage
                      {...errorProps}
                      messages={{
                        required: 'Please enter a First Name.',
                      }}
                    />
                  </React.Fragment>
                )}
              </FormControl>
              <FormControl
                id="attest-middle-name"
                model="attest.middleName"
                label="Middle Name (optional)"
                className={classes.formControl}
              >
                {(inputProps, errorProps) => (
                  <React.Fragment>
                    <TextInput {...inputProps} maxLength={80} />
                    <ErrorMessage {...errorProps} />
                  </React.Fragment>
                )}
              </FormControl>
              <FormControl
                id="attest-last-name"
                model="attest.lastName"
                label="Last Name"
                className={classes.formControl}
                required
              >
                {(inputProps, errorProps) => (
                  <React.Fragment>
                    <TextInput
                      {...inputProps}
                      maxLength={80}
                      validators={{
                        required: value => !value,
                      }}
                    />
                    <ErrorMessage
                      {...errorProps}
                      messages={{
                        required: 'Please enter a Last Name.',
                      }}
                    />
                  </React.Fragment>
                )}
              </FormControl>
              <FormControl
                id="attest-email-address"
                model="attest.emailAddress"
                label="Email Address"
                className={classes.formControl}
                required
              >
                {(inputProps, errorProps) => (
                  <React.Fragment>
                    <TextInput
                      {...inputProps}
                      maxLength={80}
                      validators={{
                        required: value => !value,
                        emailFormat: value => !EMAIL_REGEX.test(value),
                      }}
                    />
                    <ErrorMessage
                      {...errorProps}
                      messages={{
                        required: 'Please enter an Email Address.',
                        emailFormat: 'Please enter a valid Email Address.',
                      }}
                    />
                  </React.Fragment>
                )}
              </FormControl>
              <FormControl
                id="attest-phone-number"
                model="attest.phoneNumber"
                label="Phone Number"
                className={classes.formControl}
                required
              >
                {(inputProps, errorProps) => (
                  <React.Fragment>
                    <TextInput
                      {...inputProps}
                      type="phone"
                      validators={{
                        required: value => !value,
                      }}
                    />
                    <ErrorMessage
                      {...errorProps}
                      messages={{
                        required: 'Please enter a Phone Number.',
                      }}
                    />
                  </React.Fragment>
                )}
              </FormControl>
            </fieldset>
            <div className={classes.section2}>
              <Flex direction='row'>
                <Flex.Content className={classes.half}>
                  <fieldset className={classes.fieldset}>
                    <legend>
                      <h3>
                        <b>Rendering/Service Address</b>
                      </h3>
                    </legend>
                    <FormControl
                      id="attest-service-street-address-1"
                      model="attest.serviceAddress.addressLine1"
                      label="Address 1"
                      className={classes.formControl}
                      required
                    >
                      {(inputProps, errorProps) => (
                        <React.Fragment>
                          <TextInput
                            {...inputProps}
                            maxLength={80}
                            validators={{
                              required: value => !value,
                            }}
                          />
                          <ErrorMessage
                            {...errorProps}
                            messages={{
                              required: 'Please enter a Street Address.',
                            }}
                          />
                        </React.Fragment>
                      )}
                    </FormControl>
                    <FormControl
                      id="attest-service-street-address-2"
                      model="attest.serviceAddress.addressLine2"
                      label="Address 2 (optional)"
                      className={classes.formControl}
                    >
                      {(inputProps, errorProps) => (
                        <React.Fragment>
                          <TextInput {...inputProps} maxLength={80} />
                          <ErrorMessage {...errorProps} />
                        </React.Fragment>
                      )}
                    </FormControl>
                    <FormControl
                      id="attest-service-city"
                      model="attest.serviceAddress.city"
                      label="City"
                      className={classes.formControl}
                      required
                    >
                      {(inputProps, errorProps) => (
                        <React.Fragment>
                          <TextInput
                            {...inputProps}
                            maxLength={80}
                            validators={{
                              required: value => !value,
                            }}
                          />
                          <ErrorMessage
                            {...errorProps}
                            messages={{
                              required: 'Please enter a City.',
                            }}
                          />
                        </React.Fragment>
                      )}
                    </FormControl>
                    <FormControl
                      id="attest-service-state"
                      model="attest.serviceAddress.state"
                      label="State"
                      className={classes.formControl}
                      required
                    >
                      {(inputProps, errorProps) => (
                        <React.Fragment>
                          <SelectList
                            placeholder="Select..."
                            {...inputProps}
                            options={states}
                            validators={{
                              required: value => !value,
                            }}
                          />
                          <ErrorMessage
                            {...errorProps}
                            messages={{
                              required: 'Please select a State.',
                            }}
                          />
                        </React.Fragment>
                      )}
                    </FormControl>
                    <FormControl
                      id="attest-service-zip-code"
                      model="attest.serviceAddress.zip"
                      label="Zip Code"
                      className={classes.formControl}
                      required
                    >
                      {(inputProps, errorProps) => (
                        <React.Fragment>
                          <TextInput
                            {...inputProps}
                            type="zip"
                            validators={{
                              required: value => !value,
                            }}
                          />
                          <ErrorMessage
                            {...errorProps}
                            messages={{
                              required: 'Please enter a 5 digit zip code.',
                            }}
                          />
                        </React.Fragment>
                      )}
                    </FormControl>
                  </fieldset>
                </Flex.Content>
                <Flex.Content className={classes.half}>
                  <fieldset className={classes.fieldset}>
                    <legend>
                      <h3>
                        <b>Billing Address</b>
                      </h3>
                    </legend>
                    <FormControl
                      id="attest-billing-street-address-1"
                      model="attest.billingAddress.addressLine1"
                      label="Address 1"
                      className={classes.formControl}
                      required
                    >
                      {(inputProps, errorProps) => (
                        <React.Fragment>
                          <TextInput
                            {...inputProps}
                            maxLength={80}
                            validators={{
                              required: value => !value,
                            }}
                          />
                          <ErrorMessage
                            {...errorProps}
                            messages={{
                              required: 'Please enter a Street Address.',
                            }}
                          />
                        </React.Fragment>
                      )}
                    </FormControl>
                    <FormControl
                      id="attest-billing-street-address-2"
                      model="attest.billingAddress.addressLine2"
                      label="Address 2 (optional)"
                      className={classes.formControl}
                    >
                      {(inputProps, errorProps) => (
                        <React.Fragment>
                          <TextInput {...inputProps} maxLength={80} />
                          <ErrorMessage {...errorProps} />
                        </React.Fragment>
                      )}
                    </FormControl>
                    <FormControl
                      id="attest-billing-city"
                      model="attest.billingAddress.city"
                      label="City"
                      className={classes.formControl}
                      required
                    >
                      {(inputProps, errorProps) => (
                        <React.Fragment>
                          <TextInput
                            {...inputProps}
                            maxLength={80}
                            validators={{
                              required: value => !value,
                            }}
                          />
                          <ErrorMessage
                            {...errorProps}
                            messages={{
                              required: 'Please enter a City.',
                            }}
                          />
                        </React.Fragment>
                      )}
                    </FormControl>
                    <FormControl
                      id="attest-billing-state"
                      model="attest.billingAddress.state"
                      label="State"
                      className={classes.formControl}
                      required
                    >
                      {(inputProps, errorProps) => (
                        <React.Fragment>
                          <SelectList
                            placeholder="Select..."
                            {...inputProps}
                            options={states}
                            validators={{
                              required: value => !value,
                            }}
                          />
                          <ErrorMessage
                            {...errorProps}
                            messages={{
                              required: 'Please select a State.',
                            }}
                          />
                        </React.Fragment>
                      )}
                    </FormControl>
                    <FormControl
                      id="attest-billing-zip-code"
                      model="attest.billingAddress.zip"
                      label="Zip Code"
                      className={classes.formControl}
                      required
                    >
                      {(inputProps, errorProps) => (
                        <React.Fragment>
                          <TextInput
                            {...inputProps}
                            type="zip"
                            validators={{
                              required: value => !value,
                            }}
                          />
                          <ErrorMessage
                            {...errorProps}
                            messages={{
                              required: 'Please enter a 5 digit zip code.',
                            }}
                          />
                        </React.Fragment>
                      )}
                    </FormControl>
                  </fieldset>
                </Flex.Content>
              </Flex>
            </div>
            <If condition={!isCheckPayment}>
              <Then>
                <div className={classes.formControlCheckbox}>
                  <FormControl
                    id="optum-pay-enrollment-agreement"
                    model="attestCheckbox.optumPayEnrollmentAgreement"
                    required
                  >
                    {(inputProps, errorProps) => (
                      <React.Fragment>
                        <Checkbox
                          {...inputProps}
                          validators={{
                            required: value => !value,
                          }}
                        >
                          <div className={classes.checkboxMargin}>
                            I have read and agree to the{' '}
                            <a
                              href={`${config(
                                'BASE_APP_URL'
                              )}/optum-pay-enrollment-agreement`}
                              target="_blank"
                              rel="noopener noreferrer"
                              className={classes.accessiblityLink}
                            >
                              Optum Pay Enrollment Agreement Terms and Conditions
                            </a>
                            .
                          </div>
                        </Checkbox>
                        <ErrorMessage
                          {...errorProps}
                          messages={{
                            required: 'This is required.',
                          }}
                        />
                      </React.Fragment>
                    )}
                  </FormControl>
                </div>
              </Then>
            </If>
            <div className={classes.padding}>
            <ReCaptcha onClick={handleRecaptcha} sitekey={RECAPTCHA_KEY} />
            </div>
            <If condition={error}>
              <Then>
                <Switch>
                  <Case condition={error?.code === 'HHACDR'}>
                    <Alert className={classes.alertMessage}>
                      <Alert.Title>Duplicate Submission</Alert.Title>
                      {getMessage('submit-attestation[HHACDR]', {
                        tin: attestationData.maskedTin,
                      })}
                    </Alert>
                  </Case>
                  <Default>
                    <Alert
                      errorCode={error?.code}
                      className={classes.alertMessage}
                    >
                      <Alert.Title>Error</Alert.Title>
                      {error?.message}
                    </Alert>
                  </Default>
                </Switch>
              </Then>
            </If>
            <Button
              className={classes.actionButton}
              onClick={handleAccept}
              isDisabled={!passedCaptcha}
              isLoading={isAccept && submitAttestation.saga.isLoading}
            >
              I Accept Payment
            </Button>
            <If condition={!isRejectDisable}>
              <Then>
                <Button
                  variant="outline"
                  className={classes.actionButton}
                  onClick={handleReject}
                  isDisabled={!passedCaptcha}
                >
                  I Reject Payment
                </Button>
              </Then>
            </If>
          </Flex.Content>
        </Flex>
      </PageLayout>
    </React.Fragment>
  );
};
