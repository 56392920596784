import React from 'react';
import { Icon } from '@abyss/web/ui/Icon';

export const IconInfo = props => {
  return (
    <Icon {...props}>
      <svg width="40px" height="40px" viewBox="0 0 40 40" focusable="false">
        <desc>Created with Sketch.</desc>
        <g
          id="Seamless-Icons"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="fill-info"
            transform="translate(-10.000000, -10.000000)"
            fill="#005eea"
            fillRule="nonzero"
          >
            <g transform="translate(10.000000, 10.000000)" id="i-icon">
              <path d="M20,0 C31.0317461,0 40,8.96825397 40,20 C40,31.0317461 31.0317461,40 20,40 C8.96825397,40 0,31.0317461 0,20 C0,8.96825397 8.96825397,0 20,0 Z M21.2460317,16.047619 L18.7301587,16.047619 L18.7301587,31.4285715 L21.2460317,31.4285715 L21.2460317,16.047619 Z M20,8.92063493 C19.0714286,8.92063493 18.2777778,9.75396827 18.2777778,10.7380952 C18.2777778,11.7301587 19.0793651,12.5555555 19.968254,12.5555555 C20.9206349,12.5555555 21.7222222,11.7301587 21.7222222,10.7380952 C21.7222222,9.74603173 20.952381,8.92063493 20,8.92063493 Z" />
            </g>
          </g>
        </g>
      </svg>
    </Icon>
  );
};
