import React from 'react';
import PropTypes from 'prop-types';
import { useStyles } from '@hhs/ui/old-abyss/ui/styles/hooks/useStyles';
import { useColor } from '@hhs/ui/old-abyss/ui/styles/hooks/useColor';
import { classNames } from '@abyss/web/tools/classNames';

import { baseStyles } from './TimelineStep.styles';

export const TimelineStep = ({
  color,
  className,
  styles,
  children,
  ...props
}) => {
  const stepColor = useColor(color);
  const classes = useStyles(baseStyles, { color: stepColor }, styles);

  return (
    <li {...props} className={classNames(classes.timelineStep, className)}>
      <div className={classes.timelineStepContent}>{children}</div>
    </li>
  );
};

TimelineStep.propTypes = {
  className: PropTypes.string,
  styles: PropTypes.shape({}),
  children: PropTypes.node,
  color: PropTypes.string,
};

TimelineStep.defaultProps = {
  className: null,
  styles: null,
  children: null,
  color: 'gray300',
};
