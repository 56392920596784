"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.baseStyles = void 0;

var baseStyles = function baseStyles(theme) {
  return {
    inputClear: {
      display: 'flex',
      alignItems: 'center',
      color: '#005eaa !important',
      paddingLeft: 10,
      '&:hover': {
        color: theme.colors.secondary
      }
    },
    inputClearSvg: {
      cursor: 'pointer',
      display: 'inline-block',
      fill: 'currentColor',
      lineHeight: 1,
      stroke: 'currentColor',
      strokeWidth: 0
    }
  };
};

exports.baseStyles = baseStyles;