export const styles = theme => {
  return {
    formControl: {
      marginTop: 24,
    },
    horizontalLine: {
      height: '50%',
      borderBottom: '1px solid #D8D8D8',
      marginLeft: 12,
    },
    subHeading: {
      marginTop: 10,
      marginBottom: 25,
      lineHeight: '18px',
    },
    accessiblityLink: {
      '&:focus': {
        outline: 'none',
        boxShadow: '0px 0px 5px 5px rgb(174,202,225)',
      },
    },
    headerIcon: {
      marginRight: 20,
    },
    continueButton: {
      marginTop: 40,
      borderRadius: 3,
      width: '33%',
    },
    printButton: {
      borderRadius: 3,
    },
    tinTextbox: {
      height: 200,
      width: 300,
    },
    cardContainer: {
      marginTop: 24,
    },
    cardContent: {
      position: 'relative',
      backgroundColor: '#ffffff',
      border: '1px solid #CBCBCB',
      width: 550,
    },
    cardHeader: {
      padding: 15,
      borderBottom: '1px solid #CBCBCB',
      fontSize: 14,
      lineHeight: '20px',
      color: '#666666',
    },
    cardSubHeader: {
      padding: 15,
      borderBottom: '1px solid #CBCBCB',
      fontSize: 14,
      lineHeight: '20px',
      color: '#666666',
    },
    deadLine: {
      padding: 10,
      border: '1px solid #83B6F0',
      fontSize: 14,
      lineHeight: '9px',
      textAlign: 'center',
      backgroundColor: '#F1FAFC !important',
      borderRadius: 3,
    },
    deadLineLabel: {
      color: '#666666',
    },
    deadLineText: {
      fontSize: 11,
      lineHeight: 1.5,
      color: '#000000',
      textAlign: 'justify',
      fontWeight: 'normal',
      marginBottom: 1,
    },
    cardHeaderText: {
      fontSize: 18,
      color: '#000000',
    },
    cardText: {
      padding: 15,
    },
    cardTextFooter: {
      marginTop: 10,
    },
    cardSubsection: {
      marginBottom: 24,
      width: '50%',
    },
    cardTopSection: {
      width: '100%',
    },
    cardSubsectionTitle: {
      color: '#666666',
      marginRight: 10,
      fontSize: 14,
    },
    cardHr: {
      height: 1,
      backgroundColor: '#CBCBCB',
      color: '#CBCBCB',
      border: 'none',
    },
    sectionWrapper: {
      padding: 12,
    },
    cardButton: {
      borderRadius: 3,
    },
    cardButtonDesc: {
      fontWeight: 'normal',
      lineHeight: '20px',
      fontSize: 14,
      marginBottom: 10,
    },
    cardButtonSection: {
      margin: [12, 6, 0, 6],
      fontWeight: 'bold',
    },
    confirmationBadge: {
      marginTop: 10,
      width: '100%',
      padding: 5,
    },
    topBorderColor: {
      height: 2,
      width: '100%',
      backgroundColor: '#196ECF',
      position: 'absolute',
      top: 0,
      left: 0,
    },
    badge: {
      position: 'absolute',
      top: -13,
      right: -1,
      color: '#000 !important',
      backgroundColor: '#F1FAFC !important',
      border: '1px solid #196ECF',
      padding: '2px 20px !important',
    },
    badgeNum: {
      fontWeight: 'normal',
    },
    labelHeader: {
      fontSize: 13,
      fontWeight: 'bold',
      marginBottom: 12,
    },
    section: {
      marginTop: 48,
      border: 'none',
    },
    full: {
      width: '90%',
    },
    liner: {
      flex: 1,
    },
  };
};
