import { config } from '@abyss/web/tools/config';
import { http } from 'src/tools/http';

export const validateAdditionalPaymentRequest = async payload => {
  const { data } = await http({
    method: 'POST',
    url: `${config('HHS_API_URL')}/api/validate/requestId`,
    headers: {
      recaptchaenterprise: payload.token,
    },
    data: {
      tin: payload.billingTin,
      requestId: payload.referenceNumber,
    },
  });
  return {
    ...payload,
    valid: data.valid || true,
  };
};
