"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "http", {
  enumerable: true,
  get: function get() {
    return _http.http;
  }
});
Object.defineProperty(exports, "createInstance", {
  enumerable: true,
  get: function get() {
    return _http.createInstance;
  }
});

var _http = require("./http");