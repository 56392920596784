"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "tokenizer", {
  enumerable: true,
  get: function get() {
    return _tokenizer.tokenizer;
  }
});

var _tokenizer = require("./tokenizer");