"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.baseStyles = void 0;

var baseStyles = function baseStyles() {
  return {
    error: {
      borderWidth: 20,
      borderStyle: 'solid',
      borderColor: '#BB3131',
      backgroundColor: '#000',
      zIndex: 999999,
      color: '#FFF',
      padding: '10px 20px'
    },
    message: {
      whiteSpace: 'pre-wrap',
      fontSize: 14,
      fontWeight: 600,
      letterSpacing: '0.2px',
      margin: '10px 0'
    },
    wrapper: {
      whiteSpace: 'pre-wrap',
      paddingLeft: 20
    },
    stack: {
      fontSize: 16,
      fontFamily: 'monospace'
    }
  };
};

exports.baseStyles = baseStyles;