"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getFields = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _toArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toArray"));

var _pickBy = _interopRequireDefault(require("lodash/pickBy"));

var _startsWith = _interopRequireDefault(require("lodash/startsWith"));

var getFields = function getFields(state) {
  var model = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';

  var _model$split = model.split('.'),
      _model$split2 = (0, _toArray2["default"])(_model$split),
      formKey = _model$split2[0],
      path = _model$split2.slice(1);

  var form = state[formKey] || {};
  var fieldPath = path.join('.');

  if (form.fields && form.fields[fieldPath]) {
    return (0, _defineProperty2["default"])({}, fieldPath, form.fields[fieldPath]);
  }

  return (0, _pickBy["default"])(form.fields, function (v, k) {
    return (0, _startsWith["default"])(k, fieldPath);
  });
};

exports.getFields = getFields;