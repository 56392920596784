"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "useAction", {
  enumerable: true,
  get: function get() {
    return _useAction.useAction;
  }
});

var _useAction = require("./useAction");