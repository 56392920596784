"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.sagaReducer = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _combineSagas = require("../helpers/combineSagas");

var _actions = require("./actions");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var SAGA_ACTION_REGEX = /_(STARTED|SUCCESS|ERROR)$/;
var initialSagaState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  errorCode: '',
  errorMessage: ''
};

var sagaReducer = function sagaReducer(sagas) {
  var initialState = {};

  if (sagas) {
    var allSagas = (0, _combineSagas.combineSagas)(sagas);
    initialState = allSagas.reduce(function (newObj, saga) {
      var _saga$payload$args = (0, _slicedToArray2["default"])(saga.payload.args, 1),
          type = _saga$payload$args[0];

      return _objectSpread(_objectSpread({}, newObj), {}, (0, _defineProperty2["default"])({}, type, initialSagaState));
    }, {});
  }

  return function () {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
    var action = arguments.length > 1 ? arguments[1] : undefined;

    if (action.type === _actions.LOAD_SAGAS) {
      var newSagas = action.payload.reduce(function (newObj, saga) {
        var _saga$payload$args2 = (0, _slicedToArray2["default"])(saga.payload.args, 1),
            type = _saga$payload$args2[0];

        return _objectSpread(_objectSpread({}, newObj), {}, (0, _defineProperty2["default"])({}, type, initialSagaState));
      }, {});
      return _objectSpread(_objectSpread({}, newSagas), state);
    }

    if (!action.meta || !action.meta.__abyss_saga__) {
      return state;
    }

    var key = action.type.replace(SAGA_ACTION_REGEX, '');
    return _objectSpread(_objectSpread({}, state), {}, (0, _defineProperty2["default"])({}, key, _objectSpread(_objectSpread({}, state[key]), action.meta.__abyss_saga__)));
  };
};

exports.sagaReducer = sagaReducer;