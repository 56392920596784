import { createReducer } from '@hhs/ui/old-abyss/ui/redux/helpers/createReducer';
import { SET_STEP } from './constants';

const initialState = {
  step: 1,
};

export const stepsReducer = createReducer(initialState, {
  [SET_STEP]: (state, action) => {
    return {
      step: action.payload,
    };
  },
});
