"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useAction = void 0;

var _react = require("react");

var _reactRedux = require("react-redux");

var useAction = function useAction(actionFn, meta) {
  var dispatch = (0, _reactRedux.useDispatch)();
  var action = (0, _react.useCallback)(function () {
    return dispatch(actionFn.apply(void 0, arguments));
  }, [dispatch]);

  if (meta) {
    Object.keys(meta).forEach(function (key) {
      action[key] = meta[key];
    });
  }

  return action;
};

exports.useAction = useAction;