import React from 'react';
import PropTypes from 'prop-types';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { useForm } from '@hhs/ui/old-abyss/ui/form/hooks/useForm';
import { useStyles } from '@hhs/ui/old-abyss/ui/styles/hooks/useStyles';
import { config } from '@abyss/web/tools/config';
import { event } from '@abyss/web/tools/event';
import { Flex } from '@abyss/web/ui/Flex';
import { Button } from '@hhs/ui/src/base/Button';
import { Badge } from '@abyss/web/ui/Badge';
import { classNames } from '@abyss/web/tools/classNames';
import { getFeature } from '@hhs/api/src/tools/features';
import moment from 'moment';
import { useAttestation } from 'src/state/attestation/hooks';
import { styles } from '../Attestations.styles';


export const AttestationCard = ({ attestation }) => {
  const classes = useStyles(styles);
  const form = useForm();
  const router = useRouter();
  const { loadAttestation } = useAttestation();
  const noOfDays = config('NINTY_DAYS_PAYMENT_ISSUANCE') ? 90 : 1000;
  const hooText = getFeature('HRSA_PRF_HOO');
  const deadLineDate = attestation.settleDate
    ? moment(attestation.settleDate, 'YYYY-MM-DD')
        .add(noOfDays, 'days')
        .format('MM/DD/YYYY')
    : '';
  const TodayDate = moment()
    .utc()
    .utcOffset('-05:00')
    .format('MM/DD/YYYY');
  const onReview = () => {
    event('ATTESTATIONS_SUBMISSION');
    loadAttestation(attestation);
    form.reset('attest');
    form.reset('attestCheckbox');
    router.navigate('/attestation');
  };

  return (
    <Switch>
      <Case condition={!attestation.allowed}>
        <div className={classNames(classes.section, classes.cardContent)}>
          <h4 className={classes.cardHeader}>
            <b>Payment Not Found</b>
          </h4>
          <div className={classes.cardText}>
            This portal is for attesting to Provider Relief Fund Phase 1 General
            Distributions or Targeted Distributions. To attest to Phase 2 or
            Phase 3 General Distributions, please visit the{' '}
            <a
              href={config('PORTAL2_LINKHEALTH_URL')}
              target="_blank"
              rel="noopener noreferrer"
            >
              Provider Relief Fund Application and Attestation Portal.
            </a>
            <br />
            <br />
            For additional information, please visit{' '}
            <a
              href={config('CORONAVIRUS_GOV_URL')}
              target="_blank"
              rel="noopener noreferrer"
              className={classes.accessiblityLink}
            >
              hrsa.gov/provider-relief
            </a>{' '}
            or call the provider support line at (866) 569-3522; for TTY dial
            711. {hooText}
            <div className={classes.cardTextFooter}>
              Billing ID Number (TIN):
              {` ${attestation.maskedTin}`}
            </div>
          </div>
        </div>
      </Case>
      <Default>
        <div className={classes.cardContent} style={{ height: '100%' }}>
          <Flex>
            <Flex.Content className={classes.cardTopSection}>
              <h4 className={classes.cardHeader}>
                <b>Billing TIN:</b>
                <div className={classes.cardHeaderText}>
                  <b>{attestation.maskedTin}</b>
                </div>
              </h4>
            </Flex.Content>
            <Flex.Content>
              <If condition={attestation.confirmationId}>
                <Then>
                  <div className={classes.topBorderColor} />
                  <Badge variant="solid" color="info" className={classes.badge}>
                    <span className={classes.badgeNum}>Reference Number:</span>{' '}
                    {attestation.confirmationId}
                  </Badge>
                </Then>
              </If>
            </Flex.Content>
          </Flex>
          <div className={classes.sectionWrapper}>
            <Flex>
              <Flex.Content className={classes.cardSubsection}>
                <div className={classes.cardSubsectionTitle}>
                  Last Six Digits of Account Number
                </div>
                <b>{attestation.maskedAccountNumber}</b>
              </Flex.Content>
              <Flex.Content className={classes.cardSubsection}>
                <div className={classes.cardSubsectionTitle}>
                  Relief Fund Payment
                </div>
                <b>{attestation.amountPaid}</b>
              </Flex.Content>
            </Flex>
            <hr className={classes.cardHr} />
            <div className={classes.cardButtonSection}>
              <Switch>
                <Case condition={attestation.confirmationId}>
                  <div className={classes.cardButtonDesc}>
                    I acknowledge deposit from the Public Health and Social
                    Services Emergency Fund ("Relief Fund").
                  </div>
                  {attestation.accepted ? 'Funds Accepted' : 'Funds Rejected'}
                </Case>
                <Case
                  condition={
                    config('NINTY_DAYS_PAYMENT_ISSUANCE') &&
                    deadLineDate &&
                    new Date(TodayDate) > new Date(deadLineDate)
                  }
                >
                  <div className={classes.deadLine}>
                    <div>
                      <p className={classes.deadLineText}>
                        It has been at least 90 days since the ACH payment date;
                        therefore, the rejection window has passed for this
                        Organization TIN payment. You can proceed with
                        attestation. For further information please call the
                        toll-free Provider Support Line at (866) 569-3522, for
                        TTY dial 711. {hooText}
                        <br />
                        Expiration Date: <b>{deadLineDate}</b>
                      </p>
                      <Button
                        onClick={onReview}
                        className={classes.cardButton}
                      >
                        Review and Accept
                      </Button>
                    </div>
                  </div>
                </Case>
                <Case
                  condition={
                    deadLineDate && new Date(TodayDate) > new Date(deadLineDate)
                  }
                >
                  <div className={classes.deadLine}>
                    <div>
                      <p className={classes.deadLineText}>
                        It has been at least 45 days since the ACH payment date;
                        therefore the attestation window has passed for this
                        Billing TIN payment. If you retained these funds you are
                        now deemed to have accepted the terms and conditions for
                        this distribution. No additional action is required at
                        this time. If you have questions please call the
                        toll-free Provider Support Line at (866) 569-3522, TTY
                        dial 711. {hooText}
                        <br />
                        Expiration Date: <b>{deadLineDate}</b>
                      </p>
                    </div>
                  </div>
                </Case>
                <Default>
                  <Button
                    onClick={onReview}
                    className={classes.cardButton}
                  >
                    Review and Accept
                  </Button>
                </Default>
              </Switch>
            </div>
          </div>
        </div>
      </Default>
    </Switch>
  );
};

AttestationCard.propTypes = {
  attestation: PropTypes.shape({
    tin: PropTypes.string,
    maskedTin: PropTypes.string,
    maskedAccountNumber: PropTypes.string,
    amountPaid: PropTypes.string,
    confirmationId: PropTypes.string,
    confirmed: PropTypes.bool,
    accepted: PropTypes.bool,
    settleDate: PropTypes.string,
    allowed: PropTypes.bool,
  }).isRequired,
};
