import React from 'react';
import { Button as AbyssButton } from '@abyss/web/ui/Button';

export const Button = props => {
  return (
    <AbyssButton
      {...props}
      styles={{
        button: (base, theme, prop) => {
          if (prop.variant === 'outline') {
            return {
              ...base,
              borderRadius: 1,
              '&:hover': {
                backgroundColor: `${theme.colors.blue100} !important`,
              },
            };
          }

          return {
            ...base,
            borderRadius: 0,
            paddingLeft: 24,
            paddingRight: 24,
          };
        },
      }}
    />
  );
};
