import { createReducer } from '@hhs/ui/old-abyss/ui/redux/helpers/createReducer';
import { POST_VALIDATE_ADDITIONAL_PAYMENT } from './constants';

const initialState = {
  additionalPaymentInfo: {},
};

export const additionalPaymentReducer = createReducer(initialState, {
  [`${POST_VALIDATE_ADDITIONAL_PAYMENT}_SUCCESS`]: (state, action) => {
    return {
      ...state,
      additionalPaymentInfo: action.payload,
    };
  },
});
