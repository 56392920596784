"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.baseStyles = void 0;

var baseStyles = function baseStyles(theme) {
  return {
    calendarWrapper: {
      position: 'absolute',
      zIndex: 1000
    },
    calendarIcon: {
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      minWidth: 18,
      height: 18,
      borderWidth: '5px 2px 2px',
      borderStyle: 'solid',
      borderRadius: 2,
      margin: 3,
      cursor: 'pointer',
      color: '#005eaa !important',
      top: 6,
      '&:hover': {
        color: theme.colors.secondary
      },
      '&:before': {
        content: '""',
        position: 'absolute',
        width: 2,
        height: 4,
        boxShadow: 'inset 0 0 0 18px, 10px 0',
        top: -7,
        left: 1
      },
      '&:after': {
        content: '""',
        position: 'absolute',
        width: 3,
        height: 3,
        top: 2,
        left: 1,
        boxShadow: 'inset 0 0 0 18px, 4px 0, 8px 0, 0 4px, 4px 4px'
      }
    },
    calendarIconArrowDown: {
      position: 'absolute',
      bottom: -11,
      width: 0,
      height: 0,
      borderLeft: '12px solid transparent',
      borderRight: '12px solid transparent',
      borderTop: '12px solid #a0a096',
      '&:after': {
        content: '""',
        position: 'absolute',
        left: -12,
        bottom: 1,
        width: 0,
        height: 0,
        borderLeft: '12px solid transparent',
        borderRight: '12px solid transparent',
        borderTop: '12px solid #fff'
      }
    },
    calendarIconArrowUp: {
      position: 'absolute',
      top: -11,
      width: 0,
      height: 0,
      borderLeft: '12px solid transparent',
      borderRight: '12px solid transparent',
      borderBottom: '12px solid #a0a096',
      '&:after': {
        content: '""',
        position: 'absolute',
        left: -12,
        top: 1,
        width: 0,
        height: 0,
        borderLeft: '12px solid transparent',
        borderRight: '12px solid transparent',
        borderBottom: '12px solid #fff'
      }
    }
  };
};

exports.baseStyles = baseStyles;