"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.config = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var getNodeEnv = function getNodeEnv() {
  if (typeof process !== 'undefined') {
    if (process.env.NODE_ENV) {
      return process.env.NODE_ENV.toLowerCase();
    }
  }

  return '';
};

var getAppEnv = function getAppEnv() {
  if (typeof process !== 'undefined') {
    if (process.env.APP_ENV) {
      return process.env.APP_ENV.toLowerCase();
    }
  }

  if (typeof window !== 'undefined') {
    if (window.__ABYSS_APP_ENV__) {
      return window.__ABYSS_APP_ENV__.toLowerCase();
    }
  }

  if (typeof document !== 'undefined') {
    var metaEnv = document.querySelector('meta[environment]');

    if (metaEnv) {
      return metaEnv.getAttribute('environment').toLowerCase();
    }
  }

  return '';
};

var configuration = {};

var getTokenizedConfig = function getTokenizedConfig(configToReplace) {
  return Object.keys(configToReplace).reduce(function (obj, key) {
    var value = configToReplace[key];

    if (typeof value === 'string' && /{{[a-zA-Z0-9_]+}}/.test(value)) {
      var match = value.match(/{{([a-zA-Z0-9_]+)}}/)[1];
      value = value.replace("{{".concat(match, "}}"), configuration[match] || '');
    }

    return _objectSpread(_objectSpread({}, obj), {}, (0, _defineProperty2["default"])({}, key, value));
  }, {});
};

var config = function config(value, setValue) {
  if (!value) {
    return configuration;
  }

  if (typeof value === 'string' && typeof setValue === 'undefined') {
    if (value === 'APP_ENV' || value === 'NODE_ENV') {
      return {
        NODE_ENV: getNodeEnv(),
        APP_ENV: getAppEnv()
      }[value];
    }

    return configuration[value];
  }

  var newValue = value;

  if (typeof setValue !== 'undefined') {
    newValue = (0, _defineProperty2["default"])({}, value, setValue);
  }

  if (typeof process !== 'undefined') {
    if (process.env.ABYSS_LOCAL_CONFIG) {
      var localConfig = JSON.parse(process.env.ABYSS_LOCAL_CONFIG);
      Object.assign(newValue, localConfig);
    }

    Object.keys(newValue).forEach(function (key) {
      process.env[key] = newValue[key];
    });
  }

  var tokenizedConfig = getTokenizedConfig(newValue);
  return Object.assign(configuration, tokenizedConfig, {
    NODE_ENV: getNodeEnv(),
    APP_ENV: getAppEnv()
  });
};

exports.config = config;