"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "useTheme", {
  enumerable: true,
  get: function get() {
    return _reactJss.useTheme;
  }
});

var _reactJss = require("react-jss");