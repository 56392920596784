"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.customStyles = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var customStyles = function customStyles(styles, theme, props) {
  var _theme$colors = theme.colors,
      primaryColor = _theme$colors.primary,
      errorColor = _theme$colors.error,
      disabledColor = _theme$colors.gray500,
      placeHolder = _theme$colors.gray600,
      textColor = _theme$colors.gray800,
      whiteColor = _theme$colors.white;
  var isValid = props.isValid,
      isKeyboard = props.isKeyboard;
  var mainColor = isValid === false ? errorColor : primaryColor;
  var baseSelectStyles = {
    control: function control(provided, state) {
      return _objectSpread(_objectSpread({}, provided), {}, {
        cursor: state.isDisabled ? 'not-allowed' : 'inherit',
        pointerEvents: state.isDisabled ? 'auto' : 'inherit',
        border: 'none',
        backgroundColor: 'inherit',
        transition: 'none',
        boxShadow: 'none',
        minHeight: 34,
        padding: '5px 0',
        flexWrap: 'nowrap'
      });
    },
    menu: function menu(provided) {
      return _objectSpread(_objectSpread({}, provided), {}, {
        marginTop: 0,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        zIndex: 500
      });
    },
    menuList: function menuList(provided) {
      return _objectSpread(_objectSpread({}, provided), {}, {
        padding: 0,
        fontSize: theme.fontSizes.size400
      });
    },
    option: function option(provided, state) {
      var isSelected = state.isSelected;
      var isFocused = isKeyboard && state.isFocused;
      return _objectSpread(_objectSpread({}, provided), {}, {
        color: isSelected || isFocused ? whiteColor : textColor,
        backgroundColor: isSelected || isFocused ? mainColor : whiteColor,
        fontWeight: isSelected || isFocused ? 700 : 'normal',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        '&:hover': {
          color: whiteColor,
          backgroundColor: primaryColor,
          fontWeight: 700
        }
      });
    },
    valueContainer: function valueContainer(provided) {
      return _objectSpread(_objectSpread({}, provided), {}, {
        fontSize: theme.fontSizes.size500,
        padding: 0,
        paddingLeft: 10,
        paddingRight: 10,
        position: 'static',
        flex: '1 1 auto',
        flexWrap: 'nowrap',
        input: !props.isSearchable && {
          textIndent: '-99999999em'
        }
      });
    },
    multiValueLabel: function multiValueLabel(provided) {
      return _objectSpread(_objectSpread({}, provided), {}, {
        padding: 0,
        paddingRight: 3,
        paddingLeft: 6
      });
    },
    indicatorsContainer: function indicatorsContainer(provided) {
      return _objectSpread(_objectSpread({}, provided), {}, {
        paddingLeft: 2,
        paddingRight: 8
      });
    },
    indicatorSeparator: function indicatorSeparator() {
      return {
        display: 'none'
      };
    },
    loadingIndicator: function loadingIndicator(provided) {
      return _objectSpread(_objectSpread({}, provided), {}, {
        padding: 0,
        marginRight: 6
      });
    },
    clearIndicator: function clearIndicator(provided, state) {
      var activeState;

      if (state.selectProps.menuIsOpen) {
        activeState = {
          transform: 'scaleY(-1)',
          color: mainColor
        };
      }

      return _objectSpread(_objectSpread(_objectSpread({}, provided), {}, {
        padding: 0,
        cursor: state.isDisabled ? 'inherit' : 'pointer',
        color: state.isDisabled ? disabledColor : textColor
      }, activeState), {}, {
        '&:hover': {
          color: state.isDisabled ? disabledColor : mainColor
        }
      });
    },
    dropdownIndicator: function dropdownIndicator(provided, state) {
      var activeState;

      if (state.selectProps.menuIsOpen) {
        activeState = {
          transform: 'scaleY(-1)',
          color: mainColor
        };
      }

      return _objectSpread(_objectSpread(_objectSpread({}, provided), {}, {
        padding: 0,
        cursor: state.isDisabled ? 'inherit' : 'pointer',
        color: state.isDisabled ? disabledColor : textColor
      }, activeState), {}, {
        '&:hover': {
          color: state.isDisabled ? disabledColor : mainColor
        }
      });
    },
    placeholder: function placeholder(provided) {
      return _objectSpread(_objectSpread({}, provided), {}, {
        marginLeft: 0,
        fontSize: theme.fontSizes.size500,
        color: placeHolder
      });
    }
  };

  if (styles) {
    return Object.keys(styles).reduce(function (obj, key) {
      return _objectSpread(_objectSpread({}, obj), {}, (0, _defineProperty2["default"])({}, key, function (provided, state) {
        var baseProvided = baseSelectStyles[key](provided, state);
        return styles[key](baseProvided, theme, _objectSpread(_objectSpread({}, state), props));
      }));
    }, _objectSpread({}, baseSelectStyles));
  }

  return baseSelectStyles;
};

exports.customStyles = customStyles;