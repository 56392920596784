import { useAction } from '@hhs/ui/old-abyss/ui/redux/hooks/useAction';
import { useSelect } from '@hhs/ui/old-abyss/ui/redux/hooks/useSelect';
import { useSaga } from '@hhs/ui/old-abyss/ui/redux/hooks/useSaga';
import { getUserSession } from './actions';
import { GET_USER_SESSION } from './constants';

const userSelector = state => state.user;

export const useUser = () => {
  return {
    user: useSelect(userSelector),
    getUserSession: useAction(getUserSession, {
      saga: useSaga(GET_USER_SESSION),
    }),
  };
};
