import { http } from 'src/tools/http';

export const getUserSessionRequest = async () => {
  const { headers } = await http({
    id: 'user-session',
    method: 'GET',
    url: '/user',
  });

  return {
    ipAddress: headers['ip-address'],
  };
};
