"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useSaga = void 0;

var _get = _interopRequireDefault(require("lodash/get"));

var _useSelect = require("../useSelect");

var useSaga = function useSaga(actionType) {
  return (0, _useSelect.useSelect)(function (state) {
    return (0, _get["default"])(state, "sagas.".concat(actionType));
  });
};

exports.useSaga = useSaga;