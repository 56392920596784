import * as React from 'react';
import { GoogleReCaptchaConsumer } from './google-recaptcha-provider';

export const withGoogleReCaptcha = Component => {
  const WithGoogleReCaptchaComponent = props =>
    React.createElement(GoogleReCaptchaConsumer, null, googleReCaptchaValues =>
      React.createElement(
        Component,
        Object.assign({}, props, {
          googleReCaptchaProps: googleReCaptchaValues,
        })
      )
    );
  WithGoogleReCaptchaComponent.displayName = `withGoogleReCaptcha(${Component.displayName ||
    Component.name ||
    'Component'})`;
  Object.assign(WithGoogleReCaptchaComponent, Component);
  return WithGoogleReCaptchaComponent;
};
