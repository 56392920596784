import React from 'react';
import { Icon } from '@abyss/web/ui/Icon';

export const IconClinic = props => {
  return (
    <Icon {...props} color='#005eaa'>
      <svg width="40px" height="40px" viewBox="0 0 30 40" focusable="false">
        <title>Clinic</title>
        <desc>A small building</desc>
        <path d="M18.822 3.052c5.292.58 10.164 2.384 10.164 6.116v20.394H0V9.168c0-3.357 4.378-5.285 9.743-6.004V5.26c-4.508.656-7.671 2.16-7.671 3.908V27.49h9.415v-7.43h5.587v7.43h9.84V9.168c0-1.928-3.286-3.476-8.092-4.034zM9.175 19.467v4.371H2.988v-4.371h6.187zm16.398 0v4.371h-6.186v-4.371h6.186zM9.175 12.84v4.372H2.988V12.84h6.187zm8.198 0v4.372h-6.185V12.84h6.185zm8.2 0v4.372h-6.186V12.84h6.186zM17.783 0c.1 0 .18.081.18.18v7.004c0 .1-.08.18-.18.18h-7.002a.183.183 0 01-.18-.18V.18c0-.097.082-.18.18-.18zm-2.488.796h-2.022V2.68H11.47v1.986h1.803V6.57h2.022V4.667h1.804V2.681h-1.804V.796z" />
      </svg>
    </Icon>
  );
};
