"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useColor = void 0;

var _reactJss = require("react-jss");

var useColor = function useColor(color) {
  if (typeof color === 'string' && color[0] === '#') {
    return color;
  }

  var theme = (0, _reactJss.useTheme)();

  if (theme && theme.colors && theme.colors[color]) {
    return theme.colors[color];
  }

  return color;
};

exports.useColor = useColor;