"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.enableDevTools = void 0;

var _config = require("../../tools/config");

var enableDevTools = function enableDevTools() {
  var ABYSS_DEVTOOLS = (0, _config.config)('ABYSS_DEVTOOLS');
  var IS_LOCAL = (0, _config.config)('APP_ENV') === 'local';
  var IS_DEV = (0, _config.config)('NODE_ENV') === 'development';

  if (ABYSS_DEVTOOLS === false) {
    return false;
  }

  return ABYSS_DEVTOOLS || IS_LOCAL || IS_DEV;
};

exports.enableDevTools = enableDevTools;