"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useSize = void 0;

var _reactJss = require("react-jss");

var useSize = function useSize(size) {
  if (/px$/.test(size)) {
    return size;
  }

  if (typeof size === 'number') {
    return "".concat(size, "px");
  }

  var theme = (0, _reactJss.useTheme)();

  if (theme && theme.fontSizes && theme.fontSizes[size]) {
    return theme.fontSizes[size];
  }

  return size;
};

exports.useSize = useSize;