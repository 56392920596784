"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createRouterMiddleware = void 0;

var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));

var _actions = require("../actions");

var createRouterMiddleware = function createRouterMiddleware(history) {
  return function () {
    return function (next) {
      return function (action) {
        if (action.type === _actions.LOCATION_CHANGE) {
          return next(action);
        }

        var _action$payload = action.payload,
            method = _action$payload.method,
            args = _action$payload.args;
        history[method].apply(history, (0, _toConsumableArray2["default"])(args));
        return undefined;
      };
    };
  };
};

exports.createRouterMiddleware = createRouterMiddleware;