"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.routerReducer = void 0;

var _actions = require("./actions");

var _injectQuery = require("./helpers/injectQuery");

var routerReducer = function routerReducer(history) {
  var initialState = {
    current: {
      location: (0, _injectQuery.injectQuery)(history.location),
      action: history.action
    },
    previous: {}
  };
  return function () {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
    var action = arguments.length > 1 ? arguments[1] : undefined;
    var type = action.type,
        payload = action.payload;

    if (type === _actions.LOCATION_CHANGE) {
      return {
        current: {
          location: (0, _injectQuery.injectQuery)(payload.location),
          action: payload.action
        },
        previous: state.current
      };
    }

    return state;
  };
};

exports.routerReducer = routerReducer;