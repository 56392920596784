const stringMap = {
  unitedhealthcare: 'UnitedHealthcare',
  optumrx: 'OptumRx',
  md: 'MD',
  pc: 'PC',
  llc: 'LLC',
  wi: 'WI',
  ri: 'RI',
  aarp: 'AARP',
  hmo: 'HMO',
  pos: 'POS',
  hmopos: 'HMOPOS',
  hra: 'HRA',
  po: 'P.O.',
  'p.o.': 'P.O.',
  'n/a': 'N/A',
};

export const formatCapitalCase = str => {
  return (str || '').replace(/\w[^\s-()]*/g, txt => {
    const stringKey = txt.toLowerCase();
    if (stringMap[stringKey]) {
      return stringMap[stringKey];
    }
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};
