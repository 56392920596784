import makeAsyncScriptLoader from 'react-async-script';
import { ReCAPTCHA } from './recaptcha';

const callbackName = 'onloadcallback';
const globalName = 'grecaptcha';

function getOptions() {
  return (typeof window !== 'undefined' && window.recaptchaOptions) || {};
}

function getURL() {
  const dynamicOptions = getOptions();
  const hostname = dynamicOptions.useRecaptchaNet
    ? 'recaptcha.net'
    : 'www.google.com';
  return `https://${hostname}/recaptcha/enterprise.js?onload=${callbackName}&render=explicit`;
}

export const ReCAPTCHAWrapper = makeAsyncScriptLoader(getURL, {
  callbackName,
  globalName,
})(ReCAPTCHA);
