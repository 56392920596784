import React from 'react';
import PropTypes from 'prop-types';
import { useGoogleReCaptcha } from '../../GoogleRecaptcha';

export const ReCaptchaButton = ({ children, count, onClick, label }) => {
  const { executeRecaptcha } = useGoogleReCaptcha();

  return React.Children.map(children, child => {
    if (!child) {
      return child;
    }

    const handleClick = async event => {
      const clickEvent = event;

      if (onClick) {
        onClick(clickEvent);
      }

      if (child.props.onClick) {
        const tokensCount = [...Array(count || 1).keys()];
        let tokens = [];

        try {
          tokens = await Promise.all(
            tokensCount.map(() => executeRecaptcha(label))
          );
        } catch (e) {
          tokens = tokensCount;
        }

        child.props.onClick(clickEvent, count ? tokens : tokens[0]);
      }
    };

    return React.cloneElement(child, { onClick: handleClick });
  });
};

ReCaptchaButton.propTypes = {
  children: PropTypes.node,
  count: PropTypes.number,
  onClick: PropTypes.func,
  label: PropTypes.string,
};

ReCaptchaButton.defaultProps = {
  children: null,
  count: null,
  onClick: null,
  label: null,
};
