"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createPalette = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _color = require("../../tools/color");

var createPalette = function createPalette(name, base) {
  var _ref;

  var baseColor = (0, _color.color)(base);
  return _ref = {}, (0, _defineProperty2["default"])(_ref, "".concat(name, "50"), baseColor.lighten(1.1).hex()), (0, _defineProperty2["default"])(_ref, "".concat(name, "100"), baseColor.lighten(1.0).hex()), (0, _defineProperty2["default"])(_ref, "".concat(name, "150"), baseColor.lighten(0.9).hex()), (0, _defineProperty2["default"])(_ref, "".concat(name, "200"), baseColor.lighten(0.8).hex()), (0, _defineProperty2["default"])(_ref, "".concat(name, "250"), baseColor.lighten(0.7).hex()), (0, _defineProperty2["default"])(_ref, "".concat(name, "300"), baseColor.lighten(0.6).hex()), (0, _defineProperty2["default"])(_ref, "".concat(name, "350"), baseColor.lighten(0.5).hex()), (0, _defineProperty2["default"])(_ref, "".concat(name, "400"), baseColor.lighten(0.4).hex()), (0, _defineProperty2["default"])(_ref, "".concat(name, "450"), baseColor.lighten(0.3).hex()), (0, _defineProperty2["default"])(_ref, "".concat(name, "500"), baseColor.lighten(0.2).hex()), (0, _defineProperty2["default"])(_ref, "".concat(name, "550"), baseColor.hex()), (0, _defineProperty2["default"])(_ref, "".concat(name, "600"), baseColor.darken(0.2).hex()), (0, _defineProperty2["default"])(_ref, "".concat(name, "650"), baseColor.darken(0.3).hex()), (0, _defineProperty2["default"])(_ref, "".concat(name, "700"), baseColor.darken(0.4).hex()), (0, _defineProperty2["default"])(_ref, "".concat(name, "750"), baseColor.darken(0.5).hex()), (0, _defineProperty2["default"])(_ref, "".concat(name, "800"), baseColor.darken(0.6).hex()), (0, _defineProperty2["default"])(_ref, "".concat(name, "850"), baseColor.darken(0.7).hex()), (0, _defineProperty2["default"])(_ref, "".concat(name, "900"), baseColor.darken(0.8).hex()), (0, _defineProperty2["default"])(_ref, "".concat(name, "950"), baseColor.darken(0.9).hex()), (0, _defineProperty2["default"])(_ref, "".concat(name, "1000"), baseColor.darken(1.0).hex()), _ref;
};

exports.createPalette = createPalette;