export const styles = theme => {
  return {
    headerIcon: {
      marginRight: 20,
    },
    horizontalLine: {
      borderBottom: '1px solid #D8D8D8',
      marginLeft: 12,
      marginBottom: 20,
      width: '68%',
    },
    subHeading: {
      marginTop: 10,
      marginBottom: 25,
      lineHeight: '18px',
      marginLeft: 52,
    },
    continueButton: {
      borderRadius: 3,
      padding: '0px 24px',
    },
    tinTextbox: {
      width: 300,
    },
    instructionsText: {
      marginTop: 10,
      marginBottom: 30,
      color: '#666666',
      fontSize: 12,
      lineHeight: '12px',
    },
    alertMessage: {
      marginTop: 20,
      marginBottom: 30,
    },
    formControl: {
      marginLeft: 52,
    }
  };
};
