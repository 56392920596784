"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.baseStyles = void 0;

var baseStyles = function baseStyles() {
  return {
    button: {},
    box: function box(_ref) {
      var isVisible = _ref.isVisible,
          durationMS = _ref.durationMS,
          width = _ref.width;
      return {
        position: 'relative',
        color: '#26323d',
        boxShadow: "0 0 20px 4px rgba(154, 161, 177, 0.15),\n          0 4px 80px -8px rgba(36, 40, 47, 0.25),\n          0 4px 4px -2px rgba(91, 94, 105, 0.15)",
        backgroundColor: '#fff',
        borderRadius: 4,
        fontSize: 14,
        lineHeight: 1.4,
        outline: 0,
        maxWidth: width,
        opacity: isVisible ? 1 : 0,
        transitionProperty: 'opacity',
        transitionTimingFunction: 'cubic-bezier(0.54, 1.5, 0.38, 1.11)',
        transitionDuration: durationMS
      };
    },
    arrow: {
      width: 16,
      height: 16,
      color: '#333',
      '&:before': {
        content: '""',
        position: 'absolute',
        borderColor: 'transparent',
        borderStyle: 'solid'
      },
      '$box[data-placement^="top"] &': {
        bottom: 0,
        '&:before': {
          bottom: -7,
          left: 0,
          borderWidth: '8px 8px 0',
          borderTopColor: '#fff',
          transformOrigin: 'center top'
        }
      },
      '$box[data-placement^="bottom"] &': {
        top: 0,
        '&:before': {
          top: -7,
          left: 0,
          borderWidth: '0 8px 8px',
          borderBottomColor: '#fff',
          transformOrigin: 'center bottom'
        }
      },
      '$box[data-placement^="left"] &': {
        right: 0,
        '&:before': {
          right: -7,
          borderWidth: '8px 0 8px 8px',
          borderLeftColor: '#fff',
          transformOrigin: 'center left'
        }
      },
      '$box[data-placement^="right"] &': {
        left: 0,
        '&:before': {
          left: -7,
          borderWidth: '8px 8px 8px 0',
          borderRightColor: '#fff',
          transformOrigin: 'center right'
        }
      }
    },
    content: {
      position: 'relative',
      padding: '5px 9px',
      zIndex: 1
    }
  };
};

exports.baseStyles = baseStyles;