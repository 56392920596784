export const baseStyles = theme => {
  const primaryColor = '#005eaa !important';

  return {
    pageHeader: {
      paddingTop: 35,
      paddingBottom: 40,
      backgroundColor: 'white',
      borderBottomWidth: 1,
      borderStyle: 'solid',
      borderColor: "#BFBFBF",
      marginBottom: 40,
      position: 'relative',
      fontSize: 16,
      lineHeight: '18px',
    },
    backButton: {
      position: 'absolute',
      marginTop: 5,
      marginLeft: 40,
      top: 38,
      width: 34,
      height: 34,
      borderRadius: '50%',
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: primaryColor,
      background: 'white',

      '&:hover': {
        cursor: 'pointer',
      },

      '&:before, &:after': {
        position: 'absolute',
        content: '""',
        backgroundColor: primaryColor,
        left: 15,
        width: 1,
        height: 12,
      },

      '&:before': {
        transform: 'rotate(45deg)',
        top: 5,
      },

      '&:after': {
        transform: 'rotate(-45deg)',
        top: 13,
      },
    },
  };
};
