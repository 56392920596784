import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { ReCAPTCHAWrapper } from '../GoogleRecaptchaCheckBox';

export const ReCaptchaCheckbox = ({ onClick, sitekey, ...props }) => {
  useEffect(() => {
    if (!sitekey) {
      onClick(true);
    }
  }, []);

  return !sitekey ? null : (
    <ReCAPTCHAWrapper {...props} sitekey={sitekey} onChange={onClick} />
  );
};

ReCaptchaCheckbox.propTypes = {
  onClick: PropTypes.func,
  sitekey: PropTypes.string,
};

ReCaptchaCheckbox.defaultProps = {
  onClick: () => null,
  sitekey: null,
};
