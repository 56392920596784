"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "Alert", {
  enumerable: true,
  get: function get() {
    return _Alert.Alert;
  }
});

var _Alert = require("./Alert");

var _AlertIcon = require("./AlertIcon");

var _AlertTitle = require("./AlertTitle");

_Alert.Alert.Icon = _AlertIcon.AlertIcon;
_Alert.Alert.Title = _AlertTitle.AlertTitle;