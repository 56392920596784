import React from 'react';
import PropTypes from 'prop-types';
import { useStyles } from '@hhs/ui/old-abyss/ui/styles/hooks/useStyles';
import { classNames } from '@abyss/web/tools/classNames';

import { baseStyles } from './PageLayout.styles';

export const PageLayout = ({ className, styles, isWide, ...props }) => {
  const classes = useStyles(baseStyles, { isWide }, styles);
  return <div {...props} className={classNames(classes.layout, className)} />;
};

PageLayout.propTypes = {
  className: PropTypes.string,
  styles: PropTypes.shape({}),
  isWide: PropTypes.bool,
};

PageLayout.defaultProps = {
  className: null,
  styles: null,
  isWide: false,
};
