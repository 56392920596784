"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "useMask", {
  enumerable: true,
  get: function get() {
    return _useMask.useMask;
  }
});

var _useMask = require("./useMask");