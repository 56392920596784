"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.baseStyles = void 0;

var baseStyles = function baseStyles() {
  return {
    icon: {
      display: 'inline-block',
      height: function height(_ref) {
        var size = _ref.size;
        return size;
      },
      width: function width(_ref2) {
        var size = _ref2.size;
        return size;
      }
    }
  };
};

exports.baseStyles = baseStyles;