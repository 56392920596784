import { formatter as abyssFormatter } from '@abyss/web/tools/formatter';
import { formatCapitalCase } from './types/formatCapitalCase';
import { formatName } from './types/formatName';
import { formatGender } from './types/formatGender';
import { formatLowerCase } from './types/formatLowerCase';
import { formatString } from './types/formatString';
import { formatSubscriber } from './types/formatSubscriber';
import { formatTrimZeroes } from './types/formatTrimZeroes';
import { formatCityStateZip } from './types/formatCityStateZip';
import { formatBlank } from './types/formatBlank';
import { formatMpin } from './types/formatMpin';
import { formatMoney } from './types/formatMoney';

const formatters = {
  capitalCase: formatCapitalCase,
  name: formatName,
  gender: formatGender,
  lowerCase: formatLowerCase,
  string: formatString,
  subscriber: formatSubscriber,
  trimZeroes: formatTrimZeroes,
  cityStateZip: formatCityStateZip,
  blank: formatBlank,
  mpin: formatMpin,
  money: formatMoney,
};

export const formatter = (type, ...args) => {
  const formatterType = formatters[type];
  if (formatterType) {
    return formatterType(...args);
  }
  return abyssFormatter(type, ...args);
};
