import React from 'react';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { useStyles } from '@hhs/ui/old-abyss/ui/styles/hooks/useStyles';
import { styles } from './AccessibilityStatement.styles';
import { PageHeader } from '../../PageHeader';
import { PageLayout } from '../../PageLayout';

export const AccessibilityStatement = () => {
  const classes = useStyles(styles);
  const router = useRouter();
  const location = router.getLocation();  

  const handleGoBack = () => {
    const url = window.location.pathname.split('/');
    if ((url[1] === 'attestation' || url[1] === 'registration' || url[1] === 'docusign') &&
      (url[2] === 'contact-us' || url[2] === 'accessibility-statement' || url[2] === 'terms-of-use' || url[2] === 'privacy-policy')) {
      router.navigate('/');
      return;
    }
    if (window.location.pathname.split('/').length > 2) {  
      router.navigate(-1);  
    } else {  
      //router.navigate(-1);  
      router.navigate('/');
    }    
  };

  return (
    <React.Fragment>
      <PageHeader onBackClick={handleGoBack}>
        <h1 className={classes.pageHeader}>
          Accessibility Statement for Individuals with Disabilities
        </h1>
      </PageHeader>
      <PageLayout>
        <div className={classes.section}>
          <p>
            We are committed to ensuring that our website is accessible to
            individuals with disabilities. If you need assistance using our
            website, we can help you. Please call us toll-free at
            1-844-386-7491. TTY users dial 711.
          </p>
          <p>
            For password help, or other general assistance with the website,
            please call 1-866-569-3522. TTY users dial 711.
          </p>
        </div>
      </PageLayout>
    </React.Fragment>
  );
};
