"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "ErrorBoundary", {
  enumerable: true,
  get: function get() {
    return _ErrorBoundary.ErrorBoundary;
  }
});

var _ErrorBoundary = require("./ErrorBoundary");

var _ErrorMessage = require("./ErrorMessage");

_ErrorBoundary.ErrorBoundary.Message = _ErrorMessage.ErrorMessage;