"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.baseStyles = exports.createStyles = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _typeof2 = _interopRequireDefault(require("@babel/runtime/helpers/typeof"));

var _reactJss = require("react-jss");

var _merge = _interopRequireDefault(require("lodash/merge"));

var _theme = require("../theme");

var _reset = require("./reset");

var _normalize = require("./normalize");

var _base = require("./base");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var createStyles = function createStyles(styles) {
  if ((0, _typeof2["default"])(styles) === 'object') {
    var globalFonts = [];
    var globalStyles = {};

    if (styles['@global'] || styles['@font-face']) {
      globalFonts = globalFonts.concat(styles['@font-face'] || []);
      globalStyles = _objectSpread(_objectSpread({}, globalStyles), styles['@global']);
    } else {
      Object.keys(styles).forEach(function (key) {
        globalFonts = globalFonts.concat(styles[key]['@font-face'] || []);
        globalStyles = _objectSpread(_objectSpread({}, globalStyles), styles[key]['@global']);
      });
    }

    _reactJss.jss.createStyleSheet({
      '@font-face': globalFonts,
      '@global': globalStyles
    }).attach();
  }
};

exports.createStyles = createStyles;

var baseStyles = function baseStyles(theme) {
  return {
    '@global': (0, _merge["default"])(_reset.reset, _normalize.normalize, (0, _base.base)(theme || (0, _theme.createTheme)()))
  };
};

exports.baseStyles = baseStyles;