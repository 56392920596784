import React, { useEffect } from 'react';
import { useUser } from 'src/state/user';
import { Header } from './Header';
import { Content } from './Content';
import { Footer } from './Footer';
import { honeypot } from 'src/tools/honeypot';
import { registerAnalytics } from 'src/tools/analytics';
import { attachStylesheet } from '../../styles/stylesheet';
export const App = () => {
  registerAnalytics();
  honeypot();
  attachStylesheet();
  
  const { getUserSession } = useUser();

  useEffect(() => {
    getUserSession();
  }, []);
  
  return (
    <React.Fragment>
      <Header />
      <Content />
      <Footer />
    </React.Fragment>
  );
};
