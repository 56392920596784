import React, { useState, useEffect } from 'react';
import { config } from '@abyss/web/tools/config';
import { useStyles } from '@hhs/ui/old-abyss/ui/styles/hooks/useStyles';
import { event } from '@abyss/web/tools/event';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { Flex } from '@abyss/web/ui/Flex';
import { ReCaptcha } from '@hhs/ui/src/utility/ReCaptcha';
import { FormControl } from '@hhs/ui/old-abyss/ui/form/FormControl';
import { ErrorMessage } from '@hhs/ui/old-abyss/ui/form/ErrorMessage';
import { useForm } from '@hhs/ui/old-abyss/ui/form/hooks/useForm';
import { useFormState } from '@hhs/ui/old-abyss/ui/form/hooks/useFormState';
import { PageHeader } from 'src/app/App/PageHeader';
import { PageLayout } from 'src/app/App/PageLayout';
import { IconClinic } from '@hhs/ui/src/base/Icon/Provider/IconClinic';
import { TextArea } from '@hhs/ui/old-abyss/ui/form/TextArea';
import { useAttestation } from 'src/state/attestation';
import { Button } from '@abyss/web/ui/Button';
import { Alert } from '@hhs/ui/old-abyss/ui/base/Alert';
import { useSteps } from 'src/state/steps';

import { styles } from './TaxIdNumbers.styles';

const RECAPTCHA_KEY = config('RECAPTCHA_KEY_EP');

export const TaxIdNumbers = () => {
  const classes = useStyles(styles);
  const router = useRouter();
  const form = useForm();
  const formState = useFormState('taxIdNumbers');
  const { validateTins } = useAttestation();
  const { setStep } = useSteps();
  const [invalidTin, setInvalidTin] = useState();
  const [error, setError] = useState();
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setStep(2);
    event('BILLING_TINS_PAGE_VIEW');
  }, []);

  const handleReCaptchaClick = () => {
    setLoading(true);
  };

  const handleContinue = (e, token) => {
    setError();
    form.submit('taxIdNumbers.tins', data => {
      const billingTINs = data.replace(/\s/g, '');
      event('BILLING_TINS_CONTINUE', { billingTINs });

      const payload = {
        token,
        tins: billingTINs.split(','),
      };

      validateTins(
        payload,
        response => {
          setLoading(false);
          const newTins = Object.values(response)
            .filter(item => item.valid)
            .map(item => item.tin);
          form.set('taxIdNumbers.tins', [...new Set(newTins)].join(',\n'));
          form.reset('medicareVerification');
          router.navigate('/step/3');
        },
        respError => {
          setLoading(false);
          event('VALIDATE_TINS_ERROR', {
            errorType: 'Error',
            errorMessage: respError.message,
            errorCode: respError.code,
            payload,
          });
          setError(respError);
        }
      );
    });
  };

  const handleChangeTINs = value => {
    let newValue = (value || '').replace(/[^\d,\n]+/g, '');      // RDR codeql fix

    newValue = newValue.replace(/\d+\n$/g, txt => {
      // return txt.replace('\n', ',\n');
      return txt.replace(/\n/g, ',\n');      
    });

    newValue = newValue.replace(/\d+,\d$/g, txt => {
      // return txt.replace(',', ',\n');
      return txt.replace(/,/g, ',\n');      
    });

    return newValue;
  };

  const handleFormatTINs = value => {
    const trimValue = (value || '').replace(/\s/g, '');

    let split;
    if (/^\d+(,[^]*)/.test(trimValue)) {
      split = ',';
    }

    let formattedTINs = trimValue;
    if (split) {
      formattedTINs = trimValue
        .split(split)
        .filter(tin => tin)
        .join(',\n');
    }
    form.set('taxIdNumbers.tins', formattedTINs, { focused: false });
  };

  const handleBlurTINs = e => {
    handleFormatTINs(e.target.value);
  };

  const handlePasteTINs = e => {
    const { selectionStart, selectionEnd, value } = e.target;
    const clipboard = e.clipboardData || window.clipboardData;
    let copyValue = clipboard.getData && clipboard.getData('Text');
    const text = value;

    const newString =
      text.slice(0, selectionStart) + copyValue + text.slice(selectionEnd);
    copyValue = newString;

    setTimeout(() => {
      handleFormatTINs(copyValue);
    });
  };

  const splitTins = tinString => {
    return tinString.replace(/\s/g, '').split(',');
  };

  return (
    <React.Fragment>
      <PageHeader>
        <PageHeader.Title>Step 2 Billing TIN(s)</PageHeader.Title>
      </PageHeader>
      <PageLayout>
        <Flex>
          <Flex.Content>
            <Flex direction='row' alignContent='stretch' className={classes.section}>
              <IconClinic className={classes.headerIcon} size={36} />
                <h2 tabIndex="-1">Billing Tax ID Number(s)</h2>
                <div className={classes.horizontalLine} />
            </Flex>
            <div className={classes.subHeading} id="billing-tin-desc">
              Please enter the Taxpayer Identification Number (TIN) (either
              Employer Identification Number or Social Security Number)
              connected to the billing entity. All billing entity TINs must be
              owned, and all changes of ownership approved by the Centers for
              Medicare & Medicaid Services, on the date the application is
              initiated. You may enter up to 20 TINs as long as they are
              attached to the same billing entity. TINs must have all 9 digits
              entered to be accepted.
            </div>
            <FormControl
              id="tax-id-numbers-tins"
              model="taxIdNumbers.tins"
              label="Billing TIN(s)"
              className={classes.formControl}
            >
              {(inputProps, errorProps) => (
                <div className={classes.tinTextbox}>
                  <TextArea
                    {...inputProps}
                    aria-describedby="billing-tin-desc"
                    aria-required="true"
                    rows={8}
                    maxLength={237}
                    formatValue={handleChangeTINs}
                    placeholder={`Example:\n123456789,\n987654321`}
                    onPaste={handlePasteTINs}
                    onBlur={handleBlurTINs}
                    validators={{
                      required: value => !value,
                      validTin: value => {
                        let isInvalid = false;
                        splitTins(value).forEach(tin => {
                          const originalTin = ` ${tin}`.slice(1);
                          if (!originalTin.match('^[\\d|-]*$')) {
                            isInvalid = true;
                            setInvalidTin(originalTin);
                          }
                          const plainTin = tin.replace(/\D/g, '');
                          if (plainTin.length !== 0) {
                            if (
                              !tin.match(
                                '^\\d{9}$|^\\d{3}-\\d{2}-\\d{4}$|^\\d{2}-\\d{7}$'
                              )
                            ) {
                              isInvalid = true;
                              setInvalidTin(originalTin);
                            }
                            if (
                              plainTin.length !== 9 &&
                              plainTin.length !== 0
                            ) {
                              isInvalid = true;
                              setInvalidTin(originalTin);
                            }
                          }
                        });
                        return isInvalid;
                      },
                      maxTins: value =>
                        splitTins(value).length > 20 ||
                        value.replace(/\s/g, '').length > 220,
                      duplicate: value => {
                        let isDuplicate = false;
                        const allTins = splitTins(value);
                        allTins.forEach((tin, index) => {
                          const tmpTins = [...allTins];
                          tmpTins.splice(index, 1);
                          if (tmpTins.includes(tin)) {
                            isDuplicate = true;
                            setInvalidTin(tin);
                          }
                        });
                        return isDuplicate;
                      },
                    }}
                  />
                  <ErrorMessage
                    {...errorProps}
                    messages={{
                      required:
                        'TINs must be entered with all 9 digits and multiple TINs must be separated by commas. Only 20 TINs allowed per submission.',
                      validTin: `${invalidTin} is an invalid TIN.`,
                      maxTins:
                        'TINs must be entered with all 9 digits and multiple TINs must be separated by commas. Only 20 TINs allowed per submission.',
                      duplicate: `${invalidTin} is a duplicate TIN.`,
                    }}
                  />
                  <If
                    condition={formState.valid || !formState.fields.tins.value}
                  >
                    <Then>
                      <div className={classes.instructionsText}>
                        Type, or copy/paste TIN(s) here. Multiple TINs should be
                        separated by commas.
                      </div>
                    </Then>
                  </If>
                </div>
              )}
            </FormControl>
            <If condition={error}>
              <Then>
                <Alert errorCode={error?.code} className={classes.alertMessage}>
                  <Alert.Title>Error</Alert.Title>
                  {error?.message}
                </Alert>
              </Then>
            </If>
          </Flex.Content>
        </Flex>
        <ReCaptcha
          sitekey={RECAPTCHA_KEY}
          onClick={handleReCaptchaClick}
          label="validate_tins"
        >
          <Button
            className={classes.continueButton}
            onClick={handleContinue}
            isDisabled={!formState.valid}
            isLoading={isLoading}
            size={'$lg'}
          >
            Continue
          </Button>
        </ReCaptcha>
      </PageLayout>
    </React.Fragment>
  );
};
