import React from 'react';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { useStyles } from '@hhs/ui/old-abyss/ui/styles/hooks/useStyles';

import { styles } from './OptumPayEnrollmentAgreement.styles';
import { PageHeader } from '../../PageHeader';
import { PageLayout } from '../../PageLayout';

export const OptumPayEnrollmentAgreement = () => {
  const classes = useStyles(styles);
  const router = useRouter();
  const location = router.getLocation();  

  const handleGoBack = () => {
    if (location.state?.from) {  
      router.navigate(location.state.from);  
    } else {  
      router.navigate(-1);  
    }  
  };

  return (
    <React.Fragment>
      <PageHeader onBackClick={handleGoBack}>
        <PageHeader.Title>
          Optum Pay Enrollment Agreement - Terms and Conditions
        </PageHeader.Title>
      </PageHeader>
      <PageLayout>
        <div className={classes.section}>
          In addition to any agreements, conditions, covenants, certifications,
          documents, disclosures and notices you received and consented to,
          including the OptumHealth Financial Services, Inc., Internet Website
          Terms of Use (“Website Terms”), the following terms and conditions, as
          amended from time to time, shall supplement and collectively apply to
          and govern all use of OptumHealth Financial Services, Inc.’s Optum Pay
          solution, and the use of any product or service provided in connection
          therewith (collectively the “Optum Pay Services”). In these terms and
          conditions, the words “you” and “your” means the organization, entity
          or entities, and individuals identified on the enrollment you
          submitted to us or that you subsequently identify as a primary or
          other user and the words “we,” “our,” “us” refers to OptumHealth
          Financial Services, Inc., its affiliates, designees and other service
          providers (collectively, “Optum”). Your enrollment, registration, use
          or allowance of another person to use the OptumHealth Financial
          Services, Inc., Internet Website or Optum Pay Services signifies your
          acceptance and consent to be legally bound by such terms and
          conditions, including the terms and conditions of this document and
          any applicable agreement, condition, covenant, certification,
          document, disclosure and notice referenced herein or related thereto
          (collectively “Agreement”).
        </div>

        <div className={classes.section}>
          <b>ACH Payments.</b> This Agreement is subject to Article 4A of the
          Uniform Commercial Code (“UCC”). You hereby authorize us, acting
          directly or indirectly on behalf of or through, any third party
          administrator, health care coalition, health plan carrier, or other
          third party carrier or payer (each a “Third Party Payer”), or any
          individual consumer or other person (an “Individual Payer” and
          collectively “Payers”), to credit or debit the account(s) listed on
          your enrollment (the “Account” or “Accounts”), in connection with
          processing transactions between you and any Payers. You agree that we
          may rely upon all Account information and identifying numbers provided
          by you to receive Optum Pay Services. We may rely on the routing and
          account numbers you provided even if they identify a financial
          institution, person or account other than the one named on your
          enrollment. You agree to comply with all applicable federal and state
          laws, regulations, rules and guidelines related to electronic funds
          transfers, including without limitation, Article 4A of the UCC and the
          operating rules and regulations of the National Automated
          Clearinghouse Association. These rules provide, among other things,
          that payments made to you, are provisional until final settlement is
          made through a Federal Reserve Bank or payment is otherwise made as
          provided in Article 4A-403(a) of the UCC. If we do not receive good
          funds in connection with any payment made to you, we are entitled to a
          refund from you in the amount credited to your Account and the Payer
          that originated or instructed such payment will not be considered to
          have paid the amount so credited. We are not required to give you any
          notice of debits or credits to your Accounts. We may make adjustments
          to your Accounts whenever a correction or change is required. For
          example, if we make an error with respect to your Account, you agree
          that we may correct such error immediately and without notice to you.
          Such errors may include, but are not limited to, reversing an improper
          credit to your Account, making adjustments for returned items, and
          correcting calculation and input errors. Our right to make adjustments
          shall include the right to offset amounts you owe us or a Payer
          against future amounts payable to you and shall not be subject to any
          limitations or time constraints, except as required by law.
        </div>

        <div className={classes.section}>
          <b>Bank Accounts.</b> You represent and warrant that (i) you are the
          owner of each of the Accounts and (ii) none of the Accounts is or will
          be used primarily for personal, family or household purposes.
        </div>

        <div className={classes.section}>
          <b>Disclosures of Information to others.</b> We may disclose
          information to Payers and third parties about you and your Account(s)
          and transactions as follows: (i) pursuant to agreements with Payers
          and other third parties that utilize Optum Pay Services or assist us
          in the provision of Optum Pay Services; (ii) to verify the existence
          and condition of an Account; (iii) as otherwise necessary for us to
          provide Optum Pay Services, including but not limited to, accurately
          effectuating payments; and (iv) as instructed by you.
        </div>

        <div className={classes.section}>
          <b>Virtual Payment Card; processing fees.</b> If you have elected to
          receive payments by prepaid virtual payment (“Virtual Card”), you
          agree: (i) that such election constitutes your consent for any Payers,
          including but not limited to, Third Party Payers and Individual
          Payers, to make payments to you by Virtual Card; (ii) that mailing of
          the Virtual Card constitutes payment for purposes of a Payer’s
          compliance with applicable law; (iii) to only process Virtual Card
          payments using your systems; and (iv) that use of the Virtual Card is
          subject to and you will comply with this Agreement and the Virtual
          Card terms and conditions that accompany the Virtual Card. You
          acknowledge that that by processing a Virtual Card payment, you are
          subject to the terms and conditions governing card processing between
          you and your card service processor and that you are responsible for
          any charges and related third party fees, including interchange,
          merchant discount, or other card processing fees that may be imposed
          as a result of processing a Virtual Card payment through a card
          processor.
        </div>

        <div className={classes.section}>
          <b>Security.</b> You are responsible under this Agreement for all use
          of the Optum Pay Services, including but not limited to, any
          authorization, direction, action or failure to act by your designated
          contacts or any individual or entity authorized by you to transact
          business or otherwise utilize Optum Pay Services on behalf of your
          organization (an “Authorized User”), even if you did not intend for
          such use to occur. You authorize us to provide all information to any
          Authorized User and to discuss the Optum Pay Services with them.
          Furthermore, you agree to notify each Authorized User, at the time he
          or she becomes an Authorized User, that we may receive, record,
          exchange and use information about him or her in the same manner we do
          with information about you, as described herein. You shall notify us
          immediately in writing if any Authorized User is no longer authorized
          to transact business or make changes on behalf of you or your
          organization. You agree that: (i) we may process all instructions
          related to Optum Pay Services that are or appear to be submitted by an
          Authorized User and that such instructions are effective even if not
          authorized by you; (ii) you will maintain appropriate accounting and
          auditing procedures to protect your Account(s) from misuse; and (iii)
          you will promptly review all electronic statements, notices and
          transaction information made available to you and you shall report all
          unauthorized transactions and errors to us immediately.
        </div>

        <div className={classes.section}>
          <b>Payer payment priority; disenrollment.</b> If you have elected to
          receive payments by both ACH and by Virtual Card, the default payment
          method will be ACH, unless you change your preferences using our
          Internet website. At any time, you may dis-enroll from receiving
          electronic payments: (i) from a specific Third Party Payer; or (ii)
          from one or more Individual Payers by providing us with written notice
          that includes information reasonably requested by us. Upon request, we
          will provide you with access to a form detailing the information we
          need from you to process your dis-enrollment. Dis-enrollment and
          changes to your payment method preferences will be effective thirty
          (30) days after receipt by us of such notice or request.
        </div>

        <div className={classes.section}>
          <b>Ownership.</b> Except as provided in this Agreement, we own or
          license all rights, title and interests in the Optum Pay Services and
          any information arising from or in connection therewith. You hereby
          acknowledge that you shall not acquire any ownership rights in the
          Optum Pay Services by virtue of this Agreement.
        </div>

        <div className={classes.section}>
          <b>Warranties.</b> WE HEREBY DISCLAIM ALL WARRANTIES WITH RESPECT TO
          THE OPTUM PAY SERVICES PROVIDED HEREUNDER, WHETHER EXPRESS, IMPLIED,
          STATUTORY OR OTHERWISE, INCLUDING WITHOUT LIMITATION ANY WARRANTY OF
          MERCHANTABILITY OR FITNESS FOR USE FOR A PARTICULAR PURPOSE.
        </div>

        <div className={classes.section}>
          <b>Indemnification.</b> You agree to indemnify, defend and hold us
          harmless from and against any and all losses, liabilities, costs,
          damages and expenses, including litigation expenses and reasonable
          attorneys’ fees arising from or incurred as the result of: (i) your
          breach of this Agreement; (ii) your unauthorized or unlawful use of
          the Optum Pay Services; (iii) the unauthorized or unlawful use of the
          Optum Pay Services by an Authorized User; (iv) any inaccurate or
          incomplete data you provide or fail to provide to us; (v) your failure
          to timely update information; or (vi) the negligence or willful
          misconduct of you, your directors, officers, employees, designees,
          agents, affiliates and Authorized Users. You shall bear all risk of
          loss of records, data and materials during transit from you to us or
          to our agents or sub-contractors.
        </div>

        <div className={classes.section}>
          <b>Limitation of Liability.</b> Under no circumstances shall our
          financial responsibility for any act or failure to act by us under
          this Agreement exceed the fees or charges paid by you to us (excluding
          the portion of fees constituting pass through fees) for the
          transaction or activity that is or was the subject of the alleged
          failure of performance. In no event shall we, our parent, affiliates,
          subsidiaries, directors, officers, employees, agents or
          representatives be liable for special incidental or consequential
          damages or claims by you or any Payers or third party relative to the
          Optum Pay Services provided hereunder. We do not guarantee the payment
          or timing of payments. Payment is the responsibility of the particular
          Payer. We will not be liable if circumstances beyond our control
          prevent a payment, despite reasonable precautions we have taken. Such
          circumstances include but are not limited to, delays or losses of
          payments caused by telecommunications outages, actions of third
          parties and equipment failures. You agree that the foregoing
          limitation of liability is an agreed upon allocation of risk between
          you and us and reflects the fees, if any, we charge you to use the
          Optum Pay Services. You acknowledge that absent your agreement to this
          limitation of liability, we would not provide the Optum Pay Services
          to you.
        </div>

        <div className={classes.section}>
          <b>Electronic communications; notice.</b> You hereby consent to
          receive all communications, including statements and notifications
          related to payment electronically through our Internet website. Any
          written notice required or permitted to be given to you pursuant to
          this Agreement may be provided to you at the email address provided by
          you to us during the enrollment process. Any notice required or
          permitted to be given to us pursuant hereto shall be provided in
          writing to the following address: OptumHealth Financial Services,
          Inc., P.O. Box 30777, Salt Lake City, UT 84130-0777. Written notices
          sent by mail shall be delivered by registered or certified mail,
          return receipt requested, postage prepaid and shall be deemed
          effective seventy-two (72) hours after the same is postmarked. Notice
          sent by any other method shall be effective only upon actual receipt.
        </div>

        <div className={classes.section}>
          <b>Amendments.</b> We may add, remove, change or otherwise modify any
          term of this Agreement at any time by providing you with notice. You
          agree that amendments may be provided in electronic form and will be
          sent to your primary user’s email address. We may also modify,
          terminate or discontinue some or all of the Optum Pay Services at any
          time and will provide notice of such changes only as required by
          applicable law.
        </div>

        <div className={classes.section}>
          <b>Entire agreement.</b> This Agreement, including the Website Terms,
          which are incorporated herein as a part of this Agreement, constitutes
          the only and entire agreement between the parties hereto relating to
          the subject matter hereof and all prior negotiations, agreements and
          understandings relating to the subject matter hereof, whether oral or
          written, are superseded or canceled hereby.
        </div>

        <div className={classes.section}>
          <b>Governing law and venue.</b> The laws of the State of Minnesota
          shall govern this Agreement and all disputes arising hereunder or
          thereunder. You agree that jurisdiction and venue are proper in the
          State of Minnesota for the resolution of any dispute arising under
          this Agreement.
        </div>

        <div className={classes.section}>
          <b>Arbitration.</b> (i) As permitted by applicable law, any claim or
          controversy that is not resolved informally by the us shall, at the
          request of a party, be resolved by binding arbitration in accordance
          with the applicable commercial arbitration rules of the American
          Arbitration Association, except to the extent modified by your
          enrollment and this Agreement. In no event may arbitration be
          initiated more than one year following the date the dispute arose. Any
          arbitration proceeding under your enrollment or this Agreement shall
          be conducted in Minneapolis, Minnesota. The arbitrator may construe or
          interpret, but shall not vary or ignore the terms of this Agreement,
          and shall have no authority to award extra contractual damages of any
          kind, including punitive or exemplary damages, and shall be bound by
          controlling law. Judgment upon an arbitration award may be entered in
          any court of competent jurisdiction. The parties acknowledge that
          because this Agreement, including your enrollment and Website Terms
          affects interstate commerce, the Federal Arbitration Act applies; and
          (ii) No dispute resolution proceeding between you and Optum shall be
          permitted to be conducted in a class, representative, or private
          attorney general action, nor may any such proceeding be consolidated
          with any other dispute. Optum and you agree that any claims related to
          the validity of this class waiver shall only be resolved by a court of
          competent jurisdiction located in Minneapolis, Minnesota.
        </div>
        <div className={classes.section}>
          <b>Severability.</b> If any provision of this Agreement is found to be
          unenforceable according to its terms, all remaining provisions will
          continue in full force and effect.
        </div>
        <div className={classes.section}>
          <b>Miscellaneous.</b> The relationship between both parties under your
          enrollment and this Agreement is that of independent contractor.
          Nothing herein contained shall be construed as constituting a
          partnership, joint venture or agency between the parties hereto. You
          shall not assign your enrollment or this Agreement, directly or by
          operation of law, without our prior written consent. Any attempted
          assignment without such consent shall be void. No waiver or failure to
          exercise any option, right, or privilege under your enrollment or this
          Agreement shall be construed to be a waiver of the same or any other
          option, right or privilege on any other occasion. You agree to
          cooperate fully with us in furnishing any information, documentation
          or performing any action requested by us. You shall furnish us, upon
          forty-eight (48) hours’ notice, with true, accurate and complete
          copies of such records, documentation or any other information we or
          our authorized employees, representatives, agents and any regulatory
          agencies may request; provided, however, that you shall not be
          required to divulge any records to the extent prohibited by applicable
          law.
        </div>
      </PageLayout>
    </React.Fragment>
  );
};
