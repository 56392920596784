import React from 'react';
import { Icon } from '@abyss/web/ui/Icon';

export const IconCatalog = props => {
  return (
    <Icon {...props} color='#005eaa'>
      <svg width="40px" height="40px" viewBox="0 0 35 40" focusable="false">
        <title>Catalog</title>
        <desc>A catalog</desc>
        <path
          transform="translate(0.000000, 5.000000)"
          d="M25.312 0c4.245 0 7.272 2.038 8.098 2.663.374.281.59.714.59 1.186v18.653c0 .823-.67 1.493-1.489 1.493-.204 0-.408-.043-.6-.128-1.049-.468-3.26-1.251-6.315-1.251-2.472 0-5.021.508-7.587 1.511a2.751 2.751 0 01-2.027-.01c-2.401-.961-4.867-1.448-7.327-1.448-3.107 0-5.466.782-6.594 1.248A1.49 1.49 0 010 22.543h-.001V3.85c0-.472.216-.905.587-1.186C1.416 2.038 4.443 0 8.685 0c2.186 0 4.377.534 6.516 1.587.568.28 1.171.42 1.797.42.625 0 1.23-.14 1.8-.42C20.935.534 23.127 0 25.311 0zm0 1.702c-1.92 0-3.86.475-5.764 1.413a5.803 5.803 0 01-1.698.523v18.734c2.607-.962 5.21-1.458 7.746-1.458 3.098 0 5.433.741 6.702 1.265V3.955c-.834-.609-3.41-2.253-6.986-2.253zm-16.627 0c-3.575 0-6.152 1.644-6.983 2.253v18.273c1.334-.518 3.799-1.261 6.953-1.261 2.517 0 5.035.473 7.493 1.395V3.638a5.803 5.803 0 01-1.697-.523c-1.905-.938-3.844-1.413-5.766-1.413zM5.245 15.95c1.019-.487 3.747-1.461 7.078.064a.85.85 0 01-.708 1.546c-2.676-1.222-4.83-.46-5.63-.074a.852.852 0 01-.74-1.536zm16.236.064c3.33-1.525 6.06-.55 7.077-.064a.853.853 0 01.4 1.137.85.85 0 01-1.135.399c-.803-.386-2.955-1.148-5.634.074a.853.853 0 01-1.127-.42.85.85 0 01.42-1.126zm-16.237-4.51c1.02-.49 3.748-1.462 7.079.062a.852.852 0 01-.708 1.547c-2.676-1.222-4.83-.46-5.63-.074a.853.853 0 01-.74-1.536zm16.237.062c3.33-1.524 6.06-.551 7.077-.063a.85.85 0 01-.735 1.535c-.803-.385-2.955-1.149-5.634.075a.845.845 0 01-1.127-.42.85.85 0 01.42-1.127zM5.244 7.056c1.02-.49 3.748-1.461 7.079.062a.85.85 0 01-.708 1.547c-2.676-1.223-4.83-.46-5.63-.075a.851.851 0 01-.74-1.534zm16.238.062c3.33-1.523 6.059-.55 7.077-.062a.851.851 0 01-.737 1.534c-.802-.385-2.956-1.148-5.632.075a.842.842 0 01-1.127-.42.85.85 0 01.419-1.127z"
        />
      </svg>
    </Icon>
  );
};
