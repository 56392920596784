"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.baseStyles = void 0;

var baseStyles = function baseStyles(theme) {
  return {
    singleValue: function singleValue(_ref) {
      var isDisabled = _ref.isDisabled;
      return {
        color: isDisabled ? theme.colors.gray600 : 'inherit',
        display: 'inline',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap' // paddingLeft: 10,
        // paddingRight: 10,

      };
    }
  };
};

exports.baseStyles = baseStyles;