//import { http as abyssHttp } from '@abyss/web/tools/http';
import { http as abyssHttp } from '@hhs/ui/old-abyss/ui/tools/http';
import { config } from '@abyss/web/tools/config';
import { event } from '@abyss/web/tools/event';
import { getMessage } from '../messages';

const getCode = response => {
  const data = response.data || {};

  if (data.faultCode) {
    return data.faultCode;
  }
  if (data.errorCode) {
    return data.errorCode;
  }
  if (data.code) {
    return data.code;
  }
  if (response.code) {
    return response.code;
  }

  if (data.errors) {
    return data.errors.map(err => err.code).join(',');
  }

  if (data.error && data.error.errors) {
    return data.error.errors.map(err => err.code).join(',');
  }

  return response.status || 'NoResponse';
};

const getError = response => {
  const code = getCode(response);
  const id = response.requestId;
  const messageId = code ? `${id}[${code}]` : `${id}`;

  let message;

  if (code) {
    message = getMessage(`${id}[${code}]`);
    if (!message) {
      message = getMessage(`default-error[${code}]`);
    }
  }

  if (!message) {
    message = getMessage(id);
    if (!message) {
      message = getMessage('default-error') || messageId;
    }
  }

  return { code, message };
};

const http = options => {
  const httpOptions = options;

  if (config('APP_ENV') === 'local') {
    delete httpOptions.withCredentials;
  }

  return abyssHttp(httpOptions)
    .then(response => {
      response.requestId = httpOptions.id;
      response.resourceId = httpOptions.resource;

      if (!response.cached) {
        event('API_TRANSACTION', {
          result: 'success',
          duration: response.duration,
          status: response.status,
          endpoint: response.endpoint,
          requestId: response.requestId,
          resourceId: response.resourceId,
        });
      }
      return response;
    })
    .catch(response => {
      response.requestId = httpOptions.id;
      response.resourceId = httpOptions.resource;
      const responseData = response.data || {};
      const errorData = getError(response);

      event('API_TRANSACTION', {
        result: 'error',
        duration: response.duration,
        status: response.status,
        endpoint: response.endpoint,
        requestId: response.requestId,
        resourceId: response.resourceId,
        code: errorData.code,
        message: errorData.message,
      });

      throw {
        ...response,
        data: {
          ...responseData,
          ...errorData,
        },
      };
    });
};

Object.assign(http, abyssHttp);

export { http, getError };
