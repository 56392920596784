export const styles = theme => {
  return {
    horizontalLine: {
      borderBottom: '1px solid #D8D8D8',
      marginLeft: 12,
      marginBottom: 20,
      width: '65%',
    },
    subHeading: {
      marginTop: 10,
      lineHeight: '18px',
      marginLeft: 52,
    },
    headerIcon: {
      marginRight: 12,
    },
    continueButton: {
      borderRadius: 3,
    },
    spacing: {
      marginBottom: 30,
    },
    cardContent: {
      backgroundColor: '#ffffff',
      border: `1px solid #D8D8D8`,
    },
    accessiblityLink: {
      '&:focus': {
        outline: 'none',
        boxShadow: '0px 0px 5px 5px rgb(174,202,225)',
      },
    },
    cardHeader: {
      padding: 15,
      borderBottom: `1px solid #D8D8D8`,
    },
    cardText: {
      padding: 15,
    },
    cardTextFooter: {
      marginTop: 10,
    },
    alertMessage: {
      marginTop: 20,
    },
    section: {
      marginTop: 48,
      border: 'none',
      marginLeft: 52,
    },
  };
};
