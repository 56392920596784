import React, { useRef, useEffect } from 'react';
import { useStyles } from '@hhs/ui/old-abyss/ui/styles/hooks/useStyles';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { PageLayout } from '@hhs/ui/src/common/PageLayout';
import { Timeline } from '@hhs/ui/src/base/Timeline';
import { useSteps } from 'src/state/steps';
import { Flex } from '@abyss/web/ui/Flex';
import { Text } from '@abyss/web/ui/Text';
import { styled } from '@abyss/web/tools/styled';


import { styles } from './Header.styles';

const Header = () => {
  const classes = useStyles(styles);
  const { step } = useSteps();
  const router = useRouter();
  const accessibilityRef = useRef();
  const getStepProps = thisStep => {
    const stepProps = {};
    if (step >= thisStep) {
      stepProps.color = 'primary';
      if (step === thisStep) {
        stepProps['aria-current'] = 'step';
      }
    }
    return stepProps;
  };

  useEffect(() => {
    accessibilityRef.current.focus();
  }, []);

  return (
    <header className={classes.header}>
      <a className={classes.skipLink} href="#main-content">
        Skip To Main Content
      </a>
      <PageLayout>
        <Flex gutters className={classes.headerContainer}>
          <Flex.Content space-100 space-medium-40 className={classes.forty}>
            <a href="/step/1"
              ref={accessibilityRef}
              className={classes.mainLink}
            >
              <img 
                src={ '/images/hrsa-logo.png' } 
                alt="Health Resources and Services Administration Logo"
                className={classes.logo}
              />
            </a>
          </Flex.Content>
          <Flex.Content space-100 space-medium-60 className={classes.sixty}>
            <If
              condition={
                (router.getLocation().pathname.includes('/step') ||
                router.getLocation().pathname.includes('/confirmation')) &&
                !router.getLocation().pathname.includes('docu-sign-flow')
              }
            >
              <Then>  
              <Timeline className={classes.timeline}>
                <Timeline.Step {...getStepProps(1)}>
                  <div className={classes.timelineTitle}>Step 1</div>
                  Eligibility
                </Timeline.Step>
                <Timeline.Step {...getStepProps(2)}>
                  <div className={classes.timelineTitle}>Step 2</div>
                  Billing TIN(s)
                </Timeline.Step>
                <Timeline.Step {...getStepProps(3)}>
                  <div className={classes.timelineTitle}>Step 3</div>
                  Verify Payment Information
                </Timeline.Step>
                <Timeline.Step {...getStepProps(4)}>
                  <div className={classes.timelineTitle}>Step 4</div>
                  Attestations
                </Timeline.Step>
                <Timeline.Step {...getStepProps(5)}>
                  <div className={classes.timelineTitle}>Confirmation</div>
                </Timeline.Step>
              </Timeline>
              </Then>  
            </If>
          </Flex.Content>
        </Flex>
      </PageLayout>
    </header>
  );
};

export { Header };
