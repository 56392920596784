"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useInput = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = require("react");

var _useStyles = require("../../../styles/hooks/useStyles");

var _useForm = require("../useForm");

var _useFormState = require("../useFormState");

var _useField = require("../useField");

var _useModel = require("../useModel");

var _getErrors = require("../../redux/helpers/getErrors");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var isEqual = function isEqual(itemA, itemB) {
  return JSON.stringify(itemA) === JSON.stringify(itemB);
};

var modelStyles = function modelStyles(theme) {
  var _theme$colors = theme.colors,
      primaryColor = _theme$colors.primary,
      focusColor = _theme$colors.focus,
      errorColor = _theme$colors.error,
      textColor = _theme$colors.gray800,
      fillColor = _theme$colors.gray100,
      _borderColor = _theme$colors.gray800,
      disabledBorderColor = _theme$colors.gray400,
      disabledColor = _theme$colors.gray200;
  return {
    formInput: {
      color: textColor,
      transitionProperty: 'background-color',
      transitionDuration: '250ms',
      transitionTimingFunction: 'cubic-bezier(.2, .8, .4, 1)',
      borderStyle: 'solid',
      borderWidth: 1,
      borderRadius: 3,
      borderColor: function borderColor(_ref) {
        var isDisabled = _ref.isDisabled,
            isValid = _ref.isValid,
            isFocused = _ref.isFocused;

        if (isDisabled) {
          return disabledBorderColor;
        }

        if (isValid === false) {
          return errorColor;
        }

        if (isFocused) {
          return primaryColor;
        }

        return _borderColor;
      },
      backgroundColor: function backgroundColor(_ref2) {
        var isDisabled = _ref2.isDisabled;

        if (isDisabled) {
          return disabledColor;
        }

        return fillColor;
      },
      boxShadow: function boxShadow(_ref3) {
        var isValid = _ref3.isValid,
            isFocused = _ref3.isFocused;

        if (isFocused) {
          var shadowColor = isValid === false ? errorColor : focusColor;
          return "0 0 0 1px #ffffff, 0 0 3px 4px ".concat(shadowColor);
        }

        return 'inherit';
      },
      outline: '1px dotted transparent',
      outlineOffset: '1px'
    },
    subText: {
      fontSize: 14,
      fontStyle: 'italic',
      color: theme.colors.gray600,
      paddingLeft: 3
    }
  };
};

var useInput = function useInput(_ref4) {
  var innerRef = _ref4.innerRef,
      value = _ref4.value,
      defaultValue = _ref4.defaultValue,
      model = _ref4.model,
      validators = _ref4.validators,
      styles = _ref4.styles,
      isDisabled = _ref4.isDisabled,
      isFocused = _ref4.isFocused,
      onFieldChange = _ref4.onFieldChange,
      mapModelValue = _ref4.mapModelValue;
  var inputRef = innerRef || (0, _react.useRef)();

  var _useState = (0, _react.useState)(true),
      _useState2 = (0, _slicedToArray2["default"])(_useState, 2),
      isValid = _useState2[0],
      setValid = _useState2[1];

  var classes = (0, _useStyles.useStyles)(modelStyles, _objectSpread(_objectSpread({}, styles), {}, {
    isDisabled: isDisabled,
    isFocused: isFocused,
    isValid: isValid
  }));
  var inputClass = classes.formInput;
  var subTextClass = classes.subText;

  if (!model) {
    return {
      inputRef: inputRef,
      inputValue: value,
      defaultValue: defaultValue,
      inputClass: inputClass,
      subTextClass: subTextClass,
      isFocused: isFocused,
      isDisabled: isDisabled,
      isValid: isValid
    };
  }

  var _useState3 = (0, _react.useState)(defaultValue),
      _useState4 = (0, _slicedToArray2["default"])(_useState3, 2),
      inputValue = _useState4[0],
      setInputValue = _useState4[1];

  var form = (0, _useForm.useForm)();
  var formState = (0, _useFormState.useFormState)(model);
  var field = (0, _useField.useField)(model);
  var modelValue = (0, _useModel.useModel)(model, defaultValue);
  (0, _react.useEffect)(function () {
    form.init(model, modelValue, {
      errors: (0, _getErrors.getErrors)(modelValue, formState, validators)
    });
    return function () {
      return form.unset(model);
    };
  }, []);
  (0, _react.useEffect)(function () {
    if (onFieldChange) {
      onFieldChange(field, inputRef);
    }

    if (!field.touched) {
      setValid(true);
    } else if (field.changed || !field.validated) {
      setValid(field.valid);
    }

    if (validators && field.validated === false) {
      form.init(model, modelValue, {
        errors: (0, _getErrors.getErrors)(modelValue, formState, validators)
      });
    }

    var mappedModelValue = modelValue;

    if (mapModelValue) {
      mappedModelValue = mapModelValue(modelValue);
    }

    if (!isEqual(inputValue, mappedModelValue)) {
      setInputValue(mappedModelValue);
    }
  }, [field, modelValue]);
  var handleChange = (0, _react.useCallback)(function (newValue, options) {
    setInputValue(newValue);
    form.set(model, newValue, _objectSpread({
      errors: (0, _getErrors.getErrors)(newValue, formState, validators),
      touched: true
    }, options));
  }, [validators]);
  var handleValidate = (0, _react.useCallback)(function () {
    form.update(model, {
      validated: false
    });
  }, []);
  var handleFocus = (0, _react.useCallback)(function () {
    if (!field.focused) {
      form.update(model, {
        focused: true
      });
    }
  }, []);
  var handleBlur = (0, _react.useCallback)(function () {
    if (field.focused || !field.touched) {
      setTimeout(function () {
        form.update(model, {
          focused: false,
          touched: true
        });
      });
    }
  }, []);
  var handleReset = (0, _react.useCallback)(function () {
    form.set(model, defaultValue, {
      errors: (0, _getErrors.getErrors)(defaultValue, formState, validators),
      touched: true
    });
  }, [validators]);
  return {
    inputRef: inputRef,
    inputValue: inputValue,
    inputClass: inputClass,
    subTextClass: subTextClass,
    isDisabled: isDisabled,
    isFocused: isFocused,
    isValid: isValid,
    handleChange: handleChange,
    handleValidate: handleValidate,
    handleFocus: handleFocus,
    handleBlur: handleBlur,
    handleReset: handleReset
  };
};

exports.useInput = useInput;