import { formatter } from '@abyss/web/tools/formatter';;

const genderMap = {
  M: 'Male',
  F: 'Female',
};

export const formatGender = gender => {
  const genderNormalized = formatter('capitalCase', gender);
  return genderMap[genderNormalized] || genderNormalized || '-';
};
