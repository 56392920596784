"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.baseStyles = void 0;

var baseStyles = function baseStyles(theme) {
  return {
    container: {
      overflowX: 'auto'
    },
    table: {
      width: '100%',
      borderSpacing: 0,
      borderWidth: 1,
      borderTopWidth: 3,
      borderBottomWidth: 3,
      borderStyle: 'solid',
      borderColor: theme.colors.gray400,
      fontSize: 14,
      lineHeight: '16px'
    }
  };
};

exports.baseStyles = baseStyles;