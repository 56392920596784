import { AccessibilityStatement } from './AccessibilityStatement';
import { ContactUs } from './ContactUs';
import { CommunicationPreferences } from './CommunicationPreferences';
import { FAQ } from './FAQ';
import { OptumPayEnrollmentAgreement } from './OptumPayEnrollmentAgreement';
import { PrivacyPolicy } from './PrivacyPolicy';
import { ReliefFundPaymentTermsAndConditions } from './ReliefFundPaymentTermsAndConditions';
import { TermsOfUse } from './TermsOfUse';

export const FooterLinks = {
  AccessibilityStatement,
  ContactUs,
  CommunicationPreferences,
  FAQ,
  OptumPayEnrollmentAgreement,
  PrivacyPolicy,
  ReliefFundPaymentTermsAndConditions,
  TermsOfUse,
};
