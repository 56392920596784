"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.routerActions = exports.goForward = exports.goBack = exports.go = exports.replace = exports.push = exports.updateLocation = exports.onLocationChanged = exports.GO_FORWARD = exports.GO_BACK = exports.GO = exports.REPLACE = exports.PUSH = exports.LOCATION_CHANGE = void 0;
var LOCATION_CHANGE = '@hhs/ui/old-abyss/ui/router/LOCATION_CHANGE';
exports.LOCATION_CHANGE = LOCATION_CHANGE;
var PUSH = '@hhs/ui/old-abyss/ui/router/PUSH';
exports.PUSH = PUSH;
var REPLACE = '@hhs/ui/old-abyss/ui/router/REPLACE';
exports.REPLACE = REPLACE;
var GO = '@hhs/ui/old-abyss/ui/router/GO';
exports.GO = GO;
var GO_BACK = '@hhs/ui/old-abyss/ui/router/GO_BACK';
exports.GO_BACK = GO_BACK;
var GO_FORWARD = '@hhs/ui/old-abyss/ui/router/GO_FORWARD';
exports.GO_FORWARD = GO_FORWARD;

var onLocationChanged = function onLocationChanged(location, action) {
  return {
    type: LOCATION_CHANGE,
    payload: {
      location: location,
      action: action
    }
  };
};

exports.onLocationChanged = onLocationChanged;

var updateLocation = function updateLocation(type, method) {
  return function () {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return {
      type: type,
      payload: {
        method: method,
        args: args
      }
    };
  };
};

exports.updateLocation = updateLocation;
var push = updateLocation(PUSH, 'push');
exports.push = push;
var replace = updateLocation(REPLACE, 'replace');
exports.replace = replace;
var go = updateLocation(GO, 'go');
exports.go = go;
var goBack = updateLocation(GO_BACK, 'goBack');
exports.goBack = goBack;
var goForward = updateLocation(GO_FORWARD, 'goForward');
exports.goForward = goForward;
var routerActions = {
  onLocationChanged: onLocationChanged,
  push: push,
  replace: replace,
  go: go,
  goBack: goBack,
  goForward: goForward
};
exports.routerActions = routerActions;