import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@abyss/web/ui/Modal';
import { useStyles } from '@hhs/ui/old-abyss/ui/styles/hooks/useStyles';
import { Button } from '@hhs/ui/src/base/Button';
import { Flex } from '@abyss/web/ui/Flex';

import { styles } from './RejectModal.styles';

export const RejectModal = ({
  isCheckPayment,
  isLoading,
  isOpen,
  onClose,
  onConfirm,
}) => {
  const classes = useStyles(styles);

  return (
    <Modal title="Are you sure you want to reject the payment?" isOpen={isOpen} onClose={onClose} width={850} scrollableFocus>
        <Modal.Section>
          <Switch>
            <Case condition={!isCheckPayment}>
              <b>How do I return the money?</b>
              <div>
                To return the money you would need to contact your financial
                institution and ask the institution to refuse the received
                Automated Clearinghouse (ACH) credit by initiating ACH return
                using the ACH return code of “R23 - Credit Entry Refused by
                Receiver".
                <Flex>
                  <Flex.Content space-5 />
                  <Flex.Content>
                    a. You are <span className={classes.underline}>not</span>{' '}
                    required to call back in to confirm that the funds have been
                    received by OptumBank.
                  </Flex.Content>
                </Flex>
              </div>
              <div className={classes.modalSection}>
                <b>Can I return the money via check?</b>
                <div>
                  If you received the money via ACH you must return the money
                  via ACH.
                </div>
              </div>
              <div className={classes.modalSection}>
                <b>Can I return a portion of the money?</b>
                <div>No, you must return all of it or keep all of it.</div>
              </div>
            </Case>
            <Case condition={isCheckPayment}>
              By choosing to reject this payment from the HRSA Provider Relief
              Fund, I attest that I am not accepting payment and that I will
              either destroy the check or refund the full amount as described
              below.
              <div className={classes.modalSection}>
                <div className={classes.listContainer}>
                  <ul className={classes.list}>
                    <li>
                      <b>
                        If you received a check and the check has not been
                        deposited:
                      </b>{' '}
                      Destroy, shred or securely dispose of it.
                    </li>
                    <li>
                      <b>If payment was received via paper check:</b> Mail a
                      refund check for the full amount payable to “UnitedHealth
                      Group” to the address below via United States Postal
                      Service (USPS); mailing services such as FedEx and UPS
                      cannot be used with this PO box. List the check number
                      from the original Provider Relief Fund check in the memo.
                    </li>
                  </ul>
                  <div className={classes.modalSection}>
                    UnitedHealth Group
                    <br />
                    Attention: Provider Relief Fund
                    <br />
                    PO Box 31376
                    <br />
                    Salt Lake City, UT 84131-0376
                    <br />
                  </div>
                </div>
              </div>
              <div className={classes.modalSection}>
                If you need additional support, please call the Provider Support
                Line at (866) 569-3522.
              </div>
            </Case>
          </Switch>
          <div className={classes.buttonsContainer}>
            <Button
              className={classes.actionButton}
              onClick={onConfirm}
              isLoading={isLoading}
            >
              I Reject Payment
            </Button>
            <Button
              variant="outline"
              className={classes.actionButton}
              onClick={onClose}
            >
              Cancel
            </Button>
          </div>
        </Modal.Section>
    </Modal>
  );
};

RejectModal.propTypes = {
  isCheckPayment: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};
