"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formReducer = void 0;

var _toArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toArray"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _startsWith = _interopRequireDefault(require("lodash/startsWith"));

var _cloneDeep = _interopRequireDefault(require("lodash/cloneDeep"));

var _some = _interopRequireDefault(require("lodash/some"));

var _every = _interopRequireDefault(require("lodash/every"));

var _get = _interopRequireDefault(require("lodash/get"));

var _set = _interopRequireDefault(require("lodash/set"));

var _createReducer2 = require("../../redux/helpers/createReducer");

var _actions = require("./actions");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var initialState = {};
var initialFormState = {
  fields: {},
  model: {},
  valid: true
};
var initialFieldState = {
  value: null,
  focused: false,
  touched: false,
  changed: false,
  validated: false,
  valid: true,
  errors: {}
};

var setModel = function setModel(model, path, value) {
  return path ? (0, _set["default"])((0, _cloneDeep["default"])(model), path, value) : value;
};

var mapFields = function mapFields(fields, fieldPath, mapOptions) {
  var newFields = _objectSpread({}, fields);

  if (newFields[fieldPath]) {
    newFields[fieldPath] = _objectSpread(_objectSpread({}, newFields[fieldPath]), mapOptions(fieldPath, newFields[fieldPath]));
  } else {
    Object.keys(newFields).forEach(function (key) {
      if ((0, _startsWith["default"])(key, fieldPath)) {
        newFields[key] = _objectSpread(_objectSpread({}, newFields[key]), mapOptions(key, newFields[key]));
      }
    });
  }

  return newFields;
};

var extractData = function extractData(state, action) {
  var _action$payload = action.payload,
      model = _action$payload.model,
      value = _action$payload.value,
      options = _action$payload.options;

  var _model$split = model.split('.'),
      _model$split2 = (0, _toArray2["default"])(_model$split),
      formKey = _model$split2[0],
      path = _model$split2.slice(1);

  var fieldPath = path.join('.');
  var formState = state[formKey] || initialFormState;
  return {
    formKey: formKey,
    formState: formState,
    fieldPath: fieldPath,
    value: value,
    options: options
  };
};

var formReducer = function formReducer() {
  var _createReducer;

  return (0, _createReducer2.createReducer)(initialState, (_createReducer = {}, (0, _defineProperty2["default"])(_createReducer, _actions.SUBMIT, function (state, action) {
    var _extractData = extractData(state, action),
        formKey = _extractData.formKey,
        formState = _extractData.formState,
        fieldPath = _extractData.fieldPath,
        options = _extractData.options;

    var newFields = _objectSpread({}, formState.fields);

    var mappedFields = mapFields(newFields, fieldPath, function (key, field) {
      return _objectSpread({
        focused: key === action.payload.focus,
        touched: field.validated,
        changed: field.validated
      }, options);
    });
    return _objectSpread(_objectSpread({}, state), {}, (0, _defineProperty2["default"])({}, formKey, _objectSpread(_objectSpread({}, formState), {}, {
      fields: mappedFields
    })));
  }), (0, _defineProperty2["default"])(_createReducer, _actions.INIT, function (state, action) {
    var _extractData2 = extractData(state, action),
        formKey = _extractData2.formKey,
        formState = _extractData2.formState,
        fieldPath = _extractData2.fieldPath,
        value = _extractData2.value,
        options = _extractData2.options;

    var newModel = setModel(formState.model, fieldPath, value);

    var newFields = _objectSpread({}, formState.fields);

    if (!newFields[fieldPath] && fieldPath) {
      newFields[fieldPath] = _objectSpread({}, initialFieldState);
    }

    var mappedValue;

    if (newFields[fieldPath]) {
      mappedValue = {
        value: value
      };
    }

    var mappedFields = mapFields(newFields, fieldPath, function () {
      return _objectSpread(_objectSpread({}, mappedValue), {}, {
        valid: !options.errors || !(0, _some["default"])(options.errors),
        validated: true
      }, options);
    });
    return _objectSpread(_objectSpread({}, state), {}, (0, _defineProperty2["default"])({}, formKey, {
      fields: mappedFields,
      model: newModel,
      valid: (0, _every["default"])(mappedFields, function (field) {
        return field.valid;
      })
    }));
  }), (0, _defineProperty2["default"])(_createReducer, _actions.SET, function (state, action) {
    var _extractData3 = extractData(state, action),
        formKey = _extractData3.formKey,
        formState = _extractData3.formState,
        fieldPath = _extractData3.fieldPath,
        value = _extractData3.value,
        options = _extractData3.options;

    var newModel = setModel(formState.model, fieldPath, value);

    var newFields = _objectSpread({}, formState.fields);

    var mappedValue;

    if (newFields[fieldPath]) {
      mappedValue = {
        value: value
      };
    }

    var mappedFields = mapFields(newFields, fieldPath, function () {
      return _objectSpread(_objectSpread({}, mappedValue), {}, {
        changed: true,
        valid: !options.errors || !(0, _some["default"])(options.errors),
        validated: !!options.errors
      }, options);
    });
    return _objectSpread(_objectSpread({}, state), {}, (0, _defineProperty2["default"])({}, formKey, {
      fields: mappedFields,
      model: newModel,
      valid: (0, _every["default"])(mappedFields, function (field) {
        return field.valid;
      })
    }));
  }), (0, _defineProperty2["default"])(_createReducer, _actions.UPDATE, function (state, action) {
    var _extractData4 = extractData(state, action),
        formKey = _extractData4.formKey,
        formState = _extractData4.formState,
        fieldPath = _extractData4.fieldPath,
        options = _extractData4.options;

    var newFields = _objectSpread({}, formState.fields);

    var mappedFields = mapFields(newFields, fieldPath, function () {
      return options;
    });
    return _objectSpread(_objectSpread({}, state), {}, (0, _defineProperty2["default"])({}, formKey, _objectSpread(_objectSpread({}, formState), {}, {
      fields: mappedFields,
      valid: (0, _every["default"])(mappedFields, function (field) {
        return field.valid;
      })
    })));
  }), (0, _defineProperty2["default"])(_createReducer, _actions.RESET, function (state, action) {
    var _extractData5 = extractData(state, action),
        formKey = _extractData5.formKey,
        formState = _extractData5.formState,
        fieldPath = _extractData5.fieldPath,
        value = _extractData5.value,
        options = _extractData5.options;

    var defaultValue = fieldPath ? null : {};
    var resetValue = typeof value !== 'undefined' ? value : defaultValue;
    var newModel = setModel(formState.model, fieldPath, resetValue);

    var newFields = _objectSpread({}, formState.fields);

    var mappedFields = mapFields(newFields, fieldPath, function () {
      return _objectSpread(_objectSpread({}, initialFieldState), options);
    });
    return _objectSpread(_objectSpread({}, state), {}, (0, _defineProperty2["default"])({}, formKey, _objectSpread(_objectSpread({}, formState), {}, {
      fields: mappedFields,
      model: newModel
    })));
  }), (0, _defineProperty2["default"])(_createReducer, _actions.UNSET, function (state, action) {
    var _extractData6 = extractData(state, action),
        formKey = _extractData6.formKey,
        formState = _extractData6.formState,
        fieldPath = _extractData6.fieldPath;

    var newFields = _objectSpread({}, formState.fields);

    delete newFields[fieldPath];
    return _objectSpread(_objectSpread({}, state), {}, (0, _defineProperty2["default"])({}, formKey, _objectSpread(_objectSpread({}, formState), {}, {
      fields: newFields,
      valid: (0, _every["default"])(newFields, function (field) {
        return field.valid;
      })
    })));
  }), (0, _defineProperty2["default"])(_createReducer, _actions.VALIDATE, function (state, action) {
    var _extractData7 = extractData(state, action),
        formKey = _extractData7.formKey,
        formState = _extractData7.formState,
        fieldPath = _extractData7.fieldPath,
        options = _extractData7.options;

    var newFields = _objectSpread({}, formState.fields);

    var mappedFields = mapFields(newFields, fieldPath, function () {
      return _objectSpread({
        validated: false,
        touched: true
      }, options);
    });
    return _objectSpread(_objectSpread({}, state), {}, (0, _defineProperty2["default"])({}, formKey, _objectSpread(_objectSpread({}, formState), {}, {
      fields: mappedFields,
      valid: (0, _every["default"])(mappedFields, function (field) {
        return field.valid;
      })
    })));
  }), (0, _defineProperty2["default"])(_createReducer, _actions.ADD_ITEM, function (state, action) {
    var _extractData8 = extractData(state, action),
        formKey = _extractData8.formKey,
        formState = _extractData8.formState,
        fieldPath = _extractData8.fieldPath,
        value = _extractData8.value;

    var values = (0, _get["default"])(formState.model, fieldPath, []).slice();
    var index = action.payload.index;
    var addIndex = index >= 0 ? index + 1 : values.length;
    values.splice(addIndex, 0, value);
    var newModel = setModel(formState.model, fieldPath, values);
    return _objectSpread(_objectSpread({}, state), {}, (0, _defineProperty2["default"])({}, formKey, _objectSpread(_objectSpread({}, formState), {}, {
      model: newModel
    })));
  }), (0, _defineProperty2["default"])(_createReducer, _actions.REMOVE_ITEM, function (state, action) {
    var _extractData9 = extractData(state, action),
        formKey = _extractData9.formKey,
        formState = _extractData9.formState,
        fieldPath = _extractData9.fieldPath;

    var values = (0, _get["default"])(formState.model, fieldPath, []);
    var index = action.payload.index;
    var removeIndex = index >= 0 ? index : values.length - 1;
    var newValues = values.filter(function (v, i) {
      return i !== removeIndex;
    });
    var newModel = setModel(formState.model, fieldPath, newValues);
    return _objectSpread(_objectSpread({}, state), {}, (0, _defineProperty2["default"])({}, formKey, _objectSpread(_objectSpread({}, formState), {}, {
      model: newModel
    })));
  }), _createReducer));
};

exports.formReducer = formReducer;