"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.baseStyles = void 0;

var baseStyles = function baseStyles(theme) {
  return {
    tableHeader: {
      fontWeight: 600,
      color: theme.colors.secondary,
      backgroundColor: theme.colors.tertiary,
      textAlign: 'left',
      verticalAlign: 'middle',
      padding: 16,
      borderRightWidth: 1,
      borderStyle: 'solid',
      borderColor: theme.colors.gray400,
      backgroundClip: 'padding-box',
      '&:last-child': {
        borderRight: 0
      }
    }
  };
};

exports.baseStyles = baseStyles;