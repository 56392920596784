import React, { useEffect, useState } from 'react';
import { AbyssProvider } from '@abyss/web/ui/AbyssProvider';
import { App } from './App';
import { theme } from '../styles/theme';
import { configureAppStore } from '../state/store';
import { AppProvider } from '@hhs/ui/old-abyss/ui/base/AppProvider';
import { loadFeatures } from '@hhs/api/src/tools/features';

export const store = configureAppStore();  

export const browser = () => {
  const [featuresLoaded, setFeaturesLoaded] = useState(false);
  const appKey = ['cares-dashboard-toggles', 'cares-dashboard-config']; 

  useEffect(() => {  
    const loadAppFeatures = async () => {  
      await loadFeatures(appKey, () => { 
        setFeaturesLoaded(true);  
      });  
    };  

    loadAppFeatures();  
  }, []);  

  if (!featuresLoaded) {  
    return null;  
  }   
  return (  
    <AppProvider store={store}>
        <AbyssProvider includeBaseCss={false} theme={theme}>
          <App />
        </AbyssProvider>
    </AppProvider>
  )
};
