import SourceSansProLight from './formats/SourceSansPro-Light.ttf';
import SourceSansProLightItalic from './formats/SourceSansPro-LightItalic.ttf';
import SourceSansProRegular from './formats/SourceSansPro-Regular.ttf';
import SourceSansProItalic from './formats/SourceSansPro-Italic.ttf';
import SourceSansProSemiBold from './formats/SourceSansPro-SemiBold.ttf';
import SourceSansProBold from './formats/SourceSansPro-Bold.ttf';

// const trimUrl = url => url.replace(/\.\.\//g, '');    // RDR codeql fix
const trimUrl = (url) => {
  return url.split('/').reduce((acc, part) => {
      if (part === '..') {
          if (acc.length > 0) acc.pop();
      } else if (part !== '.' && part !== '') {
          acc.push(part);
      }
      return acc;
  }, []).join('/');
};

export const SourceSansPro = {
  '@font-face': [
    {
      'font-family': 'SourceSansPro',
      'font-weight': 200,
      'font-style': 'normal',
      fallbacks: [{ src: `url(${trimUrl(SourceSansProLight)})` }],
      src: [`url(${trimUrl(SourceSansProLight)}) format('ttf')`],
    },
    {
      'font-family': 'SourceSansPro',
      'font-weight': 200,
      'font-style': 'italic',
      fallbacks: [{ src: `url(${trimUrl(SourceSansProLightItalic)})` }],
      src: [`url(${trimUrl(SourceSansProLightItalic)}) format('ttf')`],
    },
    {
      'font-family': 'SourceSansPro',
      'font-weight': 'normal',
      'font-style': 'normal',
      fallbacks: [{ src: `url(${trimUrl(SourceSansProRegular)})` }],
      src: [`url(${trimUrl(SourceSansProRegular)}) format('ttf')`],
    },
    {
      'font-family': 'SourceSansPro',
      'font-weight': 'normal',
      'font-style': 'italic',
      fallbacks: [{ src: `url(${trimUrl(SourceSansProItalic)})` }],
      src: [`url(${trimUrl(SourceSansProItalic)}) format('ttf')`],
    },
    {
      'font-family': 'SourceSansPro',
      'font-weight': 600,
      'font-style': 'normal',
      fallbacks: [{ src: `url(${trimUrl(SourceSansProSemiBold)})` }],
      src: [`url(${trimUrl(SourceSansProSemiBold)}) format('ttf')`],
    },
    {
      'font-family': 'SourceSansPro',
      'font-weight': 800,
      'font-style': 'normal',
      fallbacks: [{ src: `url(${trimUrl(SourceSansProBold)})` }],
      src: [`url(${trimUrl(SourceSansProBold)}) format('ttf')`],
    },
  ],
};
