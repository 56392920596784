import React from 'react';
import PropTypes from 'prop-types';
import { useStyles } from '@hhs/ui/old-abyss/ui/styles/hooks/useStyles';
import { classNames } from '@abyss/web/tools/classNames';
import { uniqueId } from '@abyss/web/tools/uniqueId';

import { baseStyles } from './Timeline.styles';
import { TimelineStep } from './TimelineStep';

const getTimelineSteps = children => {
  const steps = [].concat(children || []);

  if (steps.length < 2) {
    if (steps.length < 1) {
      steps.push(<TimelineStep />);
    }
    steps.push(<TimelineStep />);
  }

  return steps;
};

export const Timeline = ({
  className,
  styles,
  children,
  lineColor,
  ...props
}) => {
  const timelineSteps = getTimelineSteps(children);
  const stepColors = timelineSteps.map(step => step.props && step.props.color);
  const classes = useStyles(baseStyles, { stepColors, lineColor }, styles);

  return (
    <ol {...props} className={classNames(classes.timeline, className)}>
      {React.Children.map(timelineSteps, (child, index) => {
        if (!child || child.type !== TimelineStep) {
          return child;
        }

        let align = 'center';
        if (index === 0) {
          align = 'center';
        } else if (index === timelineSteps.length - 1) {
          align = 'center';
        }

        return React.cloneElement(child, { key: uniqueId(), align });
      })}
    </ol>
  );
};

Timeline.propTypes = {
  className: PropTypes.string,
  styles: PropTypes.shape({}),
  children: PropTypes.node,
  lineColor: PropTypes.string,
};

Timeline.defaultProps = {
  className: null,
  styles: null,
  children: null,
  lineColor: '#D9D9D9',
};
