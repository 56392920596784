"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "stopwatch", {
  enumerable: true,
  get: function get() {
    return _stopwatch.stopwatch;
  }
});

var _stopwatch = require("./stopwatch");