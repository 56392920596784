import { createSagas } from '@hhs/ui/old-abyss/ui/redux/helpers/createSagas';
import { takeLatest, call } from 'redux-saga/effects';
import { POST_VALIDATE_ADDITIONAL_PAYMENT } from './constants';
import { validateAdditionalPaymentRequest } from './requests';

function* validateAdditionalPaymentSaga(action) {
  return yield call(validateAdditionalPaymentRequest, action.payload);
}

export const additionalPaymentSagas = createSagas([
  takeLatest(POST_VALIDATE_ADDITIONAL_PAYMENT, validateAdditionalPaymentSaga),
]);
