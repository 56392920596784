import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { formatter } from '@abyss/web/tools/formatter';;
import { Table } from '@hhs/ui/old-abyss/ui/base/Table';
import { Button } from '@hhs/ui/src/base/Button';
import { IconTrash } from '@hhs/ui/src/base/Icon/Provider/IconTrash';
import { TextInput } from '@hhs/ui/old-abyss/ui/form/TextInput';
import { useStyles } from '@hhs/ui/old-abyss/ui/styles/hooks/useStyles';
import { FormControl } from '@hhs/ui/old-abyss/ui/form/FormControl';
import { ErrorMessage } from '@hhs/ui/old-abyss/ui/form/ErrorMessage';
import { Modal } from '@abyss/web/ui/Modal';
import { useAttestation } from 'src/state/attestation';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { useForm } from '@hhs/ui/old-abyss/ui/form/hooks/useForm';
import { getMessage } from 'src/tools/messages';

import { styles } from './ACHTINTable.styles';

export const ACHTINTable = ({ validTINs, setError, isLoading }) => {
  const form = useForm();
  const classes = useStyles(styles);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedTin, setselectedTin] = useState(false);
  const [hasDuplicates, setHasDuplicates] = useState();
  const { attestations, setAttestations } = useAttestation();
  const router = useRouter();
  const ACHTins = validTINs.filter(item => !item.checkPayment);

  useEffect(() => {
    ACHTins.forEach(item => {
      if (item.count > 1) {
        setHasDuplicates(true);
      }
    });
  }, []);

  const confirmRemove = () => {
    const filteredAttestations = Object.values(attestations).filter(item => {
      return item.uuid !== selectedTin.uuid;
    });
    const newAttestations = filteredAttestations.reduce((obj, item) => {
      return { ...obj, [item.uuid]: item };
    }, {});

    setAttestations(newAttestations);
    const newTins = filteredAttestations.map(item => item.tin);
    form.set('taxIdNumbers.tins', newTins.join(',\n'));
    form.set(`medicareVerification.${selectedTin.uuid}`, null);

    if (validTINs.length === 1) {
      router.navigate('/step/1');
    } else {
      setModalOpen(false);
    }
  };

  const handleChangeAccountNumber = value => {
    return (value || '').replace(/[^a-zA-Z0-9-\s._]+/g, '');
  };

  const handleFormatAccountNumber = (e, uuid) => {
    const value = (e.target.value || '').replace(/[\w.](?=[\w.]{6})/g, '');
    form.set(`medicareVerification.${uuid}.accountNumber`, value, {
      focused: false,
    });
  };

  const handleFormatDollar = (e, uuid) => {
    const value = (e.target.value || '').replace('$', '');
    const dollar = formatter('money', value);
    form.set(`medicareVerification.${uuid}.amountPaid`, dollar, {
      focused: false,
    });
  };

  const handleRemoveClick = attestation => {
    if (attestations[attestation.uuid].confirmationId) {
      setError({
        message: getMessage('remove-attested-tin'),
      });
    } else {
      setselectedTin(attestation);
      setModalOpen(true);
    }
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        size='$md'
        title="Are you sure?"
      >
        <Modal.Section>
          <div className={classes.modalSection}>
            <Switch>
              <Case condition={validTINs.length === 1}>
                Removing your last TIN will take you back to the home screen.
                Are you sure you want to remove your final TIN?
              </Case>
              <Default>
                Are you sure you want to remove TIN {selectedTin.maskedTin}
              </Default>
            </Switch>
            <div className={classes.modalMessage}>
              Click 'Yes' to remove this row. Click 'No' to keep this row and go
              back.
            </div>
          </div>
          <div className={classes.modalSection}>
            <Button
              onClick={confirmRemove}
              className={classes.modalButton}
            >
              Yes
            </Button>
            <Button
              onClick={() => setModalOpen(false)}
              variant="outline"
              className={classes.modalButton}
            >
              No
            </Button>
          </div>
        </Modal.Section>
      </Modal>
      <h4 className={classes.labelHeader}>
        Automated Clearing House(ACH) Deposit
      </h4>
      <table role="presentation" className={classes.section1}>
        <Table.Head>
          <Table.Row>
            <Table.Header className={classes.tableHeader}>
              Billing TIN(s)
            </Table.Header>
            <Table.Header className={classes.tableHeader}>
              Last Six Digits of Deposit Account Number
            </Table.Header>
            <Table.Header className={classes.tableHeader}>
              Relief Fund Payment
            </Table.Header>
            <If condition={hasDuplicates}>
              <Then>
                <Table.Header className={classes.tableHeader}>
                  Settlement Date
                </Table.Header>
              </Then>
            </If>
            <Table.Header className={classes.tableHeader}>
              Remove TIN
            </Table.Header>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          { ACHTins.map((item, index) => (
            <Table.Row
              role="group"
              key={item.uuid}
              styles={{
                tableRow: base => ({
                  ...base,
                  backgroundColor: '#FEFEFE',
                }),
              }}
            >
              <Table.Cell
                aria-labelledby={`Billing Tax ID Number ${item.tin} grouping`}
              >
                {item.maskedTin}
              </Table.Cell>
              <Table.Cell
                aria-labelledby={`Last Six Digits of Deposit Account Number ${item.tin} grouping`}
                style={{ width: '50%' }}
              >
                <FormControl
                  id={`medicare-account-number-${item.uuid}-${index}`}
                  model={`medicareVerification.${item.uuid}.accountNumber`}
                >
                  {(inputProps, errorProps) => (
                    <React.Fragment>
                      <TextInput
                        {...inputProps}
                        maxLength={80}
                        formatValue={handleChangeAccountNumber}
                        onBlur={e => handleFormatAccountNumber(e, item.uuid)}
                        validators={{
                          required: value => value.length < 6,
                        }}
                      />
                      <ErrorMessage
                        {...errorProps}
                        messages={{
                          required: `Please enter the last six digits of an Account Number for ${item.maskedTin}.`,
                        }}
                      />
                    </React.Fragment>
                  )}
                </FormControl>
              </Table.Cell>
              <Table.Cell
                aria-labelledby={`Relief Fund Payment ${item.tin} grouping`}
                style={{ width: '30%' }}
              >
                <FormControl
                  id={`amount-paid-${item.tin}-${index}`}
                  model={`medicareVerification.${item.uuid}.amountPaid`}
                >
                  {(inputProps, errorProps) => (
                    <React.Fragment>
                      <TextInput
                        {...inputProps}
                        type="dollar"
                        maxLength={15}
                        onBlur={e => handleFormatDollar(e, item.uuid)}
                        validators={{
                          required: value => !value,
                          validAmount: value => {
                            let inValid = false;
                            const leftVal = value.split('.')[0];
                            if (leftVal && leftVal.length > 13) {
                              inValid = true;
                            }
                            return inValid;
                          },
                        }}
                      />
                      <ErrorMessage
                        {...errorProps}
                        messages={{
                          required: `Please enter a Relief Funding Amount for ${item.maskedTin}.`,
                          validAmount: `Max value crossed.Please enter valid amount.`,
                        }}
                      />
                    </React.Fragment>
                  )}
                </FormControl>
              </Table.Cell>
              <If condition={hasDuplicates}>
                <Then>
                  <Table.Cell>
                    <If condition={item.count > 1}>
                      <Then>
                        <FormControl
                          id={`settlement-date-${item.tin}-${index}`}
                          model={`medicareVerification.${item.uuid}.settlementDate`}
                        >
                          {(inputProps, errorProps) => (
                            <React.Fragment>
                              <TextInput
                                {...inputProps}
                                className={classes.dateInput}
                                type="date"
                                validators={{
                                  required: value => !value,
                                }}
                              />
                              <ErrorMessage
                                {...errorProps}
                                messages={{
                                  required: `Please enter a Settlement Date for ${item.maskedTin}.`,
                                }}
                              />
                            </React.Fragment>
                          )}
                        </FormControl>
                      </Then>
                    </If>
                  </Table.Cell>
                </Then>
              </If>
              <Table.Cell
                aria-labelledby={`Remove TIN ${item.tin} grouping`}
                className={classes.tableCellRelative}
              >
                <div className={classes.trashButton}>
                  <Button
                    variant="ghost"
                    aria-label={`Remove TIN ${item.tin}`}
                    onClick={() => handleRemoveClick(item)}
                    isDisabled={isLoading}
                  >
                    <IconTrash />
                  </Button>
                </div>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </table>
    </React.Fragment>
  );
};

ACHTINTable.propTypes = {
  validTINs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setError: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};
