"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.baseStyles = void 0;

var baseStyles = function baseStyles(theme) {
  return {
    errorMessage: {
      color: theme.colors.error,
      fontSize: 13,
      lineHeight: '13px'
    },
    offScreen: {
      position: 'absolute',
      left: -9999
    }
  };
};

exports.baseStyles = baseStyles;