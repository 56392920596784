export const styles = theme => {
  return {
    header: {
      backgroundColor: '#ffffff',
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: '#d8d8d8',
      padding: '20px 0',
    },
    indent: {
      marginLeft: 50,
      width: '65%',
    },
    headerIcon: {
      marginRight: 12,
    },
    heading: {
      marginTop: 10,
      lineHeight: '24px',
      fontSize: theme.fontSizes.size800,
    },
    content: {
      paddingTop: 20,
      paddingLeft: 52,
    },
    formTitle: {
      color: '#005eaa !important',
      marginBottom: 5,
    },
    formControl: {
      marginTop: 10,
      maxWidth: 420,
    },
    formControlCheckbox: {
      marginTop: 40,
      marginBottom: 20,

      '& + &': {
        marginTop: 20,
      },
    },
    checkboxMargin: {
      marginLeft: 15,
    },
    actionButton: {
      borderRadius: 3,
      '& + &': {
        marginLeft: 20,
      },
    },
    backButton: {
      lineHeight: 0,
      verticalAlign: 'top',
      marginTop: 4,
      marginRight: 14,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: '#005eaa !important',
      borderRadius: 3,
      '&:hover': {
        cursor: 'pointer',
      },
    },
    topSection: {
      marginTop: 10,
    },
    section: {
      border: 'none',
    },
    section2: {
      marginTop: 48,
      border: 'none',
      marginLeft: 48,
    },
    half: {
      width: '50%',
    },
    fieldset: {
      border: 'none',
    },
    alertMessage: {
      marginTop: 20,
    },
    accessiblityLink: {
      '&:focus': {
        outline: 'none',
        boxShadow: '0px 0px 5px 5px rgb(174,202,225)',
      },
    },
    horizontalLine: {
      borderBottom: '1px solid #D8D8D8',
      marginLeft: 12,
      marginBottom: 20,
      width: '66%',
    },
    hr: {
      height: 1,
      color: '#d8d8d8',
      backgroundColor: '#d8d8d8',
      border: 'none',
    },
    infoBox: {
      backgroundColor: '#ffffff',
      border: `1px solid #D8D8D8`,
      margin: 12,
    },
    infoBoxTitle: {
      margin: 12,
    },
    infoBoxLabel: {
      color: '#666666',
      paddingRight: 36,
    },
    infoBoxSection: {
      margin: 12,
    },
    padding: {
      marginBottom: 20,
    }
  };
};
