"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.stopwatch = void 0;
var stopwatch = {
  start: function start() {
    var startTime = performance.now();
    return {
      stop: function stop() {
        var timeDiff = (performance.now() - startTime) / 1000;
        return timeDiff.toFixed(2);
      }
    };
  }
};
exports.stopwatch = stopwatch;