"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "SelectList", {
  enumerable: true,
  get: function get() {
    return _SelectList.SelectList;
  }
});

var _reactSelect = require("react-select");

var _SelectList = require("./SelectList");

var _Input = require("./Input");

var _MenuList = require("./MenuList");

var _SingleValue = require("./SingleValue");

var _Option = require("./Option");

Object.keys(_reactSelect.components).forEach(function (key) {
  _SelectList.SelectList[key] = _reactSelect.components[key];
});
_SelectList.SelectList.Input = _Input.Input;
_SelectList.SelectList.MenuList = _MenuList.MenuList;
_SelectList.SelectList.Option = _Option.Option;
_SelectList.SelectList.SingleValue = _SingleValue.SingleValue;