const getPageData = () => {
  return {
    pageName: '',
    siteSectionL1: '',
  };
};

const getUserData = data => {
  return {
    uuid: data.uuid || '',
    tin: data.providerTin || '',
    memberid: data.memberid || '',
    mpin: data.mpin || '',
  };
};

export const adobeTags = {
  VALIDATE_TINS_ERROR: data => {
    return {
      event: 'errorTracking',
      data: {
        content: {
          ...getPageData(),
          pageName: 'step2-billing tins',
          siteSectionL1: 'covid-19 provider relief',
          errorCode: data.errorCode || '',
          errorType: data.errorType || '',
          errorMessage: data.errorMessage || '',
          errorFields: '',
        },
        digitalData: {
          protected: {
            user: {
              ...getUserData(data),
            },
          },
        },
      },
    };
  },
  CONFIRM_TINS_ERROR: data => {
    return {
      event: 'errorTracking',
      data: {
        content: {
          ...getPageData(),
          pageName: 'step3-medicare verification',
          siteSectionL1: 'covid-19 provider relief',
          errorCode: data.errorCode || '',
          errorType: data.errorType || '',
          errorMessage: data.errorMessage || '',
          errorFields: '',
        },
        digitalData: {
          protected: {
            user: {
              ...getUserData(data),
            },
          },
        },
      },
    };
  },
  SUBMIT_ATTESTATION_ERROR: data => {
    const accepted = data.payload.accepted ? 'accept' : 'reject';
    return {
      event: 'errorTracking',
      data: {
        content: {
          ...getPageData(),
          pageName: `I ${accepted}- attest confirmation`,
          siteSectionL1: 'covid-19 provider relief',
          errorCode: data.errorCode || '',
          errorType: data.errorType || '',
          errorMessage: data.errorMessage || '',
          errorFields: '',
        },
        digitalData: {
          protected: {
            user: {
              ...getUserData(data),
            },
          },
        },
      },
    };
  },
  WELCOME_PAGE_VIEW: data => {
    return {
      event: 'trackPageView',
      data: {
        content: {
          ...getPageData(),
          pageName: 'HHS welcome page',
          siteSectionL1: 'covid-19 provider relief',
        },
        digitalData: {
          protected: {
            user: {
              ...getUserData(data),
            },
          },
        },
      },
    };
  },
  BILLING_ENTITY_PAGE_VIEW: data => {
    return {
      event: 'trackPageView',
      data: {
        content: {
          ...getPageData(),
          pageName: 'step1-billing entity confirmation',
          siteSectionL1: 'covid-19 provider relief',
        },
        digitalData: {
          protected: {
            user: {
              ...getUserData(data),
            },
          },
        },
      },
    };
  },
  BILLING_TINS_PAGE_VIEW: data => {
    return {
      event: 'trackPageView',
      data: {
        content: {
          ...getPageData(),
          pageName: 'step2-billing tins',
          siteSectionL1: 'covid-19 provider relief',
        },
        digitalData: {
          protected: {
            user: {
              ...getUserData(data),
            },
          },
        },
      },
    };
  },
  MEDICARE_VERIFICATION_PAGE_VIEW: data => {
    return {
      event: 'trackPageView',
      data: {
        content: {
          ...getPageData(),
          pageName: 'step3-medicare verification',
          siteSectionL1: 'covid-19 provider relief',
        },
        digitalData: {
          protected: {
            user: {
              ...getUserData(data),
            },
          },
        },
      },
    };
  },
  ATTESTATIONS_PAGE_VIEW: data => {
    return {
      event: 'trackPageView',
      data: {
        content: {
          ...getPageData(),
          pageName: 'step4-attestations',
          siteSectionL1: 'covid-19 provider relief',
        },
        digitalData: {
          protected: {
            user: {
              ...getUserData(data),
            },
          },
        },
      },
    };
  },
  ATTESTATION_PARTIAL_COMPLETE_PAGE_VIEW: data => {
    return {
      event: 'trackPageView',
      data: {
        content: {
          ...getPageData(),
          pageName: 'step4-attestation partial complete',
          siteSectionL1: 'covid-19 provider relief',
        },
        digitalData: {
          protected: {
            user: {
              ...getUserData(data),
              acceptedTIN: data.acceptedTIN || '',
              nonacceptedTIN: data.nonacceptedTIN || '',
            },
          },
        },
      },
    };
  },
  ATTESTATION_COMPLETE_PAGE_VIEW: data => {
    return {
      event: 'trackPageView',
      data: {
        content: {
          ...getPageData(),
          pageName: 'step5-attestation complete confirmation',
          siteSectionL1: 'covid-19 provider relief',
          referenceNo: data.referenceNo || '',
        },
        digitalData: {
          protected: {
            user: {
              ...getUserData(data),
              acceptedTIN: data.acceptedTIN || '',
              nonacceptedTIN: data.nonacceptedTIN || '',
              nonstimulusTIN: data.nonstimulusTIN || '',
              ineligibleTIN: data.ineligibleTIN || '',
              visitOptumTIN: data.visitOptumTIN || '',
              acceptedTINRecord: data.acceptedTINRecord || [],
              nonacceptedTINRecord: data.nonacceptedTINRecord || [],
            },
          },
        },
      },
    };
  },
  NOT_ELIGIBLE_PAGE_VIEW: data => {
    return {
      event: 'trackPageView',
      data: {
        content: {
          ...getPageData(),
          pageName: 'not eligible now',
          siteSectionL1: 'covid-19 provider relief',
        },
        digitalData: {
          protected: {
            user: {
              ...getUserData(data),
            },
          },
        },
      },
    };
  },
  DO_NOT_ACCEPT_PAGE_VIEW: data => {
    return {
      event: 'trackPageView',
      data: {
        content: {
          ...getPageData(),
          pageName: 'do not accept funds',
          siteSectionL1: 'covid-19 provider relief',
        },
        digitalData: {
          protected: {
            user: {
              ...getUserData(data),
            },
          },
        },
      },
    };
  },
  ACCEPT_CONFIRMATION_PAGE_VIEW: data => {
    return {
      event: 'trackPageView',
      data: {
        content: {
          ...getPageData(),
          pageName: 'I accept- attest confirmation',
          siteSectionL1: 'covid-19 provider relief',
        },
        digitalData: {
          protected: {
            user: {
              ...getUserData(data),
            },
          },
        },
      },
    };
  },
  BILLING_ENTITY_CONTINUE: data => {
    return {
      event: 'continueStep1',
      data: {
        content: {
          ...getPageData(),
          pageName: 'step1-billing entity confirmation',
          siteSectionL1: 'covid-19 provider relief',
        },
        actions: {
          linkName: data.linkName || '',
        },
        digitalData: {
          protected: {
            user: {
              ...getUserData(data),
            },
          },
        },
      },
    };
  },
  BILLING_TINS_CONTINUE: data => {
    return {
      event: 'continueStep2',
      data: {
        content: {
          ...getPageData(),
          pageName: 'step2-billing tins',
          siteSectionL1: 'covid-19 provider relief',
        },
        actions: {
          linkName: 'billing tax id-continue',
          billingTinEntered: data.billingTINs || '',
        },
        digitalData: {
          protected: {
            user: {
              ...getUserData(data),
            },
          },
        },
      },
    };
  },
  MEDICARE_VERIFICATION_CONTINUE: data => {
    return {
      event: 'continueStep3',
      data: {
        content: {
          ...getPageData(),
          pageName: 'step3-medicare verification',
          siteSectionL1: 'covid-19 provider relief',
        },
        actions: {
          linkName: 'verify payment information-continue',
          billingTinEntered: data.billingTINs || '',
        },
        digitalData: {
          protected: {
            user: {
              ...getUserData(data),
            },
          },
        },
      },
    };
  },
  ACCEPT_CONFIRMATION_SUBMISSION: data => {
    return {
      event: 'submitAttestConfirmation',
      data: {
        content: {
          ...getPageData(),
          pageName: 'I accept- attest confirmation',
          siteSectionL1: 'covid-19 provider relief',
        },
        actions: {
          linkName: 'attest confirmation-submit',
          serviceAddressState: data.serviceAddressState || '',
          serviceAddressZip: data.serviceAddressZip || '',
          billingAddressState: data.billingAddressState || '',
          billingAddressZip: data.billingAddressZip || '',
        },
        digitalData: {
          protected: {
            user: {
              ...getUserData(data),
            },
          },
        },
      },
    };
  },
  REJECT_CONFIRMATION_SUBMISSION: data => {
    return {
      event: 'submitdoNotAcceptFunds',
      data: {
        content: {
          ...getPageData(),
          pageName: 'do not accept funds',
          siteSectionL1: 'covid-19 provider relief',
        },
        actions: {
          linkName: 'I do not accept-submit',
          serviceAddressState: data.serviceAddressState || '',
          serviceAddressZip: data.serviceAddressZip || '',
          billingAddressState: data.billingAddressState || '',
          billingAddressZip: data.billingAddressZip || '',
        },
        digitalData: {
          protected: {
            user: {
              ...getUserData(data),
            },
          },
        },
      },
    };
  },
  VISIT_OPTUM_BANK_BUTTON: data => {
    return {
      event: 'visitOptumBank',
      data: {
        content: {
          ...getPageData(),
          pageName: 'step5-attestation complete confirmation',
          siteSectionL1: 'covid-19 provider relief',
        },
        actions: {
          linkName: 'visit optum bank',
        },
        digitalData: {
          protected: {
            user: {
              ...getUserData(data),
              visitOptumTIN: data.visitOptumTIN || '',
            },
          },
        },
      },
    };
  },
  ATTESTATIONS_SUBMISSION: data => {
    return {
      event: 'acceptAnd DoNotAcceptLink',
      data: {
        content: {
          ...getPageData(),
          pageName: 'step4-attestations',
          siteSectionL1: 'covid-19 provider relief',
        },
        actions: {
          linkName: 'review and accept',
        },
        digitalData: {
          protected: {
            user: {
              ...getUserData(data),
              acceptedTIN: data.acceptedTIN || '',
              nonacceptedTIN: data.nonacceptedTIN || '',
            },
          },
        },
      },
    };
  },
  WELCOME_PAGE_LINK_TRACK: data => {
    return {
      event: 'trackPageView',
      data: {
        content: {
          ...getPageData(),
          pageName: 'HHS welcome page',
          siteSectionL1: 'covid-19 provider relief',
        },
        actions: {
          linkName: data.linkName || '',
        },
        digitalData: {
          protected: {
            user: {
              ...getUserData(data),
              acceptedTIN: data.acceptedTIN || '',
              nonacceptedTIN: data.nonacceptedTIN || '',
            },
          },
        },
      },
    };
  },
  FAQ_PAGE_VIEW: data => {
    return {
      event: 'trackPageView',
      data: {
        content: {
          ...getPageData(),
          pageName: 'frequently asked questions',
          siteSectionL1: 'covid-19 provider relief',
        },
        digitalData: {
          protected: {
            user: {
              ...getUserData(data),
            },
          },
        },
      },
    };
  },
  REGISTRATION_TYPE_PAGE_VIEW: data => {
    return {
      event: 'trackPageView',
      data: {
        content: {
          ...getPageData(),
          pageName: 'w12-registration type',
          siteSectionL1: 'covid-19 provider relief',
          siteSectionL2: 'medicare verification',
        },
        digitalData: {
          protected: {
            user: {
              ...getUserData(data),
            },
          },
        },
      },
    };
  },
  ADDRESS_DETAILS_PAGE_VIEW: data => {
    return {
      event: 'trackPageView',
      data: {
        content: {
          ...getPageData(),
          pageName: 'address details',
          siteSectionL1: 'covid-19 provider relief',
          siteSectionL2: 'medicare verification',
        },
        digitalData: {
          protected: {
            user: {
              ...getUserData(data),
            },
          },
        },
      },
    };
  },
  MEDICARE_SUPPLIER_PAGE_VIEW: data => {
    return {
      event: 'trackPageView',
      data: {
        content: {
          ...getPageData(),
          pageName: 'medicare supplier id',
          siteSectionL1: 'covid-19 provider relief',
          siteSectionL2: 'medicare verification',
        },
        digitalData: {
          protected: {
            user: {
              ...getUserData(data),
            },
          },
        },
      },
    };
  },
  FOOTER_NAVIGATION_LINK_TRACK: data => {
    return {
      event: 'linkTrack',
      data: {
        content: {
          ...getPageData(),
          pageName: data.pageName || '',
          siteSectionL1: 'covid-19 provider relief',
        },
        actions: {
          linkName: data.linkName || '',
        },
        digitalData: {
          protected: {
            user: {
              ...getUserData(data),
              acceptedTIN: data.acceptedTIN || '',
              nonacceptedTIN: data.nonacceptedTIN || '',
            },
          },
        },
      },
    };
  },
};
