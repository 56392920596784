import { theme } from 'src/styles/theme';

export const styles = theme => {
  return {
    header: {
      padding: '20px 0',
      backgroundColor: '#ffffff',
      minHeight: 110,
      borderBottomWidth: 6,
      borderBottomStyle: 'solid',
      borderBottomColor: '#005eaa !important',
    },
    headerContainer: {
      alignItems: 'flex-end',
      justifyContent: 'space-between',
    },
    logo: {
      height: 73,
    },
    forty: {
        width: '40%',
    },
    sixty: {
        width: '60%',
    },
    mainLink: {
      '&:focus': {
        outline: 'none',
        paddingTop: '60px',
        boxShadow: '0px 0px 5px 5px rgb(174,202,225)',
      },
    },
    timelineTitle: {
      fontWeight: 'bold',
      marginBottom: 4,
    },
    skipLink: {
      position: 'absolute',
      left: '-10000px',
      top: 'auto',
      width: '1px',
      height: '1px',
      overflow: 'hidden',
      '&:focus': {
        width: 'auto',
        height: 'auto',
        left: 'auto',
        right: 'auto',
        position: 'inherit',
      },
    },
  };
};
