import { useAction } from '@hhs/ui/old-abyss/ui/redux/hooks/useAction';
import { useSaga } from '@hhs/ui/old-abyss/ui/redux/hooks/useSaga';
import { useSelect } from '@hhs/ui/old-abyss/ui/redux/hooks/useSelect';
import {
  validateTins,
  confirmTinInfo,
  submitAttestation,
  loadAttestation,
  setAttestations,
} from './actions';
import {
  VALIDATE_TINS,
  CONFIRM_TIN_INFO,
  SUBMIT_ATTESTATION,
} from './constants';

const attestationSelector = state => state.attestation;

export const useAttestation = () => {
  const { attestations, attestationData } = useSelect(attestationSelector);

  return {
    attestations,
    attestationData,
    validateTins: useAction(validateTins, {
      saga: useSaga(VALIDATE_TINS),
    }),
    confirmTinInfo: useAction(confirmTinInfo, {
      saga: useSaga(CONFIRM_TIN_INFO),
    }),
    submitAttestation: useAction(submitAttestation, {
      saga: useSaga(SUBMIT_ATTESTATION),
    }),
    loadAttestation: useAction(loadAttestation),
    setAttestations: useAction(setAttestations),
  };
};
