"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "Table", {
  enumerable: true,
  get: function get() {
    return _Table.Table;
  }
});

var _Table = require("./Table");

var _TableBody = require("./TableBody");

var _TableCell = require("./TableCell");

var _TableHead = require("./TableHead");

var _TableHeader = require("./TableHeader");

var _TableRow = require("./TableRow");

_Table.Table.Body = _TableBody.TableBody;
_Table.Table.Cell = _TableCell.TableCell;
_Table.Table.Head = _TableHead.TableHead;
_Table.Table.Header = _TableHeader.TableHeader;
_Table.Table.Row = _TableRow.TableRow;