"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.cache = void 0;

var _storage = require("../storage");

if (typeof window !== 'undefined') {
  window.__abyss_http_cache__ = window.__abyss_http_cache__ || {};
}

var cacheStorage = {
  listItems: function listItems() {
    return window.__abyss_http_cache__;
  },
  getItem: function getItem(key) {
    return window.__abyss_http_cache__[key];
  },
  setItem: function setItem(key, value) {
    window.__abyss_http_cache__[key] = value;
  },
  removeItem: function removeItem(key) {
    delete window.__abyss_http_cache__[key];
  }
};
var cache = {
  list: function list() {
    return cacheStorage.listItems();
  },
  get: function get(key) {
    return _storage.storage.getItem(cacheStorage, key);
  },
  set: function set(key, value, maxAge) {
    return _storage.storage.setItem(cacheStorage, key, value, maxAge);
  },
  remove: function remove(key) {
    return cacheStorage.removeItem(key);
  }
};
exports.cache = cache;