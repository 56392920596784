import React from 'react';
import { Icon } from '@abyss/web/ui/Icon';

export const IconTrash = props => {
  return (
    <Icon {...props} color='#005eaa'>
      <svg width="40px" height="40px" viewBox="0 0 35 40" focusable="false">
        <title>Delete Trash Empty</title>
        <desc>An empty trash can</desc>
        <path
          transform="translate(4.000000, 0.000000)"
          d="M19.649 34H6.896c-.95 0-1.795-.362-2.511-1.075-.717-.717-1.08-1.562-1.08-2.515L2.662 9.198H.849A.85.85 0 010 8.35v-4.39a.85.85 0 01.85-.85h7.366V.851c0-.47.382-.851.85-.851h8.044c.469 0 .85.38.85.85v2.26h7.536c.467 0 .85.38.85.849v4.39a.85.85 0 01-.85.85H24l-.592 21.234c0 .03-.002.059-.007.088a4.251 4.251 0 01-1.135 2.382c-.68.727-1.56 1.097-2.616 1.097zM4.36 9.198l.646 21.186c0 .526.183.942.578 1.337.396.394.813.58 1.31.58H19.65c.583 0 1.019-.178 1.373-.558a2.56 2.56 0 00.688-1.403l.588-21.142H4.36zm18.813-1.7h1.47V4.81H1.7V7.5h21.474zM9.917 3.11h6.343V1.701H9.917V3.11zm4.188 22.478v-9.87a.85.85 0 10-1.7 0v9.87a.85.85 0 101.7 0zm-4.359 0v-9.87a.85.85 0 00-1.701 0v9.87c0 .468.382.85.85.85.469 0 .851-.382.851-.85zm8.72 0v-9.87a.85.85 0 00-1.7 0v9.87c0 .468.381.85.851.85.467 0 .85-.382.85-.85z"
        />
      </svg>
    </Icon>
  );
};
