"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.http = exports.createInstance = exports.httpRequest = exports.makeRequest = exports.handleRetry = exports.identifyError = exports.mapFormData = exports.getEndpoint = exports.parseJSON = exports.retryDefaults = exports.cacheDefaults = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _axios = _interopRequireDefault(require("axios"));

var _qs = _interopRequireDefault(require("qs"));

var _http = require("http");

var _userAgent = require("../userAgent");

var _tokenizer = require("../tokenizer");

var _stopwatch = require("../stopwatch");

var _cache = require("./cache");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var cacheDefaults = {
  maxAge: 1000 * 60 * 15,
  onCached: function onCached() {
    return null;
  }
};
exports.cacheDefaults = cacheDefaults;
var retryDefaults = {
  currentRetryAttempt: 0,
  maxRetries: 0,
  retryDelay: 100,
  shouldRetry: function shouldRetry() {
    return true;
  },
  onRetryAttempt: function onRetryAttempt() {
    return null;
  }
};
exports.retryDefaults = retryDefaults;

var parseJSON = function parseJSON(data) {
  try {
    return JSON.parse(data);
  } catch (e) {
    return data;
  }
};

exports.parseJSON = parseJSON;

var getEndpoint = function getEndpoint(options) {
  var logUrl = (options.logUrl || options.url || '').replace(/^.*\/\/[^/]+/, '');
  return "".concat(logUrl, " (").concat(options.method, ")");
};

exports.getEndpoint = getEndpoint;

var mapFormData = function mapFormData(data) {
  return Object.keys(data).reduce(function (formData, key) {
    if (Array.isArray(data[key])) {
      data[key].forEach(function (file) {
        formData.append(key, file);
      });
    } else {
      formData.append(key, data[key]);
    }

    return formData;
  }, new FormData());
};

exports.mapFormData = mapFormData;

var identifyError = function identifyError(_ref) {
  var err = _ref.err,
      options = _ref.options;
  var res = err.response || err || {};

  var newOptions = _objectSpread({}, options);

  if (!res.status) {
    res.data = {
      code: 'NoResponse',
      message: 'No response from server.'
    };
  }

  if (newOptions.responseType === 'arraybuffer' && res.data) {
    res.data = parseJSON(String.fromCharCode.apply(null, new Uint8Array(res.data)));
    newOptions.responseType = 'json';
  }

  res.message = res.data && res.data.message || _http.STATUS_CODES[res.status || 500];
  return {
    res: res,
    options: newOptions
  };
};

exports.identifyError = identifyError;

var handleRetry = function handleRetry(options, res, makeRequest) {
  var retry = options.retry;

  if (retry.currentRetryAttempt < retry.maxRetries && retry.shouldRetry(res, retry)) {
    var onBackoffPromise = new Promise(function (resolve) {
      var delay = (Math.pow(2, retry.currentRetryAttempt) - 1) / 2 * 1000;
      setTimeout(resolve, delay + retry.retryDelay);
    });
    var onRetryAttemptPromise = new Promise(function (resolve) {
      return resolve(retry.onRetryAttempt(options, res));
    });

    var retryOptions = _objectSpread(_objectSpread({}, options), {}, {
      retry: _objectSpread(_objectSpread({}, options.retry), {}, {
        currentRetryAttempt: retry.currentRetryAttempt + 1
      })
    });

    return Promise.resolve().then(function () {
      return onBackoffPromise;
    }).then(function () {
      return onRetryAttemptPromise;
    }).then(function () {
      return makeRequest(retryOptions);
    });
  }

  return Promise.reject(res);
};

exports.handleRetry = handleRetry;

var makeRequest = function makeRequest(options) {
  var clearCacheKeys = function clearCacheKeys() {
    if (options.cache && options.cache.clear) {
      var cacheList = _cache.cache.list();

      options.cache.clear.forEach(function (key) {
        Object.keys(cacheList).forEach(function (token) {
          if (token.startsWith(key)) {
            _cache.cache.remove(token);
          }
        });
      });
    }
  };

  var useCache = options.cache && options.cache.maxAge > 0;

  if (useCache) {
    var cachedRequest = _cache.cache.get(options.cache.token);

    if (cachedRequest) {
      options.cache.onCached(options);
      return cachedRequest.then(function (res) {
        res.cached = true;
        clearCacheKeys();
        return res;
      });
    }
  }

  var timer = _stopwatch.stopwatch.start();

  var request = (0, _axios["default"])(options).then(function (res) {
    res.duration = timer.stop();
    res.endpoint = options.endpoint;
    clearCacheKeys();

    if (options.handleSuccess) {
      var success = options.handleSuccess(res, options);

      if (success) {
        return success;
      }
    }

    return res;
  })["catch"](function (err) {
    var _identifyError = identifyError({
      err: err,
      options: options
    }),
        res = _identifyError.res,
        updatedOptions = _identifyError.options;

    res.duration = timer.stop();
    res.endpoint = options.endpoint;
    Object.assign(options, updatedOptions);

    if (options.handleError) {
      var success = options.handleError(res, options);

      if (success && options.handleSuccess) {
        success = options.handleSuccess(success, options);
      }

      if (success) {
        return success;
      }
    }

    if (useCache) {
      _cache.cache.remove(options.cache.token);
    }

    if (options.retry) {
      return handleRetry(options, res, makeRequest);
    }

    return Promise.reject(res);
  });

  if (useCache) {
    _cache.cache.set(options.cache.token, request, options.cache.maxAge);
  }

  return request;
};

exports.makeRequest = makeRequest;

var httpRequest = function httpRequest(opts) {
  var options = _objectSpread({}, opts);

  options.method = (options.method || 'GET').toUpperCase();
  options.endpoint = getEndpoint(options);

  if (_userAgent.userAgent.isIE) {
    if (options.method === 'GET') {
      options.query = _objectSpread(_objectSpread({}, options.query), {}, {
        t: Date.now()
      });
    }
  }

  if (options.query) {
    var query = _qs["default"].stringify(options.query, {
      encode: options.encode
    });

    options.url += query ? "?".concat(query) : '';
    delete options.query;
  }

  if (options.body) {
    options.data = options.body;
    delete options.body;
  }

  if (options.formData) {
    options.data = mapFormData(options.formData);
    delete options.formData;
  }

  if (options.retry !== false) {
    options.retry = _objectSpread(_objectSpread({}, retryDefaults), options.retry);
  }

  if (options.cache !== false) {
    var cacheTokenPrefix = options.id ? "".concat(options.id, ":") : '';
    options.cache = _objectSpread(_objectSpread(_objectSpread({}, cacheDefaults), options.cache), {}, {
      token: "".concat(cacheTokenPrefix).concat(_tokenizer.tokenizer.create({
        url: options.url,
        method: options.method,
        data: options.data && JSON.stringify(options.data)
      }))
    });
  }

  return makeRequest(options);
};

exports.httpRequest = httpRequest;
Object.assign(httpRequest, _axios["default"]);

var createInstance = function createInstance() {
  var headers = {};

  var httpInstance = function httpInstance() {
    var opts = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return httpRequest(_objectSpread(_objectSpread({}, opts), {}, {
      headers: _objectSpread(_objectSpread({}, headers), opts.headers)
    }));
  };

  httpInstance.setHeader = function (key, value) {
    headers[key] = value;
  };

  return httpInstance;
};

exports.createInstance = createInstance;
var http = createInstance();
exports.http = http;