import { userReducer, userSagas } from './user';
import { attestationReducer, attestationSagas } from './attestation';
import { stepsReducer } from './steps';
import {
  additionalPaymentReducer,
  additionalPaymentSagas,
} from './additionalPayment';

export const reducers = {
  user: userReducer,
  steps: stepsReducer,
  attestation: attestationReducer,
  additionalPayment: additionalPaymentReducer,
};

export const sagas = {
  user: userSagas,
  attestation: attestationSagas,
  additionalPayment: additionalPaymentSagas,
};
