"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "Ripples", {
  enumerable: true,
  get: function get() {
    return _Ripples.Ripples;
  }
});

var _Ripples = require("./Ripples");