"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.combineSagas = void 0;

var combineSagas = function combineSagas(sagas) {
  return Object.values(sagas).reduce(function (arr, value) {
    return arr.concat(value);
  }, []);
};

exports.combineSagas = combineSagas;