"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useStyles = void 0;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireWildcard(require("react"));

var _reactJss = require("react-jss");

var _helpers = require("./helpers");

var _useAbyssTheme = require("@abyss/web/hooks/useAbyssTheme");

var useStyles = function useStyles(styles, options) {
  var opts = options;

  var _parseCustomStyles = (0, _helpers.parseCustomStyles)(opts),
      custom = _parseCustomStyles.custom,
      data = _parseCustomStyles.data;

  var context = _react["default"].useContext(_reactJss.JssContext);

  var theme = (0, _useAbyssTheme.useAbyssTheme)();  

  var _useMemo = (0, _react.useMemo)(function () {
    var sheetIndex = (0, _helpers.getSheetIndex)();
    var newStyles = (0, _helpers.getCustomStyles)(styles, custom, theme);
    var newSheet = (0, _helpers.createStyleSheet)({
      context: context,
      styles: newStyles,
      theme: theme,
      index: sheetIndex
    });
    var newDynamicRules = newSheet ? (0, _helpers.addDynamicRules)(newSheet, data) : null;

    if (newSheet) {
      (0, _helpers.manageSheet)({
        index: sheetIndex,
        context: context,
        sheet: newSheet,
        theme: theme
      });
    }

    return [newSheet, newDynamicRules, sheetIndex];
  }, [context, theme]),
      _useMemo2 = (0, _slicedToArray2["default"])(_useMemo, 3),
      sheet = _useMemo2[0],
      dynamicRules = _useMemo2[1],
      index = _useMemo2[2];

  var dataString = JSON.stringify(data);
  (0, _react.useLayoutEffect)(function () {
    if (sheet && dynamicRules) {
      (0, _helpers.updateDynamicRules)(sheet, data, dynamicRules);
    }
  }, [dataString]);
  (0, _react.useLayoutEffect)(function () {
    return function () {
      if (sheet) {
        (0, _helpers.unmanageSheet)({
          index: index,
          context: context,
          sheet: sheet,
          theme: theme
        });

        if (dynamicRules) {
          (0, _helpers.removeDynamicRules)(sheet, data, dynamicRules);
        }
      }
    };
  }, [sheet]);
  return (0, _helpers.getSheetClasses)(sheet, dynamicRules);
};

exports.useStyles = useStyles;