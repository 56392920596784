"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "configureStore", {
  enumerable: true,
  get: function get() {
    return _store.configureStore;
  }
});

var _store = require("./store");