"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.event = event;

var _typeof2 = _interopRequireDefault(require("@babel/runtime/helpers/typeof"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _every = _interopRequireDefault(require("lodash/every"));

var _storage = require("../storage");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var LISTENER_STORAGE_KEY = '@abyss/event/listeners';
var DATA_STORAGE_KEY = '@abyss/event/data';

var getData = function getData() {
  return _storage.storage.window.get(DATA_STORAGE_KEY) || {};
};

var setData = function setData(data) {
  _storage.storage.window.set(DATA_STORAGE_KEY, _objectSpread(_objectSpread({}, getData()), data));
};

var waitFor = function waitFor(validate, callback, tries) {
  if (typeof validate === 'function' && typeof callback === 'function') {
    if (validate(tries === 0)) {
      callback();
    } else {
      var newTries = tries ? tries - 1 : 60;
      setTimeout(function () {
        waitFor(validate, callback, newTries);
      }, 500);
    }
  }
};

var debounceEvents = {};

var debounce = function debounce(tag, ms, callback) {
  var debounceId = "".concat(tag.listenerId, "-").concat(tag.eventId);

  if (debounceEvents[debounceId]) {
    return false;
  }

  debounceEvents[debounceId] = true;
  setTimeout(function () {
    delete debounceEvents[debounceId];
  }, ms || 200);
  return callback();
};

var getTag = function getTag(listenerId, eventId, eventData) {
  var listeners = _storage.storage.window.get(LISTENER_STORAGE_KEY) || {};
  var listener = listeners[listenerId];

  if (listener) {
    var tagFunction = listener.events[eventId];

    if (typeof tagFunction === 'function') {
      var globalData = _storage.storage.window.get(DATA_STORAGE_KEY) || {};
      var tag = tagFunction(_objectSpread(_objectSpread(_objectSpread({}, globalData), listener.metaData), eventData));
      return _objectSpread(_objectSpread({}, tag), {}, {
        listenerId: listenerId,
        eventId: eventId
      });
    }
  }

  return null;
};

var listener = function listener(eventListener) {
  if (typeof eventListener.id === 'string' && typeof eventListener.trigger === 'function') {
    var listeners = _storage.storage.window.get(LISTENER_STORAGE_KEY) || {};

    _storage.storage.window.set(LISTENER_STORAGE_KEY, _objectSpread(_objectSpread({}, listeners), {}, (0, _defineProperty2["default"])({}, eventListener.id, {
      trigger: eventListener.trigger,
      events: eventListener.events || {},
      metadata: eventListener.metadata || {}
    })));
  }
};

function event(eventId, eventData) {
  var listeners = _storage.storage.window.get(LISTENER_STORAGE_KEY) || {};
  Object.keys(listeners || {}).forEach(function (listenerId) {
    var tag = getTag(listenerId, eventId, eventData);

    if (tag && (0, _typeof2["default"])(tag) === 'object') {
      waitFor(function (hasTimedOut) {
        var hasRequired = (0, _every["default"])(Object.values(tag.requiredData || {}));

        if (hasTimedOut || hasRequired) {
          return true;
        }

        tag = getTag(listenerId, eventId, eventData);
        return false;
      }, function () {
        listeners[listenerId].trigger(tag);
      });
    }
  });
}

event.getData = getData;
event.setData = setData;
event.waitFor = waitFor;
event.debounce = debounce;
event.listener = listener;
event.getTag = getTag;