"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "AlertIcon", {
  enumerable: true,
  get: function get() {
    return _AlertIcon.AlertIcon;
  }
});

var _AlertIcon = require("./AlertIcon");