import React from 'react';
import { Icon } from '@abyss/web/ui/Icon';

export const IconPrint = props => {
  return (
    <Icon {...props}>
      <svg width="40px" height="37px" viewBox="0 0 40 37" focusable="false">
        <title>print</title>
        <desc>Created with Sketch.</desc>
        <g
          id="Seamless-Icons"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="print"
            transform="translate(-10.000000, -11.000000)"
            fill="#005eaa"
            fillRule="nonzero"
          >
            <g transform="translate(10.000000, 11.000000)">
              <path
                d="M19.0267516,8.25302001 C24.5457325,8.25251045 30.0647133,8.25123657 35.5836944,8.25352956 C38.6336307,8.25480345 39.7459872,9.35340217 39.7472611,12.3584977 C39.7485751,16.0520009 39.7495541,19.7455041 39.7467515,23.4392621 C39.7449683,26.1653767 38.5526115,27.3625742 35.7956688,27.4079245 C35.1615285,27.4183703 34.5268789,27.4094531 33.7057325,27.4094531 C33.7057325,25.9727652 33.7154141,24.629071 33.7039491,23.2858862 C33.6764331,20.1383703 31.7645859,18.2148034 28.6224203,18.2079245 C22.8061146,18.1951856 16.9900637,18.196969 11.173758,18.2071601 C8.00738853,18.21302 6.0843312,20.113657 6.05299363,23.243848 C6.03974523,24.5544213 6.05070064,25.8655041 6.05070064,27.3658862 C4.87694267,27.3658862 3.86012739,27.4323831 2.85554141,27.3503449 C1.31235669,27.224485 0.0430573248,25.8548034 0.02955414,24.2502175 C-0.00713375797,19.9625742 -0.0129936306,15.6744213 0.0308280256,11.3870327 C0.0496815285,9.53633211 1.49019108,8.26805186 3.48866243,8.26066332 C8.66777069,8.24180982 13.8473885,8.25327479 19.0267516,8.25302001 Z M29.8563056,13.0739117 C29.5011464,13.1009181 29.1867515,13.6616824 28.8535032,13.9768417 C29.2229299,14.2983703 29.5487899,14.7959499 29.9714651,14.9001538 C30.6107005,15.0578608 31.3174523,14.9414277 31.9956688,14.9414277 L31.9956688,14.9365869 C32.6738853,14.9365869 33.3765605,15.0443576 34.0203821,14.895313 C34.3926115,14.8091983 34.9207643,14.3093257 34.9200016,13.996969 C34.9187261,13.6741665 34.3806371,13.1014277 34.0443312,13.0762047 C32.6540128,12.970727 31.2463693,12.9686888 29.8563056,13.0739117 Z"
                id="Fill-1"
              />
              <path
                d="M28.6674129,20.2500395 C30.6842281,20.2584471 31.7509797,21.2778102 31.7690689,23.2829057 C31.798623,26.5504217 31.7981135,29.8187019 31.7688141,33.0864726 C31.7509797,35.0378102 30.7298332,36.1109312 28.7812982,36.1223962 C22.8398969,36.1583197 16.8979861,36.1590841 10.9565848,36.1218866 C9.00601157,36.1094026 7.99633004,35.0309312 7.98129819,33.0742433 C7.95633004,29.8062178 7.95378227,26.5381923 7.9823173,23.2704217 C7.99989692,21.2783197 9.07505615,20.2579376 11.0972218,20.2500395 C14.0679224,20.2383197 17.0388778,20.2469822 20.0095785,20.2469822 C22.8956931,20.2469822 25.781553,20.238065 28.6674129,20.2500395 Z M13.3265211,29.1055809 C12.6093237,29.1015045 11.8633364,29.1142433 11.8452472,30.0497847 C11.8271581,30.9822688 12.5576039,31.0459631 13.2847377,31.0449439 C17.6865211,31.0385745 22.0883046,31.0383197 26.4898332,31.0452076 C27.2248651,31.0462178 27.9298332,30.9608675 27.9058842,30.0268548 C27.8839734,29.1766637 27.2235912,29.0999758 26.5316167,29.1048166 C24.3308523,29.1198484 22.1298332,29.1104217 19.9290689,29.1101669 C17.7283046,29.1101669 15.5272854,29.118065 13.3265211,29.1055809 Z M13.1558205,25.3058357 C12.4269033,25.2989567 11.8368396,25.4727146 11.8428608,26.2971733 C11.8490689,27.1295299 12.4857568,27.2915682 13.1843555,27.2913134 C17.634037,27.2900395 22.0837186,27.290549 26.5331453,27.2915682 C27.2195147,27.2915682 27.880916,27.170549 27.9046103,26.3300395 C27.9308523,25.4023962 27.256967,25.2999758 26.5043555,25.3066 C24.3007886,25.3262178 22.0972218,25.3137338 19.8936549,25.3139885 C17.6475402,25.3139885 15.4014256,25.3277465 13.1558205,25.3058357 Z"
                id="Fill-4"
              />
              <path
                d="M29.8258337,6.17974464 L9.95602482,6.17974464 C9.95602482,4.71783381 9.81156622,3.27273827 10.0263433,1.8834389 C10.1137318,1.31834335 10.9152605,0.754266923 11.5185726,0.415413418 C11.9501649,0.172865647 12.5929675,0.277069469 13.1415025,0.276050361 C17.7606108,0.268152272 22.3797191,0.26305673 26.9990821,0.275540807 C29.2943688,0.281910234 29.8243051,0.8378338 29.8258337,3.14611405 C29.8263433,4.11477648 29.8258337,5.08343891 29.8258337,6.17974464"
                id="Fill-7"
              />
            </g>
          </g>
        </g>
      </svg>
    </Icon>
  );
};
