"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.baseStyles = void 0;
var rippleOpacity = 0.2;
var rippleTiming = 'cubic-bezier(0.23, 1, 0.32, 1)';
var baseStyles = {
  rippleContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    pointerEvents: 'none'
  },
  ripple: {
    position: 'absolute',
    color: 'inherit',
    backgroundColor: 'currentColor',
    borderRadius: '50%',
    transition: "opacity 1s ".concat(rippleTiming, ", transform 1s ").concat(rippleTiming),
    opacity: rippleOpacity,
    transform: 'scale(0)'
  },
  opacity: {
    opacity: 0
  },
  transform: {
    transform: 'scale(2)'
  }
};
exports.baseStyles = baseStyles;