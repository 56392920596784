export const styles = () => {
  return {
    underline: {
      textDecoration: 'underline',
    },
    modalSection: {
      marginTop: 24,
    },
    actionButton: {
      borderRadius: 3,
      '& + &': {
        marginLeft: 20,
      },
    },
    list: {
      listStyle: 'disc',
    },
    listContainer: {
      marginLeft: 36,
    },
    buttonsContainer: {
      marginTop: 20,
    }
  };
};
