"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.baseStyles = void 0;

var baseStyles = function baseStyles() {
  return {
    enhancer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%'
    },
    before: {
      display: 'flex',
      alignItems: 'center',
      height: function height(_ref) {
        var _height = _ref.height;
        return _height;
      },
      paddingRight: function paddingRight(_ref2) {
        var padding = _ref2.padding;
        return padding;
      }
    },
    after: {
      display: 'flex',
      alignItems: 'center',
      height: function height(_ref3) {
        var _height2 = _ref3.height;
        return _height2;
      },
      paddingLeft: function paddingLeft(_ref4) {
        var padding = _ref4.padding;
        return padding;
      }
    }
  };
};

exports.baseStyles = baseStyles;