import { createStyles, baseStyles } from '@hhs/ui/old-abyss/ui/styles/stylesheet';
import { theme, globals, fonts } from './modules';

export const attachStylesheet = () => {
  createStyles(baseStyles(theme));
  createStyles(globals);
  createStyles(fonts);

  if (module.hot) {
    module.hot.accept('./modules', () => {
      attachStylesheet();
    });
  }
};
