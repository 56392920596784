"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.baseStyles = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _userAgent = require("../../tools/userAgent");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var baseStyles = function baseStyles(theme) {
  var gray600 = theme.colors.gray600;
  var size500 = theme.fontSizes.size500;
  var IEStyles = _userAgent.userAgent.isIE && {
    '&::-ms-clear, &::-ms-reveal': {
      display: 'none',
      width: 0,
      height: 0
    }
  };
  return {
    wrapper: {
      paddingLeft: 10,
      paddingRight: 10,
      paddingTop: 8,
      paddingBottom: 8
    },
    input: _objectSpread({
      display: 'block',
      width: '100%',
      backgroundColor: 'transparent',
      border: 'none',
      resize: 'none',
      fontFamily: 'inherit',
      '&:focus': {
        outline: 'none',
        boxShadow: 'none'
      },
      '&::placeholder': {
        fontSize: size500,
        color: gray600
      }
    }, IEStyles)
  };
};

exports.baseStyles = baseStyles;