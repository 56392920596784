"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "useStyles", {
  enumerable: true,
  get: function get() {
    return _useStyles.useStyles;
  }
});

var _useStyles = require("./useStyles");