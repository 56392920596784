import MESSAGES from './list.json';

export const getMessage = (id, data) => {
  let message = MESSAGES[id] || '';

  if (data && /{{[a-zA-Z0-9_]+}}/.test(message)) {
    const messageMatches = message.match(/{{([a-zA-Z0-9_]+)}}/g);

    for (let i = 0; i < messageMatches.length; i++) {
      const match = messageMatches[i];
      const dataMatch = match.replace('{{', '').replace('}}', '');
      message = message.replace(match, data[dataMatch] || '');
    }
  }
  return message;
};
