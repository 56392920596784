import React from 'react';
import PropTypes from 'prop-types';
import { useStyles } from '@hhs/ui/old-abyss/ui/styles/hooks/useStyles';
import { classNames } from '@abyss/web/tools/classNames';

import { baseStyles } from './PageHeaderTitle.styles';
// import { useFocusRef } from './useFocusRef';

export const PageHeaderTitle = ({ className, styles, children, ...props }) => {
  const classes = useStyles(baseStyles, null, styles);
  // const headingRef = useFocusRef();

  return (
    <h2 {...props} className={classNames(classes.title, className)}>
      {children}
    </h2>
  );
};

PageHeaderTitle.propTypes = {
  className: PropTypes.string,
  styles: PropTypes.shape({}),
  children: PropTypes.node,
};

PageHeaderTitle.defaultProps = {
  className: null,
  styles: null,
  children: null,
};
