import React, { useEffect } from 'react';
import { config } from '@abyss/web/tools/config';
import { event } from '@abyss/web/tools/event/event';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { useStyles } from '@hhs/ui/old-abyss/ui/styles/hooks/useStyles';

import { styles } from './FAQ.styles';
import { PageHeader } from '../../PageHeader';
import { PageLayout } from '../../PageLayout';

export const FAQ = () => {
  const classes = useStyles(styles);
  const router = useRouter();
  const location = router.getLocation();  

  useEffect(() => {
    event('FAQ_PAGE_VIEW');
  }, []);

  const handleGoBack = () => {
    if (location.state?.from) {  
      router.navigate(location.state.from);  
    } else {  
      router.navigate(-1);  
    }  
  };

  return (
    <React.Fragment>
      <PageHeader onBackClick={handleGoBack}>
        <PageHeader.Title>COVID-19 Provider Relief FAQs</PageHeader.Title>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis non tempor
        magna, id placerat lacus. Suspendisse sit amet auctor nulla. Suspendisse
        semper odio eu lectus hendrerit iaculis. Nullam malesuada id quam at
        hendrerit. Curabitur gravida augue augue, eget varius dolor posuere a.
        Ut scelerisque ipsum a dolor ultrices.
      </PageHeader>
      <PageLayout>
        <div className={classes.section}>
          <h2 className={classes.question}>
            What is COVID-19 Provider Relief? What are the details of the
            agreement?
          </h2>
          <p>
            The Coronavirus Aid, Relief, and Economic Security Act (CARES Act)
            has appropriated $100 billion for a Public Health and Social
            Services Emergency Fund (Relief Fund). The Department of Health &
            Human Services (HHS) is partnering with UnitedHealth Group (UHG) to
            deliver the funds to providers as quickly as possible through the
            COVID-19 Provider Relief initiative.
          </p>
          <p>
            Providers eligible for immediate relief funding that have an
            Automated Clearing House (ACH) account on file with UHG may receive
            an advance payment as early as this week, and will need to accept
            the terms of the payment within 30 days via a provider portal
            accessible via{' '}
            <a
              href={config('CORONAVIRUS_GOV_URL')}
              target="_blank"
              rel="noopener noreferrer"
            >
              hrsa.gov/provider-relief
            </a>
            . Other eligible providers will be able to access that portal at the
            end of this week to verify their information and enter their payment
            information, and will receive their funds as quickly as possible.
            The provider portal is scheduled to open on April 10, 2020, and will
            remain open for at least 180 days.
          </p>
        </div>
        <div className={classes.section}>
          <h2 className={classes.question}>
            Who qualifies for relief funding?
          </h2>
          <p>
            To be eligible for payment, providers must have experienced
            additional costs or lost revenue due to COVID-19. COVID-19-related
            costs are expenses incurred to prevent, prepare for, and respond to
            coronavirus, domestically or internationally. These include expenses
            associated with building or construction of temporary structures,
            leasing of properties, medical supplies and equipment including
            personal protective equipment and testing supplies, increased
            workforce and trainings, emergency operation centers, retrofitting
            facilities, and surge capacity. These costs also include lost
            revenues, but may not include any expenses or losses that have been
            reimbursed from other sources.
          </p>
        </div>
        <div className={classes.section}>
          <h2 className={classes.question}>Do all providers need to apply?</h2>
          <p>
            Yes, all providers need to complete the form on the COVID-19
            Provider Relief Fund portal via{' '}
            <a
              href={config('CORONAVIRUS_GOV_URL')}
              target="_blank"
              rel="noopener noreferrer"
            >
              hrsa.gov/provider-relief
            </a>{' '}
            to apply and/or attest to the Terms and Conditions. Some providers
            will receive advance payment, while others will need to complete the
            application form and provide banking information before they receive
            a payment.
          </p>
        </div>
        <div className={classes.section}>
          <h2 className={classes.question}>
            How are payment amounts calculated?
          </h2>
          <p>
            To expedite payments for initial distribution, HHS is using a
            formula based on 2019 Medicare spending. Only Medicare providers are
            eligible for a portion of the first, proactive disbursement.
            Targeted distributions will follow, focusing on providers in hotspot
            COVID-19 areas and providers of services with lower Medicare
            Fee-For-Service (FFS) reimbursement rates (e.g., children’s
            hospitals, pediatricians, OB/GYNs).
          </p>
        </div>
        <div className={classes.section}>
          <h2 className={classes.question}>When will payments be made?</h2>
          <p>
            Providers eligible for immediate relief funding that have an
            Automated Clearing House (ACH) account on file with UHG may receive
            advance payment as early as Thursday, April 9. Other eligible
            providers who have not yet received advance funding will be able to
            access a portal beginning April 10 via{' '}
            <a
              target="_blank"
              href={config('CORONAVIRUS_GOV_URL')}
              rel="noopener noreferrer"
            >
              hrsa.gov/provider-relief
            </a>{' '}
            to apply for funds. Payment processing time will be determined based
            on volume, and estimates will be posted to the portal.
          </p>
        </div>
        <div className={classes.section}>
          <h2 className={classes.question}>
            What actions do I need to take to receive payment? What information
            will be required?
          </h2>
          <p>Providers applying for relief funds will need to:</p>
          <ul className={classes.list}>
            <li>
              Attest that they are legally representing a physician, practice,
              or facility.
            </li>
            <li>
              Attest to the Terms and Condition for receiving funds, including
              conditions relating to their billing practices, maintaining
              auditable records, and reporting. Full conditions will be
              available on the portal via{' '}
              <a
                href={config('CORONAVIRUS_GOV_URL')}
                target="_blank"
                rel="noopener noreferrer"
              >
                hrsa.gov/provider-relief
              </a>
              .
            </li>
            <li>
              Provide and validate tax information such as Taxpayer
              Identification Numbers (TINs), National Provider Identifiers
              (NPIs), and IRS W-9 information.
            </li>
            <li>
              Provide and validate contact information for status and follow-up.
            </li>
            <li>
              Set up Automated Clearing House (ACH) payment information,
              including financial institution, routing number, and account
              number, through UnitedHealth Group’s Optum PayTM service. (Current
              Optum Pay customers may use their existing credentials).
            </li>
          </ul>
        </div>
        <div className={classes.section}>
          <h2 className={classes.question}>
            How can I speed up my application and payment process?
          </h2>
          <p>
            Providers who have not already set up Automated Clearing House (ACH)
            payment through UnitedHealth Group’s Optum PayTM service should do
            so as soon as possible via [link]. Should you be approved for relief
            funding, this will accelerate payment. Current Optum Pay customers
            may use their existing credentials.
          </p>
        </div>
        <div className={classes.section}>
          <h2 className={classes.question}>
            Where can I find more information and ask additional questions?
          </h2>
          <p>
            Additional detail, including Frequently Asked Questions (FAQs),
            guidance for completing the form, and more, will be available
            through the COVID-19 Provider Relief portal, accessible through{' '}
            <a
              href={config('CORONAVIRUS_GOV_URL')}
              target="_blank"
              rel="noopener noreferrer"
            >
              hrsa.gov/provider-relief
            </a>
            . The provider portal is scheduled to open on April 10, 2020.
          </p>
        </div>
        <div className={classes.disclaimer}>
          Disclamer Copy can go here - Lorem ipsum dolor sit amet, consectetur
          adipiscing elit. Duis non tempor magna, id placerat lacus. Suspendisse
          sit amet auctor nulla. Suspendisse semper odio eu lectus hendrerit
          iaculis. Nullam malesuada id quam at hendrerit. Curabitur gravida
          augue augue, eget varius dolor posuere a. Ut scelerisque ipsum a dolor
          ultrices, eget egestas orci dictum. Curabitur pulvinar odio eros, non
          finibus nunc molestie non. Phasellus massa lacus, tristique vitae orci
          in, aliquet commodo ligula. Cras sit amet accumsan turpis. Nunc
          gravida id sem eu dignissim. Duis maximus leo vitae ante egestas, id
          placerat purus ultrices. Phasellus aliquet elit ac malesuada ornare.
        </div>
      </PageLayout>
    </React.Fragment>
  );
};
