"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.baseStyles = void 0;

var baseStyles = function baseStyles(theme) {
  return {
    label: function label() {
      return {
        fontWeight: 'bold',
        fontSize: '14px',
        color: '#333333'
      };
    },
    error: {
      paddingTop: 5
    }
  };
};

exports.baseStyles = baseStyles;