"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.baseStyles = void 0;
var baseStyles = {
  title: {
    fontWeight: 'bold',
    fontSize: 16,
    marginBottom: 5
  }
};
exports.baseStyles = baseStyles;