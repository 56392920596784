"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createTheme = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _createPalette = require("./createPalette");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var colorPalettes = _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, (0, _createPalette.createPalette)('gray', '#7F7F7F')), (0, _createPalette.createPalette)('red', '#D0021C')), (0, _createPalette.createPalette)('orange', '#C05621')), (0, _createPalette.createPalette)('yellow', '#DB9C00')), (0, _createPalette.createPalette)('green', '#0F880C')), (0, _createPalette.createPalette)('teal', '#38B2AC')), (0, _createPalette.createPalette)('blue', '#196ECF')), (0, _createPalette.createPalette)('cyan', '#0BC5EA')), (0, _createPalette.createPalette)('purple', '#5E22D7')), (0, _createPalette.createPalette)('pink', '#D31973'));

var createTheme = function createTheme(theme) {
  var baseTheme = theme || {};
  return _objectSpread(_objectSpread({}, baseTheme), {}, {
    breakpoints: _objectSpread({
      sm: 480,
      md: 768,
      lg: 992,
      xl: 1280
    }, baseTheme.breakpoints),
    colors: _objectSpread(_objectSpread({
      primary: colorPalettes.blue550,
      secondary: colorPalettes.blue650,
      tertiary: colorPalettes.blue50,
      focus: colorPalettes.blue550,
      black: '#000000',
      white: '#FFFFFF',
      info: colorPalettes.blue550,
      success: colorPalettes.green550,
      error: colorPalettes.red550,
      warning: colorPalettes.yellow550
    }, colorPalettes), baseTheme.colors),
    fontSizes: _objectSpread({
      size100: '8px',
      size150: '9px',
      size200: '10px',
      size250: '11px',
      size300: '12px',
      size350: '13px',
      size400: '14px',
      size450: '15px',
      size500: '16px',
      size550: '17px',
      size600: '18px',
      size650: '19px',
      size700: '20px',
      size750: '22px',
      size800: '24px',
      size850: '28px',
      size900: '36px',
      size950: '48px',
      size1000: '64px'
    }, baseTheme.fontSizes),
    fontWeights: _objectSpread({
      normal: 400,
      medium: 600,
      bold: 700
    }, baseTheme.fontWeights)
  });
};

exports.createTheme = createTheme;