"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "createTheme", {
  enumerable: true,
  get: function get() {
    return _createTheme.createTheme;
  }
});
Object.defineProperty(exports, "createPalette", {
  enumerable: true,
  get: function get() {
    return _createPalette.createPalette;
  }
});

var _createTheme = require("./createTheme");

var _createPalette = require("./createPalette");