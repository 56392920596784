import React from 'react';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { useStyles } from '@hhs/ui/old-abyss/ui/styles/hooks/useStyles';
import { config } from '@abyss/web/tools/config';

import { styles } from './ReliefFundPaymentTermsAndConditions.styles';
import { PageHeader } from '../../PageHeader';
import { PageLayout } from '../../PageLayout';

export const ReliefFundPaymentTermsAndConditions = () => {
  const classes = useStyles(styles);
  const router = useRouter();
  const location = router.getLocation();  

  const handleGoBack = () => {
    if (location.state?.from) {  
      router.navigate(location.state.from);  
    } else {  
      router.navigate(-1);  
    }  
  };

  return (
    <React.Fragment>
      <PageHeader onBackClick={handleGoBack}>
        <PageHeader.Title>
          Provider Relief Fund Payment Terms and Conditions
        </PageHeader.Title>
      </PageHeader>
      <div className={classes.section}>
        <PageLayout>
          <div className={classes.section}>
            <b>Terms and Conditions</b>
          </div>
          <div className={classes.section}>
            If you receive a payment from funds appropriated in the Public
            Health and Social Services Emergency Fund for provider relief
            (“Relief Fund”) under Division B of Public Law 116-127 and retain
            that payment for at least 30 days without contacting HHS regarding
            remittance of those funds, you are deemed to have accepted the
            following Terms and Conditions. This is not an exhaustive list and
            you must comply with any other relevant statutes and regulations, as
            applicable.
          </div>
          <div className={classes.section}>
            Your commitment to full compliance with all Terms and Conditions is
            material to the Secretary’s decision to disburse these funds to you.
            Non-compliance with any Term or Condition is grounds for the
            Secretary to recoup some or all of the payment made from the Relief
            Fund.
          </div>
          <div className={classes.section}>
            These Terms and Conditions apply directly to the recipient of
            payment from the Relief Fund. In general, the requirements that
            apply to the recipient, also apply to subrecipients and contractors
            under grants, unless an exception is specified.
          </div>
        </PageLayout>
      </div>
      <hr className={classes.hr} />
      <div className={classes.section}>
        <PageLayout>
          <div className={classes.section}>
            <ul className={classes.list}>
              <li>
                The Payment means the funds received from the Public Health and
                Social Services Emergency Fund (“Relief Fund”). The Recipient
                means the healthcare provider, whether an individual or an
                entity, receiving the Payment.
              </li>
              <li>
                The Recipient certifies that it billed Medicare in 2019;
                currently provides diagnoses, testing, or care for individuals
                with possible or actual cases of COVID-19; is not currently
                terminated from participation in Medicare; is not currently
                excluded from participation in Medicare, Medicaid, and other
                Federal health care programs; and does not currently have
                Medicare billing privileges revoked.
              </li>
              <li>
                The Recipient certifies that the Payment will only be used to
                prevent, prepare for, and respond to coronavirus, and shall
                reimburse the Recipient only for health care related expenses or
                lost revenues that are attributable to coronavirus.
              </li>

              <li>
                The Recipient certifies that it will not use the Payment to
                reimburse expenses or losses that have been reimbursed from
                other sources or that other sources are obligated to reimburse.
              </li>
              <li>
                The Recipient shall submit reports as the Secretary determines
                are needed to ensure compliance with conditions that are imposed
                on this Payment, and such reports shall be in such form, with
                such content, as specified by the Secretary in future program
                instructions directed to all Recipients.
              </li>
              <li>
                Not later than 10 days after the end of each calendar quarter,
                any Recipient that is an entity receiving more than $150,000
                total in funds under the Coronavirus Aid, Relief, and Economics
                Security Act (P.L. 116-136), the Coronavirus Preparedness and
                Response Supplemental Appropriations Act (P.L. 116-123), the
                Families First Coronavirus Response Act (P.L. 116-127), or any
                other Act primarily making appropriations for the coronavirus
                response and related activities, shall submit to the Secretary
                and the Pandemic Response Accountability Committee a report.
                This report shall contain: the total amount of funds received
                from HHS under one of the foregoing enumerated Acts; the amount
                of funds received that were expended or obligated for reach
                project or activity; a detailed list of all projects or
                activities for which large covered funds were expended or
                obligated, including: the name and description of the project or
                activity, and the estimated number of jobs created or retained
                by the project or activity, where applicable; and detailed
                information on any level of sub-contracts or subgrants awarded
                by the covered recipient or its subcontractors or subgrantees,
                to include the data elements required to comply with the Federal
                Funding Accountability and Transparency Act of 2006 allowing
                aggregate reporting on awards below $50,000 or to individuals,
                as prescribed by the Director of the Office of Management and
                Budget.
              </li>
              <li>
                The Recipient shall maintain appropriate records and cost
                documentation including, as applicable, documentation required
                by 45 CFR § 75.302 – Financial management and 45 CFR § 75.361
                through 75.365 – Record Retention and Access, and other
                information required by future program instructions to
                substantiate the reimbursement of costs under this award. The
                Recipient shall promptly submit copies of such records and cost
                documentation upon the request of the Secretary, and Recipient
                agrees to fully cooperate in all audits the Secretary, Inspector
                General, or Pandemic Response Accountability Committee conducts
                to ensure compliance with these Terms and Conditions.
              </li>
              <li>
                The Secretary has concluded that the COVID-19 public health
                emergency has caused many healthcare providers to have capacity
                constraints. As a result, patients that would ordinarily be able
                to choose to receive all care from in-network healthcare
                providers may no longer be able to receive such care in-network.
                Accordingly, for all care for a possible or actual case of
                COVID-19, Recipient certifies that it will not seek to collect
                from the patient out-of-pocket expenses in an amount greater
                than what the patient would have otherwise been required to pay
                if the care had been provided by an in-network Recipient.
              </li>
            </ul>
          </div>
        </PageLayout>
      </div>
      <div className={classes.section}>
        <hr className={classes.hr} />
      </div>
      <PageLayout>
        <div className={classes.section}>
          The following statutory provisions also apply:
        </div>
        <div className={classes.section}>
          <b>General Provisions in FY 2020 Consolidated Appropriation</b>
        </div>

        <div className={classes.section}>
          <b>SEC. 202. Executive Pay.</b>
          <br />
          None of the funds appropriated in this title shall be used to pay the
          salary of an individual, through a grant or other extramural
          mechanism, at a rate in excess of Executive Level II.
        </div>

        <div className={classes.section}>
          <b>SEC. 210. Funding Prohibition for Gun Control Advocacy.</b>
          <br />
          None of the funds made available in this title may be used, in whole
          or in part, to advocate or promote gun control.
        </div>

        <div className={classes.section}>
          <b>SEC. 503. Lobbying</b>
        </div>

        <div className={classes.section}>
          (a) No part of any appropriation contained in this Act or transferred
          pursuant to section 4002 of Public Law 111–148 shall be used, other
          than for normal and recognized executive-legislative relationships,
          for publicity or propaganda purposes, for the preparation,
          distribution, or use of any kit, pamphlet, booklet, publication,
          electronic communication, radio, television, or video presentation
          designed to support or defeat the enactment of legislation before the
          Congress or any State or local legislature or legislative body, except
          in presentation to the Congress or any State or local legislature
          itself, or designed to support or defeat any proposed or pending
          regulation, administrative action, or order issued by the executive
          branch of any State or local government, except in presentation to the
          executive branch of any State or local government itself.
        </div>

        <div className={classes.section}>
          (b) No part of any appropriation contained in this Act or transferred
          pursuant to section 4002 of Public Law 111–148 shall be used to pay
          the salary or expenses of any grant or contract recipient, or agent
          acting for such recipient, related to any activity designed to
          influence the enactment of legislation, appropriations, regulation,
          administrative action, or Executive order proposed or pending before
          the Congress or any State government, State legislature or local
          legislature or legislative body, other than for normal and recognized
          executive-legislative relationships or participation by an agency or
          officer of a State, local or tribal government in policymaking and
          administrative processes within the executive branch of that
          government.
        </div>

        <div className={classes.section}>
          (c) The prohibitions in subsections (a) and (b) shall include any
          activity to advocate or promote any proposed, pending or future
          Federal, State or local tax increase, or any proposed, pending, or
          future requirement or restriction on any legal consumer product,
          including its sale or marketing, including but not limited to the
          advocacy or promotion of gun control.
        </div>

        <div className={classes.section}>
          SEC. 506. Prohibits Use of Federal Funds for Abortions.
        </div>

        <div className={classes.section}>
          (a) None of the funds appropriated in this Act, and none of the funds
          in any trust fund to which funds are appropriated in this Act, shall
          be expended for any abortion.
        </div>

        <div className={classes.section}>
          (b) None of the funds appropriated in this Act, and none of the funds
          in any trust fund to which funds are appropriated in this Act, shall
          be expended for health benefits coverage that includes coverage of
          abortion.
        </div>

        <div className={classes.section}>
          (c) The term ‘‘health benefits coverage’’ means the package of
          services covered by a managed care provider or organization pursuant
          to a contract or other arrangement.
        </div>

        <div className={classes.section}>
          <b>SEC. 507 Limitations on Abortion Funding Prohibition</b>
        </div>

        <div className={classes.section}>
          (a) The limitations established in the preceding section shall not
          apply to an abortion—
        </div>

        <div className={classes.section}>
          (1) if the pregnancy is the result of an act of rape or incest; or
        </div>

        <div className={classes.section}>
          (2) in the case where a woman suffers from a physical disorder,
          physical injury, or physical illness, including a life-endangering
          physical condition caused by or arising from the pregnancy itself,
          that would, as certified by a physician, place the woman in danger of
          death unless an abortion is performed.
        </div>

        <div className={classes.section}>
          (b) Nothing in the preceding section shall be construed as prohibiting
          the expenditure by a State, locality, entity, or private person of
          State, local, or private funds (other than a State’s or locality’s
          contribution of Medicaid matching funds).
        </div>

        <div className={classes.section}>
          (c) Nothing in the preceding section shall be construed as restricting
          the ability of any managed care provider from offering abortion
          coverage or the ability of a State or locality to contract separately
          with such a provider for such coverage with State funds (other than a
          State’s or locality’s contribution of Medicaid matching funds).
        </div>

        <div className={classes.section}>
          (d)(1) None of the funds made available in this Act may be made
          available to a Federal agency or program, or to a State or local
          government, if such agency, program, or government subjects any
          institutional or individual health care entity to discrimination on
          the basis that the health care entity does not provide, pay for,
          provide coverage of, or refer for abortions.
        </div>

        <div className={classes.section}>
          (2) In this subsection, the term "health care entity" includes an
          individual physician or other health care professional, a hospital, a
          provider-sponsored organization, a health maintenance organization, a
          health insurance plan, or any other kind of health care facility,
          organization, or plan.
        </div>

        <div className={classes.section}>
          Prohibits Use of Funds for Embryo Research
        </div>

        <div className={classes.section}>
          <b>SEC. 508. Prohibits Use of Funds for Embryo Research</b>
        </div>

        <div className={classes.section}>
          (a) None of the funds made available in this Act may be used for—
        </div>

        <div className={classes.section}>
          (1) the creation of a human embryo or embryos for research purposes;
          or
        </div>

        <div className={classes.section}>
          (2) research in which a human embryo or embryos are destroyed,
          discarded, or knowingly subjected to risk of injury or death greater
          than that allowed for research on fetuses in utero under 45 CFR
          46.204(b) and section 498(b) of the Public Health Service Act (42
          U.S.C. 289g(b)).
        </div>

        <div className={classes.section}>
          (b) For purposes of this section, the term ‘‘human embryo or embryos’’
          includes any organism, not protected as a human subject under 45 CFR
          46 as of the date of the enactment of this Act, that is derived by
          fertilization, parthenogenesis, cloning, or any other means from one
          or more human gametes or human diploid cells.
        </div>

        <div className={classes.section}>
          <b>
            SEC. 509. Prohibits Promotion of Legalization of Controlled
            Substances
          </b>
        </div>

        <div className={classes.section}>
          (a) None of the funds made available in this Act may be used for any
          activity that promotes the legalization of any drug or other substance
          included in schedule I of the schedules of controlled substances
          established by section 202 of the Controlled Substances Act except for
          normal and recognized executive-congressional communications.
        </div>

        <div className={classes.section}>
          (b) The limitation in subsection (a) shall not apply when there is
          significant medical evidence of a therapeutic advantage to the use of
          such drug or other substance or that federally sponsored clinical
          trials are being conducted to determine therapeutic advantage.
        </div>

        <div className={classes.section}>
          SEC. 515. (b) Prohibits Asking Candidates for Federal Scientific
          Advisory Committees Their Political Affiliations; Prohibits
          Distribution of Intentionally False Information
        </div>

        <div className={classes.section}>
          (b) None of the funds made available in this Act may be used to
          disseminate information that is deliberately false or misleading.
        </div>

        <div className={classes.section}>
          <b>SEC. 520. Pornography.</b>
        </div>

        <div className={classes.section}>
          (a) None of the funds made available in this Act may be used to
          maintain or establish a computer network unless such network blocks
          the viewing, downloading, and exchanging of pornography.
        </div>

        <div className={classes.section}>
          (b) Nothing in subsection (a) shall limit the use of funds necessary
          for any Federal, State, tribal, or local law enforcement agency or any
          other entity carrying out criminal investigations, prosecution, or
          adjudication activities.
        </div>
        <div className={classes.section}>
          <b>
            SEC. 521. Prohibits Funding ACORN or Its Affiliates or Subsidiaries.
          </b>
          <br />
          None of the funds made available under this or any other Act, or any
          prior Appropriations Act, may be provided to the Association of
          Community Organizations for Reform Now (ACORN), or any of its
          affiliates, subsidiaries, allied organizations, or successors.
        </div>
        <div className={classes.section}>
          <b>
            SEC. 527. Prohibits Federal Funding for Needle Exchange Except in
            Limited Circumstances.
          </b>
          <br />
          Notwithstanding any other provision of this Act, no funds appropriated
          in this Act shall be used to purchase sterile needles or syringes for
          the hypodermic injection of any illegal drug: Provided, That such
          limitation does not apply to the use of funds for elements of a
          program other than making such purchases if the relevant State or
          local health department, in consultation with the Centers for Disease
          Control and Prevention, determines that the State or local
          jurisdiction, as applicable, is experiencing, or is at risk for, a
          significant increase in hepatitis infections or an HIV outbreak due to
          injection drug use, and such program is operating in accordance with
          State and local law.
        </div>
        <div className={classes.section}>
          <b>Government-wide General Provisions</b>
        </div>
        <div className={classes.section}>
          <b>SEC. 718. Propaganda.</b>
          <br />
          No part of any appropriation contained in this or any other Act shall
          be used directly or indirectly, including by private contractor, for
          publicity or propaganda purposes within the United States not
          heretofore authorized by the Congress.
        </div>
        <div className={classes.section}>
          <b>SEC. 732. Privacy Act.</b>
          <br />
          None of the funds made available in this Act may be used in
          contravention of section 552a of title 5, United States Code
          (popularly known as the Privacy Act), and regulations implementing
          that section.
        </div>
        <div className={classes.section}>
          <b>SEC. 742. Confidentiality Agreements.</b>
          <br />
          (a) None of the funds appropriated or otherwise made available by this
          or any other Act may be available for a contract, grant, or
          cooperative agreement with an entity that requires employees or
          contractors of such entity seeking to report fraud, waste, or abuse to
          sign internal confidentiality agreements or statements prohibiting or
          otherwise restricting such employees or contactors from lawfully
          reporting such waste, fraud, or abuse to a designated investigative or
          law enforcement representative of a Federal department or agency
          authorized to receive such information.
        </div>
        <div className={classes.section}>
          (b) The limitation in subsection (a) shall not contravene requirements
          applicable to Standard Form 312, Form 4414, or any other form issued
          by a Federal department or agency governing the nondisclosure of
          classified information.
        </div>
        <div className={classes.section}>
          <b>SEC. 743. Nondisclosure Agreements</b>
        </div>
        <div className={classes.section}>
          (a) No funds appropriated in this or any other Act may be used to
          implement or enforce the agreements in Standard Forms 312 and 4414 of
          the Government or any other nondisclosure policy, form, or agreement
          if such policy, form, or agreement does not contain the following
          provisions: “These provisions are consistent with and do not
          supersede, conflict with, or otherwise alter the employee obligations,
          rights, or liabilities created by existing statute or Executive order
          relating to (1) classified information, (2) communications to
          Congress, (3) the reporting to an Inspector General of a violation of
          any law, rule, or regulation, or mismanagement, a gross waste of
          funds, an abuse of authority, or a substantial and specific danger to
          public health or safety, or (4) any other whistleblower protection.
          The definitions, requirements, obligations, rights, sanctions, and
          liabilities created by controlling Executive orders and statutory
          provisions are incorporated into this SEC. 743. (a) No funds
          appropriated in this or any other Act may be used to implement or
          enforce the agreements in Standard Forms 312 and 4414 of the
          Government or any other nondisclosure policy, form, or agreement if
          such policy, form, or agreement does not contain the following
          provisions: “These provisions are consistent with and do not
          supersede, conflict with, or otherwise alter the employee obligations,
          rights, or liabilities created by existing statute or Executive order
          relating to (1) classified information, (2) communications to
          Congress, (3) the reporting to an Inspector General of a violation of
          any law, rule, or regulation, or mismanagement, a gross waste of
          funds, an abuse of authority, or a substantial and specific danger to
          public health or safety, or (4) any other whistleblower protection.
          The definitions, requirements, obligations, rights, sanctions, and
          liabilities created by controlling Executive orders and statutory
          provisions are incorporated into this agreement and are controlling.”:
          Provided, That notwithstanding the preceding provision of this
          section, a nondisclosure policy form or agreement that is to be
          executed by a person connected with the conduct of an intelligence or
          intelligence-related activity, other than an employee or officer of
          the United States Government, may contain provisions appropriate to
          the particular activity for which such document is to be used. Such
          form or agreement shall, at a minimum, require that the person will
          not disclose any classified information received in the course of such
          activity unless specifically authorized to do so by the United States
          Government.
        </div>
        <div className={classes.section}>
          Such nondisclosure forms shall also make it clear that they do not bar
          disclosures to Congress, or to an authorized official of an executive
          agency or the Department of Justice, that are essential to reporting a
          substantial violation of law.
        </div>
        <div className={classes.section}>
          (b) A nondisclosure agreement may continue to be implemented and
          enforced notwithstanding subsection (a) if it complies with the
          requirements for such agreement that were in effect when the agreement
          was entered into.
        </div>
        <div className={classes.section}>
          (c) No funds appropriated in this or any other Act may be used to
          implement or enforce any agreement entered into during fiscal year
          2014 which does not contain substantially similar language to that
          required in subsection (a).
        </div>
        <div className={classes.section}>
          <b>SEC. 744. Unpaid Federal Tax Liability.</b>
          <br />
          None of the funds made available by this or any other Act may be used
          to enter into a contract, memorandum of understanding, or cooperative
          agreement with, make a grant to, or provide a loan or loan guarantee
          to, any corporation that has any unpaid Federal tax liability that has
          been assessed, for which all judicial and administrative remedies have
          been exhausted or have lapsed, and that is not being paid in a timely
          manner pursuant to an agreement with the authority responsible for
          collecting the tax liability, where the awarding agency is aware of
          the unpaid tax liability, unless a Federal agency has considered
          suspension or debarment of the corporation and has made a
          determination that this further action is not necessary to protect the
          interests of the Government.
        </div>
        <div className={classes.section}>
          <b>SEC. 745. Criminal Felony Limitation.</b>
          <br />
          None of the funds made available by this or any other Act may be used
          to enter into a contract, memorandum of understanding, or cooperative
          agreement with, make a grant to, or provide a loan or loan guarantee
          to, any corporation that was convicted of a felony criminal violation
          under any Federal law within the preceding 24 months, where the
          awarding agency is aware of the conviction, unless a Federal agency
          has considered suspension or debarment of the corporation and has made
          a determination that this further action is not necessary to protect
          the interests of the Government.
        </div>
        <div className={classes.section}>
          <b>Other Appropriations Provisions</b>
        </div>
        <div className={classes.section}>
          <b>42 U.S.C. 289d note</b> No funds appropriated under this Act or
          subsequent Departments of Labor, Health and Human Services, and
          Education, and Related Agencies Appropriations Acts shall be used by
          the National Institutes of Health, or any other Federal agency, or
          recipient of Federal funds on any project that entails the capture or
          procurement of chimpanzees obtained from the wild. For purposes of
          this section, the term ‘recipient of Federal funds’ includes private
          citizens, corporations, or other research institutions located outside
          of the United States that are recipients of Federal funds.
        </div>
        <div className={classes.section}>
          <b>Other Statutory Provisions</b>
        </div>
        <div className={classes.section}>
          <b>Trafficking in Persons</b>
          <br />
          This award is subject to the requirements of Section 106 (g) of the
          Trafficking Victims Protection Act of 2000, as amended (22 U.S.C.
          7104)
        </div>
        <div className={classes.section}>
          <b>
            a. Provisions applicable to a recipient that is a private entity.
          </b>
          <br />
          1. You as the recipient, your employees, subrecipients under this
          award, and subrecipients' employees may not
          <div className={classes.indent}>
            i. Engage in severe forms of trafficking in persons during the
            period of time that the award is in effect;
            <br />
            ii. Procure a commercial sex act during the period of time that the
            award is in effect; or
            <br />
            iii. Use forced labor in the performance of the award or subawards
            under the award.
          </div>
          2. We as the Federal awarding agency may unilaterally terminate this
          award, without penalty, if you or a subrecipient that is a private
          entity –
          <div className={classes.indent}>
            i. Is determined to have violated a prohibition in paragraph a.1 of
            this award term; or
            <br />
            ii. Has an employee who is determined by the agency official
            authorized to terminate the award to have violated a prohibition in
            paragraph a.1 of this award term through conduct that is either-
          </div>
          A. Associated with performance under this award; or
          <br />
          B. Imputed to you or the subrecipient using the standards and due
          process for imputing the conduct of an individual to an organization
          that are provided in 2 CFR part 180, "OMB Guidelines to Agencies on
          Governmentwide Debarment and Suspension (Nonprocurement)," as
          implemented by our agency at 2 CFR part 376.
        </div>
        <div className={classes.section}>
          <b>
            b. Provision applicable to a recipient other than a private entity.
          </b>
          We as the Federal awarding agency may unilaterally terminate this
          award, without penalty, if a subrecipient that is a private entity-
          <br /> 1. Is determined to have violated an applicable prohibition in
          paragraph a.1 of this award term; or
          <br />
          2. Has an employee who is determined by the agency official authorized
          to terminate the award to have violated an applicable prohibition in
          paragraph a.1 of this award term through conduct that is either
          <br />
          <div className={classes.indent}>
            i. Associated with performance under this award; or
            <br />
            ii. Imputed to the subrecipient using the standards and due process
            for imputing the conduct of an individual to an organization that
            are provided in 2 CFR part 180, "OMB Guidelines to Agencies on
            Governmentwide Debarment and Suspension (Nonprocurement)," as
            implemented by our agency at 2 CFR part 376
          </div>
        </div>
        <div className={classes.section}>
          <b>c. Provisions applicable to any recipient.</b>
          <br />
          1. You must inform us immediately of any information you receive from
          any source alleging a violation of a prohibition in paragraph a.1 of
          this award term
          <br />
          2. Our right to terminate unilaterally that is described in paragraph
          a.2 or b of this section:
          <div className={classes.indent}>
            i. Implements section 106(g) of the Trafficking Victims Protection
            Act of 2000 (TVPA), as amended (22 U.S.C. 7104(g)), and
            <br />
            ii. Is in addition to all other remedies for noncompliance that are
            available to us under this award.
          </div>
          3. You must include the requirements of paragraph a.1 of this award
          term in any subaward you make to a private entity.
        </div>
        <div className={classes.section}>
          <b>d. Definitions. For purposes of this award term:</b>
          <br />
          1. "Employee" means either:
          <br />
          i. An individual employed by you or a subrecipient who is engaged in
          the performance of the project or program under this award; or
          <br />
          ii. Another person engaged in the performance of the project or
          program under this award and not compensated by you including, but not
          limited to, a volunteer or individual whose services are contributed
          by a third party as an in-kind contribution toward cost sharing or
          matching requirements.
          <br />
          2. "Forced labor" means labor obtained by any of the following
          methods: the recruitment, harboring, transportation, provision, or
          obtaining of a person for labor or services, through the use of force,
          fraud, or coercion for the purpose of subjection to involuntary
          servitude, peonage, debt bondage, or slavery.
          <br />
          3. "Private entity":
          <br />
          i. Means any entity other than a State, local government, Indian
          tribe, or foreign public entity, as those terms are defined in 2 CFR
          175.25.
          <br />
          ii. Includes:
          <br />
          A. A nonprofit organization, including any nonprofit institution of
          higher education, hospital, or tribal organization other than one
          included in the definition of Indian tribe at 2 CFR 175.25(b).
          <br />B A for-profit organization.
          <br />
          4. “Severe forms of trafficking in persons," "commercial sex act," and
          "coercion" have the meanings given at section 103 of the TVPA, as
          amended (22 U.S.C. 7102)
        </div>
        <div className={classes.section}>
          <b>Whistleblower Protections</b>
          <br />
          You are hereby given notice that the 48 CFR section 3.908,
          implementing section 828, entitled “Pilot Program for Enhancement of
          Contractor Employee Whistleblower protections,” of the National
          Defense Authorization Act (NDAA) for Fiscal Year (FY) 2013 (Pub. L.
          112-239, enacted January 2, 2013) applies to this award.
        </div>
        <div className={classes.section}>
          <b>Human Subjects Protections</b>
          <br />
          If any activities under this project will involve human subjects in
          any research activities, you must provide satisfactory assurance of
          compliance with the participant protection requirement of the HHS/OASH
          Office of Human Research Protection (OHRP) prior to implementation of
          those research components. This assurance should be submitted to the
          OHRP in accordance with the appropriate regulations.
        </div>
        <div className={classes.section}>
          <b>Fraud, Abuse and Waste:</b>
          <br />
          The HHS Inspector General accepts tips and complaints from all sources
          about potential fraud, waste, abuse, and mismanagement in Department
          of Health and Human Services' programs.
          <br />
          Your information will be reviewed promptly by a professional staff
          member. Due to the high volume of information that they receive, they
          are unable to reply to submissions. You may reach the OIG through
          various channels.
          <br />
          Internet:{' '}
          <a
            href={config('FORMS_OIG_HHS_URL')}
            rel="noopener noreferrer"
            target="_blank"
          >
            {config('FORMS_OIG_HHS_URL')}
          </a>
          <br />
          Phone: 1-800-HHS-TIPS (1-800-447-8477)
          <br />
          Mail: US Department of Health and Human Services
          <br />
          <div className={classes.indent}>
            Office of Inspector General
            <br />
            ATTN: OIG HOTLINE OPERATIONS
            <br />
            PO Box 23489
            <br />
            Washington, DC 20026
          </div>
          For additional information visit{' '}
          <a
            href={config('OIG_HHS_URL')}
            rel="noopener noreferrer"
            target="_blank"
          >
            {config('OIG_HHS_URL')}
          </a>
        </div>
      </PageLayout>
    </React.Fragment>
  );
};
