export const baseStyles = theme => {
  return {
    timelineStep: ({ color }) => {
      return {
        position: 'relative',
        textAlign: 'center',
        flex: '1 1 0%',

        '&:before': {
          content: '""',
          display: 'block',
          height: 22,
          width: 22,
          zIndex: 2,
          position: 'relative',
          background: 'white',
          margin: '0 auto',
          borderRadius: '50%',
          borderWidth: 3,
          borderStyle: 'solid',
          borderColor: color,
          borderImage: 'initial',
        },

        '&:after': {
          content: '""',
          display: 'block',
          position: 'absolute',
          top: 10,
          left: 0,
          right: 0,
          zIndex: 1,
          borderWidth: '1.5px',
          borderStyle: 'solid',
          borderColor: color,
          borderImage: 'initial',
        },

        '&:first-child:after': {
          left: '50%',
        },

        '&:last-child:after': {
          right: '50%',
        },
      };
    },
    timelineStepContent: {
      fontSize: 14,
      lineHeight: '14px',
      marginTop: 5,
    },
  };
};
