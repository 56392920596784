"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.userAgent = void 0;
var nav = typeof navigator !== 'undefined' ? navigator : {};
var userAgent = {
  isChrome: /webkit\W.*(chrome|chromium)\W/i.test(nav.userAgent),
  isFirefox: /mozilla.*\Wfirefox\W/i.test(nav.userAgent),
  isIE: /\bTrident\/7.0\b/.test(nav.userAgent),
  isEdge: /\bTrident\b/.test(nav.userAgent),
  isSafari: /webkit\W(?!.*chrome).*safari\W/i.test(nav.userAgent),
  isMac: /Mac/.test(nav.appVersion),
  isWindows: /Win/.test(nav.appVersion)
};
exports.userAgent = userAgent;