"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.loadSagas = exports.LOAD_SAGAS = void 0;
var LOAD_SAGAS = '@hhs/ui/old-abyss/ui/saga/LOAD_SAGAS';
exports.LOAD_SAGAS = LOAD_SAGAS;

var loadSagas = function loadSagas(sagas) {
  return {
    type: LOAD_SAGAS,
    payload: sagas
  };
};

exports.loadSagas = loadSagas;