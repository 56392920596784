"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.baseStyles = void 0;

var baseStyles = function baseStyles() {
  return {
    content: {
      textAlign: 'left',
      fontWeight: 'normal',
      lineHeight: 1,
      padding: 10
    }
  };
};

exports.baseStyles = baseStyles;