import React, { useEffect, useState } from 'react';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { useStyles } from '@hhs/ui/old-abyss/ui/styles/hooks/useStyles';
import { styles } from './TermsOfUse.styles';
import { PageHeader } from '../../PageHeader';
import { PageLayout } from '../../PageLayout';

export const TermsOfUse = () => {
  const classes = useStyles(styles);
  const router = useRouter();
  const location = router.getLocation();
  const [hourOfOperationText, setHourOfOperationText] = useState('');  

  const handleGoBack = () => {
    const url = window.location.pathname.split('/');
    if ((url[1] === 'attestation' || url[1] === 'registration' || url[1] === 'docusign') &&
      (url[2] === 'contact-us' || url[2] === 'accessibility-statement' || url[2] === 'terms-of-use' || url[2] === 'privacy-policy')) {
      router.navigate('/');
      return;
    }
    if (window.location.pathname.split('/').length > 2) {  
      router.navigate(-1);  
    } else {  
      //router.navigate(-1);  
      router.navigate('/');
    }    
  };

  useEffect(() => {
    const urlToSetHours = window.location.pathname.split('/');
    var hooText = '';
    switch (urlToSetHours[1]) {
      case 'attestation':
        hooText = 'Hours of operation are 8 a.m. to 4 p.m. Central Time, Monday through Friday.';
        break;
      case 'dashboard':
        hooText = 'Hours of operation are 8 a.m. to 4 p.m. Central Time, Monday through Friday.';
        break;
      case 'registration':
        hooText = 'Hours of operation are 8 a.m. to 4 p.m. Central Time, Monday through Friday.';
        break;
      case 'member':
        hooText = 'Hours of operation are 8 a.m. to 4 p.m. Central Time, Monday through Friday.';
        break; 
      default:
        hooText = 'Hours of operation are 8 a.m. to 4 p.m. Central Time, Monday through Friday.';
        break;                           
    }
    const hooDisplayText = `To contact us regarding these Terms or the operation of the website
    itself, contact us at 866-569-3522 for TTY dial 711. ` +  hooText;
    setHourOfOperationText(hooDisplayText);   
  }, []);   

  return (
    <React.Fragment>
      <PageHeader onBackClick={handleGoBack}>
        <h1 className={classes.pageHeader}>Terms Of Use</h1>
      </PageHeader>
      <PageLayout>
        <div className={classes.section}>
          <h2>IMPORTANT NOTE REGARDING WEBSITE CONTENT</h2>
        </div>
        <div className={classes.section}>
          The information and content (collectively, “Content”) on this website
          is for your general educational information only. The Content on this
          website should not be considered financial advice, legal advice or tax
          advice.
        </div>
        <div className={classes.section}>
          This website is intended for a United States audience. If you live
          outside the U.S., you may see information on this website about
          products that are not available or authorized in your country.
        </div>
        <div className={classes.section}>
          <h2>Agreement and Terms</h2>
        </div>
        <div className={classes.section}>
          These website Terms of Use (“Terms”) describe the rules for using this
          website. These Terms constitute a legally binding agreement between
          you, the person using this website, and UnitedHealth Group. If you are
          helping another person use this website, these Terms constitute a
          legally binding agreement between both the helper and the person being
          helped and UnitedHealth Group. Terms such as “we”, “our”, or “Company”
          refer to UnitedHealth Group and its current and future affiliated
          entities.
        </div>
        <div className={classes.section}>
          By using this website, you agree to the most-recent Terms as well as
          the most-recent version of our Website Privacy Policy, which is
          attached in the PDF below. We may change these Terms at any time, and
          such changes will be posted on this or a similar page of this website.
          It is your responsibility to review these Terms each time you use this
          website. By continuing to use this website, you consent to any changes
          to our Terms.
        </div>
        <div className={classes.section}>
          If you enter into any other agreement with the Company, for example a
          “EPS EFT Enrollment Authorization Agreement,” or if you wish to enroll
          in the services offered through the Site, then these Terms are in
          addition to the terms of such other agreement, and in the event of any
          conflict the terms of the other agreement control. Neither entering
          into this agreement, nor visiting this website, nor any of these
          Terms, guarantees that you are eligible to enroll in any program or
          receive an account offered through this website.
        </div>
        <div className={classes.section}>
          <h2>License to Use this Website and Content Ownership</h2>
        </div>
        <div className={classes.section}>
          Subject to these Terms, the Company grants you a personal,
          nontransferable, nonexclusive, revocable, limited license to view the
          Content on the website for the sole purpose of collecting information
          regarding our products and related activities such as, if permitted on
          this website, applying to enroll in a program or service, or open an
          account. You may also print a reasonable number of copies of the
          Content for your personal use, but in such case you must reproduce all
          proprietary copyright and trademark notices. All rights, title and
          interest in and to the website, including the Content, and all
          intellectual property rights, including all copyright, trademark,
          patent and trade secret rights therein shall remain with the Company
          and our licensors and vendors, and no ownership interest is
          transferred to you or any other entity by virtue of making the Content
          available on the website, granting the foregoing licenses or entering
          into this Agreement.
        </div>
        <div className={classes.section}>
          In the event you choose to provide us with any feedback, suggestions,
          or similar communications, all such messages (collectively, “Feedback
          Messages”) will be considered non-personal, non-confidential (except
          for personal information as described in our Website Privacy Policy)
          and nonproprietary. You hereby grant the Company a perpetual,
          sublicensable, assignable, unrestricted, worldwide, royalty-free,
          irrevocable license to use, reproduce, display, perform, practice,
          modify, create derivative or collective works, transmit and distribute
          your Feedback Messages, in whole or in part, and including all
          intellectual property rights therein.
        </div>
        <div className={classes.section}>
          Some websites operated by the Company include trademarks or logos
          belonging to other third-party licensors and are used pursuant to an
          agreement with such third parties.
        </div>
        <div className={classes.section}>
          We may terminate this license at any time for any reason. If you
          breach any of these Terms, your license to the Content terminates
          immediately. Upon the termination of this license you must stop using
          this website, including all Content, and return or destroy all copies,
          including electronic copies, of the Content in your possession or
          control.
        </div>
        <div className={classes.section}>
          <h2>Restrictions on Use of this Website</h2>
        </div>
        <div className={classes.section}>You agree:</div>
        <div className={classes.section}>
          <ul className={classes.list}>
            <li>
              not to use this website or Content in any way not explicitly
              permitted by these Terms or the text of the website itself;
            </li>{' '}
            <li>
              not to copy, modify or create derivative works involving the
              Content, except you may print a
            </li>{' '}
            <li>reasonable number of copies for your personal use;</li>{' '}
            <li>
              not to misrepresent your identity or provide us with any false
              information in any information-collection portion of this website,
              such as a registration or enrollment application page;
            </li>{' '}
            <li>
              not to take any action intended to interfere with the operation of
              this website;
            </li>{' '}
            <li>
              not to access or attempt to access any portion of this website to
              which you have not been explicitly granted access;
            </li>{' '}
            <li>
              not to share any password assigned to you with any third parties
              or use any password granted to a third party;
            </li>{' '}
            <li>
              not to directly or indirectly authorize anyone else to take
              actions prohibited in this section;
            </li>{' '}
            <li>
              to comply with all applicable laws and regulations while using
              this website or the Content. You represent and warrant that you
              are at least 13 years of age, and that if you are between 13 and
              18 years of age a parent and/or guardian agrees to these Terms on
              your behalf.
            </li>
          </ul>
        </div>
        <div className={classes.section}>
          <h2>Posting Messages, Comments or Content</h2>
        </div>
        <div className={classes.section}>
          Certain portions of this website may be configured to permit users to
          post messages, comments, or other content. Any such content is only
          the opinion of the poster, is no substitute for your own research, and
          should not be relied upon for any purpose. You agree not to post any
          content:
        </div>
        <div className={classes.section}>
          <ul className={classes.list}>
            <li>
              which is profane, libelous, defamatory, obscene, pornographic,
              indecent, lewd, harassing, threatening, harmful, invasive of
              privacy or publicity rights, abusive, inflammatory, or otherwise
              objectionable; or
            </li>{' '}
            <li>
              where the transmission of which could violate, or facilitate the
              violation of, any applicable law, regulation, or intellectual
              property rights.
            </li>{' '}
          </ul>
        </div>
        <div className={classes.section}>
          You are solely responsible for the content of any postings you submit
          and the Company assumes no responsibility or liability for any content
          submitted by you or any other site visitor. We may, but are not
          obligated to, restrict or remove any and all content from a message
          that we determine in our sole discretion violates these Terms or is
          otherwise harmful to us, our customers, or any third party. We reserve
          the right to remove the content you provide at any time, but you
          understand that we may preserve and access a back-up copy, and we may
          disclose the content if required to do so by law or in a good faith
          belief that such access, preservation, or disclosure is required by
          law or in the best interests of the Company.
        </div>
        <div className={classes.section}>
          Any content you submit is for non-commercial use only. Any content you
          submit will be routed through the Internet and you understand and
          acknowledge that you have no expectation of privacy with regard to any
          content you submit. Never assume that you are anonymous and cannot be
          identified by your posts.
        </div>
        <div className={classes.section}>
          If you do post content or submit material, and unless we indicate
          otherwise, you grant the Company a nonexclusive, royalty-free,
          perpetual, irrevocable, and fully sublicensable right to use,
          reproduce, modify, adapt, publish, translate, create derivative works
          from, distribute, and display such content throughout the world in any
          media. You grant the Company and any of its sublicensees the right to
          use the name that you submit in connection with such content, if they
          choose. You represent and warrant that you own or otherwise control
          all of the rights to the content that you post; that the content is
          accurate; that use of the content you supply does not violate these
          Terms and will not cause injury to any person or entity; and that you
          will indemnify the Company or its affiliates for all claims resulting
          from content you supply.
        </div>
        <div className={classes.section}>
          <h2>Copyright Infringement – DMCA Notice</h2>
        </div>
        <div className={classes.section}>
          The Digital Millennium Copyright Act of 1998 (the “DMCA”) provides
          recourse for copyright owners who believe that material appearing on
          the Internet infringes their rights under US copyright law. If you
          believe in good faith that content or material on this site infringes
          a copyright owned by you, you (or your agent) may send the Company a
          notice requesting that the material be removed, or access to it
          blocked. This request should be sent to: UnitedHealth Group, Attn:
          DMCA Registered Agent, 9900 Bren Road East, Minnetonka, MN 55343.
        </div>
        <div className={classes.section}>
          The notice must include the following information: (a) a physical or
          electronic signature of a person authorized to act on behalf of the
          owner of an exclusive right that is allegedly infringed; (b)
          identification of the copyrighted work claimed to have been infringed;
          (c) identification of the material that is claimed to be infringing or
          the subject of infringing activity; (d) the name, address, telephone
          number, and email address of the complaining party; (e) a statement
          that the complaining party has a good faith belief that use of the
          material in the manner complained of is not authorized by the
          copyright owner, its agent or the law; and (f) a statement that the
          information in the notification is accurate and, under penalty of
          perjury, that the complaining party is authorized to act on behalf of
          the owner of an exclusive right that is allegedly infringed. If you
          believe in good faith that a notice of copyright infringement has been
          wrongly filed against you, the DMCA permits you to send us a
          counter-notice. Notices and counter-notices must meet the then-current
          statutory requirements imposed by the DMCA. Notices and
          counter-notices with respect to the Site should be sent to the address
          above.
        </div>
        <div className={classes.section}>
          <h2>Changes to Website Content</h2>
        </div>
        <div className={classes.section}>
          We may change, add or remove some or all of the Content on this
          website at any time. In addition, please note that although our goal
          is to provide accurate information, certain features that may be
          offered through this website, or other Content, may not be accurate or
          up to date. In addition, please note that features of any services or
          accounts described in this website may change over time, or end, as
          permitted by law.
        </div>
        <div className={classes.section}>
          <h2>Links</h2>
        </div>
        <div className={classes.section}>
          While visiting this website you may click on a link to other websites
          and leave this website. We provide links to other websites that may
          contain information that may be useful or interesting to you. We do
          not endorse, and are not responsible for, the content and accuracy of
          linked websites operated by third parties or for any of your dealings
          with such third parties. You are solely responsible for your dealings
          with such third parties and we encourage you to read the terms of use
          and privacy policies on such third-party websites.
        </div>
        <div className={classes.section}>
          <h2>NO WARRANTIES</h2>
        </div>
        <div className={classes.section}>
          ALL CONTENT ON THIS WEBSITE IS PROVIDED TO YOU ON AN “AS IS”, “AS
          AVAILABLE” BASIS. THE COMPANY, ALL THIRD PARTIES, IF ANY, PROVIDING
          CONTENT FOR THIS WEBSITE, AND ALL THIRD PARTIES PROVIDING SUPPORT OR
          INFORMATION FOR THIS WEBSITE (COLLECTIVELY, “WEBSITE-RELATED-PARTIES”)
          HEREBY DISCLAIM ALL WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED,
          STATUTORY OR OTHERWISE INCLUDING BUT NOT LIMITED TO THE IMPLIED
          WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND
          NON-INFRINGEMENT. THE WEBSITE-RELATED-PARTIES MAKE NO WARRANTY AS TO
          THE ACCURACY, COMPLETENESS, CURRENCY, OR RELIABILITY OF ANY CONTENT
          AVAILABLE THROUGH THIS WEBSITE. THE WEBSITERELATED-PARTIES MAKE NO
          REPRESENTATIONS OR WARRANTIES THAT USE OF THIS WEBSITE WILL BE
          UNINTERRUPTED OR ERROR-FREE. YOU ARE RESPONSIBLE FOR TAKING ALL
          PRECAUTIONS NECESSARY TO ENSURE THAT ANY CONTENT YOU MAY OBTAIN FROM
          THIS WEBSITE IS FREE OF VIRUSES AND ANY OTHER POTENTIALLY DESTRUCTIVE
          COMPUTER CODE.
        </div>
        <div className={classes.section}>
          Some states do not allow limitations on implied warranties, so one or
          more of the above limitations may not apply to you.
        </div>
        <div className={classes.section}>
          <h2>LIMITATION OF LIABILITY</h2>
        </div>
        <div className={classes.section}>
          YOU AGREE THAT NONE OF THE WEBSITE-RELATED-PARTIES SHALL BE LIABLE FOR
          ANY DAMAGE RESULTING FROM YOUR USE OR INABILITY TO USE THIS WEBSITE OR
          THE CONTENT. THIS PROTECTION COVERS CLAIMS BASED ON WARRANTY,
          CONTRACT, TORT, STRICT LIABILITY, AND ANY OTHER LEGAL THEORY. THIS
          PROTECTION COVERS THE WEBSITE-RELATED-PARTIES, INCLUDING ALL
          AFFILIATES, AND THEIR OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS. THIS
          PROTECTION COVERS ALL LOSSES INCLUDING, WITHOUT LIMITATION, DIRECT OR
          INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, EXEMPLARY, AND PUNITIVE
          DAMAGES, PERSONAL INJURY/WRONGFUL DEATH, LOST PROFITS, OR DAMAGES
          RESULTING FROM LOST DATA OR BUSINESS INTERRUPTION. THE TOTAL,
          CUMULATIVE, LIABILITY OF THE WEBSITE-RELATED-PARTIES, INCLUDING ALL
          AFFILIATES, AND THEIR OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS, IF
          ANY, FOR LOSSES OR DAMAGES SHALL BE LIMITED TO THE AMOUNT OF YOUR
          ACTUAL DAMAGES, NOT TO EXCEED U.S. $100.00. THE LIMIT OF LIABILITY MAY
          NOT BE EFFECTIVE IN SOME STATES. IN NO EVENT SHALL THE
          WEBSITE-RELATED-PARTIES, INCLUDING ALL AFFILIATES, AND THEIR OFFICERS,
          DIRECTORS, EMPLOYEES, AND AGENTS, BE LIABLE TO YOU FOR ANY LOSSES OR
          DAMAGES OTHER THAN THE AMOUNT DESCRIBED ABOVE. ALL OTHER DAMAGES,
          DIRECT OR INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, EXEMPLARY, OR
          PUNITIVE, RESULTING FROM ANY USE OF THE WEBSITE OR CONTENT ARE
          EXCLUDED EVEN IF THE WEBSITE-RELATED-PARTIES HAVE BEEN ADVISED OF THE
          POSSIBILITY OF SUCH DAMAGES. YOU AGREE THAT YOU USE THIS SITE AT YOUR
          OWN RISK. IF YOU ARE DISSATISFIED WITH THIS WEBSITE OR THE CONTENT,
          YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE WEBSITE.
        </div>
        <div className={classes.section}>
          <h2>Governing Law and Statute of Limitations</h2>
        </div>
        <div className={classes.section}>
          The laws of the State of Minnesota govern these Terms and any cause of
          action arising under or relating to your use of the website, without
          reference to its choice-of-law principles. You agree that the only
          proper jurisdiction and venue for any dispute with the Company, or in
          any way relating to your use of this website, is in the state and
          federal courts in the State of Minnesota, U.S.A. You further agree and
          consent to the exercise of personal jurisdiction in these courts in
          connection with any dispute involving the Company or its employees,
          officers, directors, agents and providers. If any provision of these
          Terms is determined to be invalid under any applicable statute or rule
          of law, such provision is to that extent to be deemed omitted, and the
          balance of the Agreement shall remain enforceable.
        </div>
        <div className={classes.section}>
          Before seeking legal recourse for any harm you believe you have
          suffered arising from or related to your use of this website, you
          agree to inform us in writing and to give us 30 days to cure the harm
          before initiating any action. You must initiate any cause of action
          within one year after the claim has arisen, or you will be barred from
          pursuing any cause of action.
        </div>
        <div className={classes.section}>
          <h2>Contact Us</h2>
        </div>
        <div className={classes.section}>
          {hourOfOperationText}
        </div>
        <div className={classes.section}>
          <h2>Effective Date</h2>
        </div>
        <div className={classes.section}>
          The Effective Date of these Terms is April 10, 2020.
        </div>
        <div className={classes.section}>
          <h2>Additional Terms</h2>
        </div>
        <div className={classes.section}>
          Your obligations under the following sections survive termination of
          this Agreement: Important Note Regarding Website Content; Agreement
          and Terms; portions of License to use this website and content
          ownership; Restrictions on use of this website; Posting messages,
          comments or content; Copyright Infringement – DMCA Notice; Changes to
          website content; Links; NO WARRANTIES; LIMITATION OF LIABILITY;
          Governing law and statute of limitations; Additional terms. If any
          provision of these Terms is found to be invalid by any court having
          competent jurisdiction, the invalidity of such provision shall not
          affect the validity of the remaining provisions of these Terms, which
          shall remain in full force and effect. No waiver of any of these Terms
          shall be deemed a further or continuing waiver of such term or
          condition or any other term or condition. You agree to defend,
          indemnify, Website-Related-Parties and their subsidiaries, affiliates,
          officers, directors, employees, and agents, harmless from any claim,
          demand, or damage, including reasonable attorneys' fees, arising out
          of or related to your breach of this Agreement or your use or misuse
          of the Content or website. You may not transfer or assign any rights
          or obligations under this Agreement. The Company may transfer or
          assign its rights and obligations under this Agreement.
        </div>
      </PageLayout>
    </React.Fragment>
  );
};
