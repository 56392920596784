"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.baseStyles = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var baseStyles = function baseStyles(theme) {
  return {
    checkbox: function checkbox(_ref) {
      var display = _ref.display,
          size = _ref.size;
      return {
        position: 'relative',
        display: display === 'row' ? 'inline-block' : 'block',
        margin: '8px 10px 8px 0px',
        minHeight: size
      };
    },
    label: function label(_ref2) {
      var isDisabled = _ref2.isDisabled;

      if (isDisabled) {
        return {
          cursor: 'not-allowed',
          color: theme.colors.gray550
        };
      }

      return {
        cursor: 'pointer'
      };
    },
    input: {
      opacity: 0
    },
    text: function text(_ref3) {
      var size = _ref3.size;
      var parsedSize = parseInt(size.replace('px', ''), 10);
      return {
        display: 'inline-block',
        verticalAlign: 'top',
        paddingLeft: parsedSize + 5
      };
    },
    icon: function icon(_ref4) {
      var size = _ref4.size,
          isChecked = _ref4.isChecked,
          isDisabled = _ref4.isDisabled,
          isValid = _ref4.isValid;
      var parsedSize = parseInt(size.replace('px', ''), 10);
      var _theme$colors = theme.colors,
          primaryColor = _theme$colors.primary,
          inactiveColor = _theme$colors.gray600,
          disabledColor = _theme$colors.gray400,
          errorColor = _theme$colors.error,
          whiteColor = _theme$colors.white;
      var styles = {
        position: 'absolute',
        top: 0,
        left: 0,
        width: parsedSize,
        height: parsedSize,
        borderRadius: 3,
        borderWidth: 2
      };
      var iconStyles = {
        content: '""',
        position: 'absolute',
        top: parsedSize / 10 - 2,
        left: parsedSize / 3 - 2,
        width: parsedSize / 3,
        height: parsedSize / 1.7,
        borderColor: whiteColor,
        borderStyle: 'solid',
        borderWidth: parsedSize / 10,
        borderTopWidth: 0,
        borderLeftWidth: 0,
        transition: 'all 0.2s ease 0s',
        transform: 'rotate(45deg) scale(0)',
        opacity: 0
      };

      if (!isDisabled) {
        styles.borderColor = isValid ? inactiveColor : errorColor;
      }

      if (isChecked) {
        iconStyles.transform = 'rotate(45deg) scale(1)';
        iconStyles.opacity = 1;

        if (isDisabled) {
          styles.backgroundColor = disabledColor;
        } else {
          styles.backgroundColor = isValid ? primaryColor : errorColor;
          styles.borderColor = isValid ? primaryColor : errorColor;
        }
      }

      if (isDisabled && !isChecked) {
        styles.backgroundColor = whiteColor;
        iconStyles.borderWidth = 0;
      }

      return _objectSpread(_objectSpread({}, styles), {}, {
        '&:after': _objectSpread({}, iconStyles)
      });
    }
  };
};

exports.baseStyles = baseStyles;