"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useField = void 0;

var _reactRedux = require("react-redux");

var _getField = require("../../redux/helpers/getField");

var _FormContext = require("../../redux/FormContext");

var useField = function useField(model) {
  var useSelect = (0, _reactRedux.createSelectorHook)(_FormContext.FormContext);
  return useSelect(function (state) {
    return (0, _getField.getField)(state, model);
  });
};

exports.useField = useField;