"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.baseStyles = void 0;

var baseStyles = function baseStyles(theme) {
  return {
    tableRow: {
      borderBottomWidth: 1,
      borderStyle: 'solid',
      borderColor: theme.colors.gray400,
      '&:nth-child(even)': {
        backgroundColor: theme.colors.gray150
      }
    }
  };
};

exports.baseStyles = baseStyles;