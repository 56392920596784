import { createReducer } from '@hhs/ui/old-abyss/ui/redux/helpers/createReducer';
import {
  VALIDATE_TINS,
  CONFIRM_TIN_INFO,
  SUBMIT_ATTESTATION,
  SET_ATTESTATIONS,
  LOAD_ATTESTATION,
} from './constants';

const initialState = {
  attestationData: {},
  attestations: {},
};

export const attestationReducer = createReducer(initialState, {
  [`${VALIDATE_TINS}_SUCCESS`]: (state, action) => {
    return {
      ...state,
      attestations: action.payload,
    };
  },

  [`${CONFIRM_TIN_INFO}_SUCCESS`]: (state, action) => {
    return {
      ...state,
      attestations: {
        ...state.attestations,
        ...action.payload,
      },
    };
  },

  [`${SUBMIT_ATTESTATION}_SUCCESS`]: (state, action) => {
    return {
      ...state,
      attestations: {
        ...state.attestations,
        [action.payload.uuid]: action.payload,
      },
    };
  },

  [LOAD_ATTESTATION]: (state, action) => {
    return {
      ...state,
      attestationData: action.payload,
    };
  },

  [SET_ATTESTATIONS]: (state, action) => {
    return {
      ...state,
      attestations: action.payload,
    };
  },
});
