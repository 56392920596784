"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "ReduxProvider", {
  enumerable: true,
  get: function get() {
    return _reactRedux.Provider;
  }
});

var _reactRedux = require("react-redux");