import { useAction } from '@hhs/ui/old-abyss/ui/redux/hooks/useAction';
import { useSaga } from '@hhs/ui/old-abyss/ui/redux/hooks/useSaga';
import { useSelect } from '@hhs/ui/old-abyss/ui/redux/hooks/useSelect';
import { validateAdditionalPayment } from './actions';
import { POST_VALIDATE_ADDITIONAL_PAYMENT } from './constants';

const additionalPaymentSelector = state => state.additionalPayment;

export const useAdditionalPayment = () => {
  const { additionalPaymentInfo } = useSelect(additionalPaymentSelector);
  return {
    additionalPaymentInfo,
    validateAdditionalPayment: useAction(validateAdditionalPayment, {
      saga: useSaga(POST_VALIDATE_ADDITIONAL_PAYMENT),
    }),
  };
};
