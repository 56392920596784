import { createSagas } from '@hhs/ui/old-abyss/ui/redux/helpers/createSagas';
import { takeEvery, call, all } from 'redux-saga/effects';
import {
  VALIDATE_TINS,
  CONFIRM_TIN_INFO,
  SUBMIT_ATTESTATION,
} from './constants';
import {
  validateTinsRequest,
  submitAttestationRequest,
  confirmTinInfoRequest,
} from './requests';

function* validateTinsSaga(action) {
  return yield call(validateTinsRequest, action.payload);
}

function* confirmTinInfoSaga(action) {
  const confirmRequests = action.payload.map(attestation => {
    return call(confirmTinInfoRequest, attestation);
  });

  const confirmData = yield all(confirmRequests);

  return confirmData.reduce((obj, item) => {
    if (!item.confirmed) {
      throw {
        code: 'InvalidData',
      };
    }

    return { ...obj, [item.uuid]: item };
  }, {});
}

function* submitAttestation(action) {
  return yield call(submitAttestationRequest, action.payload);
}

export const attestationSagas = createSagas([
  takeEvery(VALIDATE_TINS, validateTinsSaga),
  takeEvery(CONFIRM_TIN_INFO, confirmTinInfoSaga),
  takeEvery(SUBMIT_ATTESTATION, submitAttestation),
]);
