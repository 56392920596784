import React from 'react';
import PropTypes from 'prop-types';

import { ReCaptchaProvider } from './ReCaptchaProvider';
import { ReCaptchaCheckbox } from './ReCaptchaCheckbox';

export const ReCaptcha = ({ children, ...props }) => {
  if (children) {
    return <ReCaptchaProvider {...props}>{children}</ReCaptchaProvider>;
  }

  return <ReCaptchaCheckbox {...props} />;
};

ReCaptcha.propTypes = {
  children: PropTypes.node,
};

ReCaptcha.defaultProps = {
  children: null,
};
