"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "userAgent", {
  enumerable: true,
  get: function get() {
    return _userAgent.userAgent;
  }
});

var _userAgent = require("./userAgent");