"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useMask = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _react = require("react");

var _formatMoney = require("../../../tools/formatter/formats/formatMoney");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var PLACEHOLDER = '_';

var isMaskedFilled = function isMaskedFilled(value) {
  return !!(value && String(value).indexOf(PLACEHOLDER) === -1);
};

var inputMasks = {
  phone: {
    mask: ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
  },
  date: {
    mask: '99/99/9999',
    formatter: function formatter(maskedState) {
      var value = maskedState.value,
          selection = maskedState.selection,
          enteredString = maskedState.enteredString;
      var maskSplit = value.split('/');
      var outputValue = value;
      var selectionStart = selection.start;

      if (enteredString) {
        var dateSplit = enteredString.split('/');

        if (dateSplit.length === 3) {
          if (dateSplit[0].length === 1) {
            dateSplit[0] = "0".concat(dateSplit[0]);
          }

          if (dateSplit[1].length === 1) {
            dateSplit[1] = "0".concat(dateSplit[1]);
          }

          if (dateSplit[2].length === 2) {
            var currentYear = new Date().getFullYear();

            if (currentYear - "19".concat(dateSplit[2]) < 100) {
              dateSplit[2] = "19".concat(dateSplit[2]);
            } else {
              dateSplit[2] = "20".concat(dateSplit[2]);
            }
          }

          maskSplit = dateSplit;
        }
      }

      if (maskSplit[0].includes('00')) {
        maskSplit[0] = '0';
        selectionStart = 1;
      }

      if (maskSplit[1] && maskSplit[1].includes('00')) {
        maskSplit[1] = '0';
        selectionStart = 4;
      }

      if (maskSplit[1] && ['32', '33', '34', '35', '36', '37', '38', '39'].includes(maskSplit[1])) {
        maskSplit[1] = '3_';
        selectionStart = 4;
      }

      if (['2_', '3_', '4_', '5_', '6_', '7_', '8_', '9_'].includes(maskSplit[0])) {
        maskSplit[0] = "0".concat(maskSplit[0]).replace('_', '');
        selectionStart = 3;
      }

      if (['4_', '5_', '6_', '7_', '8_', '9_'].includes(maskSplit[1])) {
        maskSplit[1] = "0".concat(maskSplit[1]).replace('_', '');
        selectionStart = 6;
      }

      outputValue = maskSplit.join('/');
      return {
        value: outputValue,
        selection: {
          start: selectionStart
        }
      };
    }
  },
  dollar: {
    formatter: function formatter(maskedState) {
      return {
        value: (0, _formatMoney.formatMoney)(maskedState.enteredString)
      };
    }
  },
  zip: {
    mask: '99999'
  },
  ssn: {
    mask: '999-999-9999'
  }
};

var useMask = function useMask(_ref) {
  var mask = _ref.mask,
      type = _ref.type;
  var maskType = mask || inputMasks[type];

  if (!maskType) {
    return null;
  }

  var handleFormat = (0, _react.useCallback)(function (typedValue) {
    var newState = {
      value: typedValue
    };

    if (maskType.formatter) {
      newState = maskType.formatter({
        value: typedValue,
        enteredString: typedValue,
        selection: {}
      });
    }

    var isFilled = isMaskedFilled(newState.value);
    return {
      value: newState.value,
      isFilled: isFilled
    };
  }, []);
  var beforeMaskedStateChange = (0, _react.useCallback)(function (maskedState) {
    var newState = maskedState;

    if (maskType.formatter) {
      newState = maskType.formatter({
        value: newState.value || '',
        enteredString: newState.enteredString || '',
        selection: _objectSpread({}, newState.selection)
      });
    }

    return newState;
  }, []);
  return _objectSpread(_objectSpread({}, maskType), {}, {
    isFilled: isMaskedFilled,
    handleFormat: handleFormat,
    beforeMaskedStateChange: beforeMaskedStateChange
  });
};

exports.useMask = useMask;