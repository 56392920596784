"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "useInput", {
  enumerable: true,
  get: function get() {
    return _useInput.useInput;
  }
});

var _useInput = require("./useInput");