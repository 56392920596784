"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "AppProvider", {
  enumerable: true,
  get: function get() {
    return _AppProvider.AppProvider;
  }
});

var _AppProvider = require("./AppProvider");