import {
  VALIDATE_TINS,
  CONFIRM_TIN_INFO,
  SUBMIT_ATTESTATION,
  LOAD_ATTESTATION,
  SET_ATTESTATIONS,
} from './constants';

export const validateTins = (payload, onSuccess, onError) => {
  return {
    type: VALIDATE_TINS,
    payload,
    meta: { onSuccess, onError },
  };
};

export const confirmTinInfo = (payload, onSuccess, onError) => {
  return {
    type: CONFIRM_TIN_INFO,
    payload,
    meta: { onSuccess, onError },
  };
};

export const submitAttestation = (payload, onSuccess, onError) => {
  return {
    type: SUBMIT_ATTESTATION,
    payload,
    meta: { onSuccess, onError },
  };
};

export const loadAttestation = payload => {
  return {
    type: LOAD_ATTESTATION,
    payload,
  };
};

export const setAttestations = payload => {
  return {
    type: SET_ATTESTATIONS,
    payload,
  };
};
