"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getField = void 0;

var _toArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toArray"));

var _get = _interopRequireDefault(require("lodash/get"));

var getField = function getField(state) {
  var model = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';

  var _model$split = model.split('.'),
      _model$split2 = (0, _toArray2["default"])(_model$split),
      formKey = _model$split2[0],
      fieldPath = _model$split2.slice(1);

  var localPath = [formKey, 'fields'].concat(fieldPath.join('.'));
  return (0, _get["default"])(state, localPath, {});
};

exports.getField = getField;