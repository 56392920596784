import { config } from '@abyss/web/tools/config';
import { http } from 'src/tools/http';
import { uniqueId } from '@abyss/web/tools/uniqueId';
import moment from 'moment';

export const validateTinsRequest = async payload => {
  let validTINs;

  try {
    const { data } = await http({
      id: 'validate-tins',
      method: 'POST',
      url: `${config('HHS_API_URL')}/api/validate`,
      headers: {
        recaptchaenterprise: payload.token,
      },
      data: {
        tins: payload.tins,
      },
    });

    validTINs = (data || []).reduce((obj, item) => {
      if (item.valid) {
        if (item.tin in obj) {
          return {
            ...obj,
            [item.tin]: obj[item.tin].concat(item),
          };
        }
        return {
          ...obj,
          [item.tin]: [item],
        };
      }

      return obj;
    }, {});
  } catch (e) {
    if (!e.data || e.data.code !== 'HHVTND') {
      throw e;
    }

    validTINs = {};
  }

  return (payload.tins || []).reduce((obj, tin) => {
    const tinData = validTINs[tin] || [{ tin, count: 1 }];
    const newObjs = {};
    tinData.forEach(tinObj => {
      for (let count = 0; count < tinObj.count; count++) {
        const uuid = uniqueId();

        newObjs[uuid] = {
          ...tinObj,
          uuid,
          maskedTin: tinObj.tin.replace(/\d(?=\d{6})/g, '*'),
          valid: !!tinObj.valid,
          checkPayment: !!tinObj.checkPayment,
        };
      }
    });

    return {
      ...obj,
      ...newObjs,
    };
  }, {});
};

export const confirmTinInfoRequest = async payload => {
  const { data } = await http({
    id: 'confirm-tins',
    method: 'POST',
    url: `${config('HHS_API_URL')}/api/confirm`,
    headers: {
      recaptchaenterprise: payload.token,
    },
    data: {
      tin: payload.tin,
      accountNumber: payload.accountNumber,
      amountPaid: payload.amountPaid,
      settlementDate: payload.settlementDate
        ? moment(payload.settlementDate, 'MM/DD/YYYY').format('YYYY-MM-DD')
        : '',
      checkPayment: payload.checkPayment,
    },
  });

  return {
    ...payload,
    valid: data.valid,
    confirmed: data.valid,
    maskedTin: payload.tin.replace(/\d(?=\d{6})/g, '*'),
    maskedAccountNumber: payload.accountNumber.replace(/\d(?=\d{6})/g, '*'),
    settleDate: data.settlementDate,
    allowed: data.allowed,
  };
};

export const submitAttestationRequest = async payload => {
  const { data } = await http({
    id: 'submit-attestation',
    method: 'POST',
    url: `${config('HHS_API_URL')}/api/attestation`,
    headers: {
      recaptchacheckbox: payload.token,
    },
    data: {
      firstName: payload.firstName,
      middleName: payload.middleName,
      lastName: payload.lastName,
      email: payload.emailAddress,
      phone: payload.phoneNumber,
      billingAddress: {
        addressLine1: payload.billingAddress.addressLine1,
        addressLine2: payload.billingAddress.addressLine2,
        city: payload.billingAddress.city,
        state: payload.billingAddress.state.value,
        zipCode: payload.billingAddress.zip,
      },
      serviceAddress: {
        addressLine1: payload.serviceAddress.addressLine1,
        addressLine2: payload.serviceAddress.addressLine2,
        city: payload.serviceAddress.city,
        state: payload.serviceAddress.state.value,
        zipCode: payload.serviceAddress.zip,
      },
      tin: payload.tin,
      accountNumber: payload.accountNumber || payload.checkNumber,
      amountPaid: payload.amountPaid || payload.checkAmountPaid,
      accepted: payload.accepted,
      settlementDate: payload.settlementDate
        ? moment(payload.settlementDate, 'MM/DD/YYYY').format('YYYY-MM-DD')
        : '',
      checkPayment: payload.checkPayment,
    },
  });

  return {
    ...payload,
    confirmationId: data.confirmationId,
  };
};