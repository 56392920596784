export const formatMpin = mpin => {
  let newMpin = mpin;

  if (newMpin === '0') {
    newMpin = '';
  }

  if (newMpin) {
    while (newMpin.length < 9) {
      newMpin = `0${newMpin}`;
    }
  }

  return newMpin;
};
