"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.tokenizer = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _typeof2 = _interopRequireDefault(require("@babel/runtime/helpers/typeof"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var createToken = function createToken(params) {
  try {
    var sortedObject = Object.keys(params).sort().reduce(function (obj, key) {
      var value = params[key];

      if ((0, _typeof2["default"])(value) === 'object' || typeof value === 'undefined' || value === '' || value === false) {
        return obj;
      }

      return _objectSpread(_objectSpread({}, obj), {}, (0, _defineProperty2["default"])({}, key, value));
    }, {});
    return btoa(JSON.stringify(sortedObject));
  } catch (e) {
    return null;
  }
};

var parseToken = function parseToken(token) {
  try {
    return JSON.parse(atob(token));
  } catch (e) {
    return null;
  }
};

var updateToken = function updateToken(token, params) {
  var parsedToken = parseToken(token) || {};
  return createToken(_objectSpread(_objectSpread({}, parsedToken), params));
};

var tokenizer = {
  create: createToken,
  parse: parseToken,
  update: updateToken
};
exports.tokenizer = tokenizer;