export const styles = theme => {
  return {
    header: {
      paddingTop: 5,
      textAlign: 'left',
      backgroundColor: theme.colors.white,
      borderBottomWidth: 1,
      borderStyle: 'solid',
      borderColor: theme.colors.gray350,
      marginBottom: 40,
    },
    heading: {
      lineHeight: '36px',
      fontSize: 36,
      marginTop: 20,
      marginBottom: 10,
    },
    subHeading: {
      lineHeight: '20px',
      margin: '0 auto',
      marginBottom: 40,
    },
    sectionHeading: {
      marginBottom: 20,
      fontSize: theme.fontSizes.size850,
      '& h2': {
        fontWeight: 'bold',
        fontSize: theme.fontSizes.size850,
      },
    },
    formTitle: {
      color: '#005eaa !important',
      marginBottom: 5,
    },
    formTitleMessage: {
      marginBottom: 15,
    },
    formControl: {
      width: 300,
      marginTop: 10,
    },
    actionButton: {
      marginTop: 20,
      minWidth: 200,
    },
    subForm: {
      marginLeft: 30,
    },
    subFormSelection: {
      marginTop: 20,
      marginBottom: 30,
    },
    question: {
      marginBottom: 4,
    },
    section: {
      marginBottom: 28,
      '& h2': {
        fontWeight: 'bold',
        fontSize: 'inherit',
      },
    },
    a: {
      textDecoration: 'underline',
    },
    list: {
      listStyle: 'disc',
      marginLeft: 36,
    },
    disclaimer: {
      fontSize: 12,
      margin: [64, 0],
    },
    backButton: {
      lineHeight: 0,
      verticalAlign: 'top',
      marginTop: 14,
      marginRight: 14,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: '#005eaa !important',
      borderRadius: '50%',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    pageHeader: {
      marginTop: '14px',
      fontSize: '30px',
      fontWeight: 'bold',
      lineHeight: '10px',
    },
  };
};
