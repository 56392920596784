import React, { useEffect } from 'react';
import { Router } from '@abyss/web/ui/Router';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { Attestations } from './routes/Attestations';
import { ComeBackLater } from './routes/ComeBackLater';
import { BillingEntityConfirm } from './routes/BillingEntityConfirm';
import { TaxIdNumbers } from './routes/TaxIdNumbers';
import { MedicareVerification } from './routes/MedicareVerification';
import { AttestForm } from './routes/AttestForm';

export const Tranche1 = () => {
  const router = useRouter();
  const location = router.getLocation();

  useEffect(() => {
    if (location.pathname === '/') {
      router.navigate('/step/1');
    }
  }, []);

  useEffect(() => {
    if (location.pathname === '/') {
      router.navigate('/step/1');
    }
  }, [location.pathname]);

  return (
    <Router.Routes>
      <Router.Route
        title="Eligibility"
        path="/step/1"
        element={< BillingEntityConfirm />}
      />
      <Router.Route
        title="Step 2 Billing TIN(s)"
        path="/step/2"
        element={<TaxIdNumbers />}
      />
      <Router.Route
        title="Step 3 Verify Payment Information"
        path="/step/3"
        element={<MedicareVerification/>}
      />
      <Router.Route title="Step 4 " path="/step/4" element={<Attestations/>} />
      <Router.Route
        title="Confirmation"
        path="/confirmation"
        element={<Attestations/>}
      />
      <Router.Route title="Payment Terms" path="/attestation" element={<AttestForm/>} />
      <Router.Route
        title="Not Eligible"
        path="/not-eligible"
        element={<ComeBackLater/>}
      />
    </Router.Routes>
  );
};
