"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.hideOnEsc = void 0;
var hideOnEsc = {
  name: 'hideOnEsc',
  defaultValue: true,
  fn: function fn(_ref) {
    var hide = _ref.hide;

    function onKeyDown(event) {
      if (event.keyCode === 27) {
        hide();
      }
    }

    return {
      onShow: function onShow() {
        document.addEventListener('keydown', onKeyDown);
      },
      onHide: function onHide() {
        document.removeEventListener('keydown', onKeyDown);
      }
    };
  }
};
exports.hideOnEsc = hideOnEsc;