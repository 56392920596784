import { createSagas } from '@hhs/ui/old-abyss/ui/redux/helpers/createSagas';
import { call, takeLatest } from 'redux-saga/effects';
import { event } from '@abyss/web/tools/event';
import { GET_USER_SESSION } from './constants';
import { getUserSessionRequest } from './requests';

function* getUserSessionSaga() {
  const data = yield call(getUserSessionRequest);
  event.setData({ ipAddress: data.ipAddress });
  return data;
}

export const userSagas = createSagas([
  takeLatest(GET_USER_SESSION, getUserSessionSaga),
]);
