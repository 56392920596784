"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.baseStyles = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _color = require("../../tools/color");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var baseStyles = function baseStyles(theme) {
  return {
    badge: function badge(_ref) {
      var variant = _ref.variant,
          size = _ref.size,
          color = _ref.color,
          width = _ref.width;
      var variantStyles;

      if (variant === 'subtle') {
        variantStyles = {
          backgroundColor: (0, _color.color)(color).lighten(0.1).alpha(0.2).string(),
          color: color
        };
      } else if (variant === 'solid') {
        variantStyles = {
          backgroundColor: color,
          color: theme.colors.white
        };
      } else if (variant === 'outline') {
        variantStyles = {
          boxShadow: "".concat(color, " 0px 0px 0px 1px inset"),
          color: color
        };
      }

      return _objectSpread({
        display: 'inline-block',
        fontWeight: 'bold',
        whiteSpace: 'nowrap',
        verticalAlign: 'middle',
        borderRadius: 3,
        width: width,
        textAlign: 'center',
        paddingLeft: size,
        paddingRight: size,
        fontSize: size
      }, variantStyles);
    }
  };
};

exports.baseStyles = baseStyles;