import React from 'react';
import PropTypes from 'prop-types';
import { GoogleReCaptchaProvider } from '../GoogleRecaptcha';
import { ReCaptchaButton } from './ReCaptchaButton';

export const ReCaptchaProvider = ({ children, sitekey, ...props }) => {
  if (!sitekey) {
    return children;
  }

  return (
    <GoogleReCaptchaProvider reCaptchaKey={sitekey}>
      <ReCaptchaButton {...props}>{children}</ReCaptchaButton>
    </GoogleReCaptchaProvider>
  );
};

ReCaptchaProvider.propTypes = {
  children: PropTypes.node,
  sitekey: PropTypes.string,
};

ReCaptchaProvider.defaultProps = {
  children: null,
  sitekey: null,
};
