"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.baseStyles = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var getSizeStyles = function getSizeStyles(props, theme) {
  var baseSize = props.size;
  var baseSizeInt = parseInt(baseSize, 10);
  var paddingHorizontal = baseSizeInt + props.paddingHorizontal;
  var paddingVertical = baseSizeInt + props.paddingVertical;
  var widthStyle = props.width && {
    width: props.width
  };
  var paddingStyle = props.variant !== 'link' && {
    paddingLeft: paddingHorizontal,
    paddingRight: paddingHorizontal,
    paddingTop: paddingVertical,
    paddingBottom: paddingVertical
  };
  return _objectSpread(_objectSpread({
    fontSize: baseSize,
    lineHeight: baseSize,
    fontWeight: theme.fontWeights.bold
  }, widthStyle), paddingStyle);
};

var getVariantStyles = function getVariantStyles(props) {
  var color = props.color,
      colorHover = props.colorHover,
      altColor = props.altColor,
      altColorHover = props.altColorHover,
      borderWidth = props.borderWidth,
      borderRadius = props.borderRadius;

  switch (props.variant) {
    case 'solid':
      return {
        color: altColor,
        backgroundColor: props.isSelected ? colorHover : color,
        borderStyle: 'solid',
        borderColor: color,
        borderWidth: borderWidth,
        borderRadius: borderRadius,
        '&:hover': {
          backgroundColor: colorHover
        }
      };

    case 'outline':
      return {
        color: color,
        backgroundColor: props.isSelected ? altColorHover : altColor,
        borderColor: color,
        borderWidth: borderWidth,
        borderRadius: borderRadius,
        '&:hover': {
          backgroundColor: altColorHover
        }
      };

    case 'link':
      return {
        color: color,
        backgroundColor: 'transparent',
        borderBottomColor: 'transparent',
        borderTopWidth: 0,
        borderRightWidth: 0,
        borderLeftWidth: 0,
        borderBottomWidth: 2,
        borderRadius: 0,
        '&:hover, &:focus': {
          borderBottomColor: color
        },
        '&:active': {
          color: colorHover,
          borderBottomColor: colorHover
        }
      };

    default:
      return {};
  }
};

var getOrientationStyles = function getOrientationStyles(props) {
  if (props.orientation === 'vertical') {
    return {
      transform: 'rotate(-90deg)'
    };
  }

  return {};
};

var baseStyles = function baseStyles(theme) {
  return {
    button: function button(props) {
      var buttonSize = getSizeStyles(props, theme);
      var buttonVariant = getVariantStyles(props);
      var buttonOrientation = getOrientationStyles(props);
      var _theme$colors = theme.colors,
          disabledTextColor = _theme$colors.gray500,
          disabledFillColor = _theme$colors.gray200,
          focusColor = _theme$colors.focus;
      return _objectSpread(_objectSpread(_objectSpread({
        display: 'inline-block',
        alignItems: 'center',
        justifyContent: 'center',
        textDecoration: 'none',
        outline: 'none',
        position: 'relative',
        WebkitAppearance: 'none',
        cursor: 'pointer',
        borderStyle: 'solid',
        '&:disabled': {
          cursor: 'not-allowed',
          color: disabledTextColor,
          backgroundColor: disabledFillColor,
          borderColor: disabledTextColor
        },
        '&:disabled:hover': {
          backgroundColor: disabledFillColor
        },
        '&:focus': {
          boxShadow: "0 0 0 1px #ffffff, 0 0 3px 4px ".concat(focusColor),
          outline: '1px dotted transparent',
          outlineOffset: '1px'
        },
        '&:active': {
          backgroundColor: 'auto'
        }
      }, buttonSize), buttonVariant), buttonOrientation);
    },
    transition: {
      transitionProperty: 'background',
      transitionDuration: '250ms',
      transitionTimingFunction: 'cubic-bezier(.2, .8, .4, 1)'
    },
    loading: function loading(props) {
      var buttonVariant = getVariantStyles(props);
      var baseSizeInt = parseInt(props.size, 10);
      var loadingWidth = baseSizeInt + 6;
      return {
        '&:before': {
          content: '""',
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          opacity: 0.5,
          backgroundColor: buttonVariant.backgroundColor,
          borderRadius: buttonVariant.borderRadius
        },
        '&:after': {
          content: '""',
          display: 'block',
          position: 'absolute',
          width: loadingWidth,
          height: loadingWidth,
          margin: 'auto',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          borderRadius: '50%',
          borderLeftWidth: loadingWidth / 10,
          borderBottomWidth: loadingWidth / 10,
          borderLeftStyle: 'solid',
          borderBottomStyle: 'solid',
          borderLeftColor: 'currentColor',
          borderBottomColor: 'currentColor',
          opacity: 0.85,
          animation: '$button-loading 700ms infinite linear'
        }
      };
    },
    '@keyframes button-loading': {
      to: {
        transform: 'rotate(360deg)'
      }
    }
  };
};

exports.baseStyles = baseStyles;