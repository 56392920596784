import * as React from 'react';
import PropTypes from 'prop-types';

export class ReCAPTCHA extends React.Component {
  constructor(props) {
    super(props);
    this.handleExpired = this.handleExpired.bind(this);
    this.handleErrored = this.handleErrored.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleRecaptchaRef = this.handleRecaptchaRef.bind(this);
  }

  componentDidMount() {
    this.explicitRender();
  }

  componentDidUpdate() {
    this.explicitRender();
  }

  componentWillUnmount() {
    if (this._widgetId !== undefined) {
      this.delayOfCaptchaIframeRemoving();
      this.reset();
    }
  }

  getValue() {
    const { grecaptcha } = this.props;
    if (grecaptcha && grecaptcha.enterprise && this._widgetId !== undefined) {
      return grecaptcha.enterprise.getResponse(this._widgetId);
    }
    return null;
  }

  getWidgetId() {
    const { grecaptcha } = this.props;
    if (grecaptcha && this._widgetId !== undefined) {
      return this._widgetId;
    }
    return null;
  }

  execute() {
    const { grecaptcha } = this.props;

    if (grecaptcha && grecaptcha.enterprise && this._widgetId !== undefined) {
      return grecaptcha.enterprise.execute(this._widgetId);
    }
    this._executeRequested = true;
    return null;
  }

  executeAsync() {
    return new Promise((resolve, reject) => {
      this.executionResolve = resolve;
      this.executionReject = reject;
      this.execute();
    });
  }

  reset() {
    const { grecaptcha } = this.props;
    if (grecaptcha && grecaptcha.enterprise && this._widgetId !== undefined) {
      grecaptcha.enterprise.reset(this._widgetId);
    }
  }

  handleExpired() {
    const { onExpired } = this.props;
    if (onExpired) {
      onExpired();
    } else {
      this.handleChange(null);
    }
  }

  handleErrored() {
    const { onErrored } = this.props;
    if (onErrored) {
      onErrored();
    }
    if (this.executionReject) {
      this.executionReject();
      delete this.executionResolve;
      delete this.executionReject;
    }
  }

  handleChange(token) {
    const { onChange } = this.props;
    if (onChange) {
      onChange(token);
    }
    if (this.executionResolve) {
      this.executionResolve(token);
      delete this.executionReject;
      delete this.executionResolve;
    }
  }

  explicitRender() {
    const {
      grecaptcha,
      sitekey,
      theme,
      type,
      tabindex,
      size,
      stoken,
      hl,
      badge,
      isolated,
    } = this.props;
    if (
      grecaptcha &&
      grecaptcha.enterprise &&
      grecaptcha.enterprise.render &&
      this._widgetId === undefined
    ) {
      const wrapper = document.createElement('div');
      this._widgetId = grecaptcha.enterprise.render(wrapper, {
        sitekey,
        callback: this.handleChange,
        theme,
        type,
        tabindex,
        'expired-callback': this.handleExpired,
        'error-callback': this.handleErrored,
        size,
        stoken,
        hl,
        badge,
        isolated,
      });
      this.captcha.appendChild(wrapper);
    }
    if (this._executeRequested && grecaptcha && this._widgetId !== undefined) {
      this._executeRequested = false;
      this.execute();
    }
  }

  delayOfCaptchaIframeRemoving() {
    const temporaryNode = document.createElement('div');
    document.body.appendChild(temporaryNode);
    temporaryNode.style.display = 'none';

    // move of the recaptcha to a temporary node
    while (this.captcha.firstChild) {
      temporaryNode.appendChild(this.captcha.firstChild);
    }

    // delete the temporary node after reset will be done
    setTimeout(() => {
      document.body.removeChild(temporaryNode);
    }, 5000);
  }

  handleRecaptchaRef(elem) {
    this.captcha = elem;
  }

  render() {
    // consume properties owned by the reCATPCHA, pass the rest to the div so the user can style it.
    /* eslint-disable no-unused-vars */
    const {
      sitekey,
      onChange,
      theme,
      type,
      tabindex,
      onExpired,
      onErrored,
      size,
      stoken,
      grecaptcha,
      badge,
      hl,
      isolated,
      ...childProps
    } = this.props;
    /* eslint-enable no-unused-vars */
    return <div {...childProps} ref={this.handleRecaptchaRef} />;
  }
}

ReCAPTCHA.displayName = 'ReCAPTCHA';
ReCAPTCHA.propTypes = {
  sitekey: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  grecaptcha: PropTypes.shape({
    enterprise: PropTypes.shape({
      render: PropTypes.func,
      reset: PropTypes.func,
      execute: PropTypes.func,
      getResponse: PropTypes.func,
    }),
  }),
  theme: PropTypes.oneOf(['dark', 'light']),
  type: PropTypes.oneOf(['image', 'audio']),
  tabindex: PropTypes.number,
  onExpired: PropTypes.func,
  onErrored: PropTypes.func,
  size: PropTypes.oneOf(['compact', 'normal', 'invisible']),
  stoken: PropTypes.string,
  hl: PropTypes.string,
  badge: PropTypes.oneOf(['bottomright', 'bottomleft', 'inline']),
  isolated: PropTypes.bool,
};
ReCAPTCHA.defaultProps = {
  onChange: () => {},
  theme: 'light',
  type: 'image',
  tabindex: 0,
  size: 'normal',
  badge: 'bottomright',
  grecaptcha: null,
  onExpired: null,
  onErrored: null,
  stoken: null,
  hl: null,
  isolated: null,
};
